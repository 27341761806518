import { useState } from 'react';
import toast from 'react-hot-toast';
import { usePlainFdk } from '../../fdk';
import { Modal, hideModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export function ChapterEditModal({ id, chapter, mutate }: { id: string; chapter: any; mutate: () => void }) {
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation('translation');
  const schema = z.object({
    name: z.string().min(1, { message: t('global.required') }),
  });
  const form = useForm({
    resolver: zodResolver(schema),
  });
  return (
    <Modal headline={t('chapter.chapterEditModal.headline')} id={id as string}>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (values) => {
            setPending(true);
            await fdk.model('chapter').editEntry(chapter.id as string, values);
            toast.success('Kapitel gespeichert!');
            await mutate();
            hideModal('editModal');
            setPending(false);
          })}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('chapters.ChapterEditModal.chapterName')}</FormLabel>
                <FormControl>
                  <Input {...field} placeholder={t('chapters.ChapterEditModal.chapterNamePlaceholder')} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end py-4 gap-x-2">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                hideModal('renameChapterModal');
              }}
            >
              {t('chapters.ChapterEditModal.cancel')}
            </Button>
            <Button type="submit" disabled={pending}>
              {t('chapters.ChapterEditModal.save')}{' '}
              {pending && <span className="loading loading-infinity loading-xs" />}
            </Button>
          </div>
        </form>
      </Form>
    </Modal>
  );
}
