import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { useTranslation } from 'react-i18next';

export default function NewStudioForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
    },
  });

  const submit = async (data: any) => {
    const { title } = data;
    navigate(`/onboarding/aboselect?studioname=${title}`, { replace: true });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="form-control">
        <label className="label pb-2 block">
          <span className="label-text">{t('projects.onboarding.label')}</span>
        </label>
        <Input
          {...register('title', {
            required: true,
            pattern: {
              value: /^[a-zA-Z0-9_\-:*, ]+$/,
              message: 'Nur folgende Zeichen sind im Namen erlaubt: a-z, A-Z, 0-9, _, -, :, *, Leerzeichen',
            },
          })}
          autoComplete="off"
          type="text"
          placeholder={t('projects.onboarding.placeholder')}
          name="title"
        />
        {errors.title && <span className="text-xs text-error mt-2">{errors.title.message}</span>}
      </div>
      <div className="form-control">
        <div className="flex items-end py-4 gap-x-4">
          <Button className="btn btn-primary grow" type="submit">
            {t('projects.onboarding.create')}
          </Button>
        </div>
      </div>
    </form>
  );
}
