// import { useOidc } from '@axa-fr/react-oidc';
import { ReactElement } from 'react';

export function TopNav({ label, children }: React.PropsWithChildren<{ label?: string | ReactElement }>) {
  return (
    <header className="col-span-12 flex items-start justify-between gap-2 lg:gap-4 sm:px-0 px-4 mb-6">
      {label && (
        <div className="grow">
          <h1 className="lg:text-2xl lg:font-light">{label}</h1>
        </div>
      )}
      {children}
      {/* <div>
        <input type="text" placeholder="Search" className="input input-sm rounded-full max-sm:w-24" />
      </div> */}
      {/* <details className="dropdown dropdown-end z-10">
        <summary tabIndex={0} className="btn btn-circle btn-ghost">
          <div className="indicator">
            <span className="badge indicator-item badge-error badge-xs"></span>
            <BellIcon className="h-5 w-5" />
          </div>
        </summary>
        <ul tabIndex={0} className="menu dropdown-content mt-3 w-80 rounded-box bg-base-100 p-2 shadow-2xl">
          <li>
            <a className="gap-4">
              <div className="avatar">
                <div className="w-8 rounded-full">
                  <img src="https://picsum.photos/80/80?1" />
                </div>
              </div>
              <span>
                <b>Neue Nachricht</b>
                <br />
                Alice: Na, du Wurst?
              </span>
            </a>
          </li>
          <li>
            <a className="gap-4">
              <div className="avatar">
                <div className="w-8 rounded-full">
                  <img src="https://picsum.photos/80/80?2" />
                </div>
              </div>
              <span>
                <b>Erinnerung</b>
                <br />
                Der Knödel ist jetzt warm
              </span>
            </a>
          </li>
          <li>
            <a className="gap-4">
              <div className="avatar">
                <div className="w-8 rounded-full">
                  <img src="https://picsum.photos/80/80?3" />
                </div>
              </div>
              <span>
                <b>Neue Abbuchung</b>
                <br />
                Es wurden €9999 von deinem Konto abgebucht
              </span>
            </a>
          </li>
        </ul>
      </details> */}
      {/* <details className="dropdown-end dropdown z-10">
        <summary tabIndex={0} className="avatar btn btn-circle btn-ghost">
          <div className="w-10 rounded-full">
            <img src="https://picsum.photos/80/80?5" />
          </div>
        </summary>
        <ul tabIndex={0} className="menu dropdown-content mt-3 w-52 rounded-box bg-base-100 p-2 shadow-2xl">
          <li>
            <a>Account</a>
          </li>
          <li>
            <a>
              Nachrichten
              <span className="badge badge-success">12</span>
            </a>
          </li>
          <li>
            <a>Einstellungen</a>
          </li>
          <li>
            <a onClick={async () => logout('/')}>Logout</a>
          </li>
        </ul>
      </details> */}
    </header>
  );
}
