import { PlusIcon, SpeakerWaveIcon } from '@heroicons/react/24/solid';
import { ChevronsUpDown, X } from 'lucide-react';

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { VoiceCreateModal } from '@/routes/VoiceCreateModal';
import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useStudioApi } from '../useStudioAPI';
import { TAGS, classNames } from '../utils';
import EmptyState from './EmptyState';
import { PageLoaderWithNav } from './PageLoaderWithNav';
import { Badge } from './ui/badge';
import { Button } from './ui/button';
import { Card, CardFooter, CardHeader } from './ui/card';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import { Select, SelectAvatarItem, SelectContent, SelectGroup, SelectTrigger } from './ui/select';

export default function VoiceSelect({
  onChange,
  value,
  showModal: sm,
}: {
  onChange?: (e: any) => any;
  value?: any;
  showModal?: boolean;
}) {
  const { t } = useTranslation('translation');
  const { data: voiceList } = useStudioApi('voices');
  const { watch } = useFormContext();

  const [selected, setSelected] = useState(value ?? null);
  const [showModal, setShowModal] = useState(sm);
  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    if (sm !== showModal) {
      setShowModal(sm);
    }
  }, [sm]);

  const globalVoice = voiceList?.find((e) => e?.id === watch('chapter.content.voiceID'));
  const voicesUsed = watch('chapter.content.sections').map((sect) => sect.voiceID);
  const allVoices = useMemo(
    () =>
      watch('chapter.content.sections')
        .reduce((acc: any[], cur: any) => {
          if (
            (cur.type === 'text' || cur.type === 'speech') &&
            cur.voiceID &&
            acc.findIndex((e) => e?.id === cur.voiceID) === -1
          ) {
            acc.push(voiceList?.find((e) => e?.id === cur.voiceID));
          }
          return acc;
        }, [])
        .filter(Boolean),
    [voiceList, voicesUsed],
  );
  if (globalVoice && allVoices.findIndex((e) => e?.id === globalVoice?.id) === -1) {
    allVoices.push(globalVoice);
  }

  useEffect(() => {
    if (voiceList?.items && !value) {
      setSelected(voiceList?.[0].id ?? null);
      if (onChange) {
        onChange(voiceList?.[0].id ?? null);
      }
    } else if (voiceList) {
      setSelected(voiceList?.find((e) => e.id === value) ?? null);
    }
  }, [voiceList]);

  const clones = voiceList?.filter(
    (voice) => !voice?.id.startsWith('global|') && allVoices.findIndex((e) => e?.id === voice?.id) === -1,
  );

  if (!voiceList) {
    return null;
  }

  return (
    <>
      <Select
        open={selectOpen}
        onOpenChange={setSelectOpen}
        onValueChange={(e) => {
          setSelected(e);
          onChange && onChange(e);
        }}
      >
        <SelectTrigger
          className={`flex items-center justify-between gap-x-2 col-span-12 relative cursor-pointer py-1 px-2 w-44`}
        >
          <img
            src={selected?.image ?? '/logo.svg'}
            className={classNames(
              !selected?.image && 'p-1.5',
              'h-6 w-6 rounded-full bg-base-300 border border-base-100',
            )}
          />

          <span className="block truncate">{selected?.name ?? 'Stimme wählen'}</span>
        </SelectTrigger>

        <SelectContent arrowPadding={1000}>
          <SelectGroup>
            {allVoices.filter(Boolean).map((person) => (
              <SelectAvatarItem
                value={person}
                key={person?.id}
                className={`flex items-center justify-between col-span-12 relative w-full cursor-pointer rounded-md text-left text-gray-100 shadow-sm ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-indigo-600 sm:text-sm sm:leading-6`}
              >
                <div className="flex items-center justify-between w-full space-x-2">
                  <img
                    src={person.image ?? '/logo.svg'}
                    className={classNames(
                      !person.image && 'p-1.5',
                      'h-6 w-6 rounded-full bg-base-300 border border-base-100',
                    )}
                  />
                  <span
                    className={classNames(
                      selected?.id === person?.id ? 'font-semibold' : 'font-normal',
                      'block truncate text-card-foreground',
                    )}
                  >
                    {person.name}
                  </span>
                </div>
              </SelectAvatarItem>
            ))}
            {clones.map((person) => (
              <SelectAvatarItem
                value={person}
                key={person?.id}
                className={`flex items-center justify-between col-span-12 relative w-full cursor-pointer rounded-md text-left text-gray-100 shadow-sm ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-indigo-600 sm:text-sm sm:leading-6`}
              >
                <div className="flex items-center justify-between w-full space-x-2">
                  <img
                    src={person.image ?? '/logo.svg'}
                    className={classNames(
                      !person.image && 'p-1.5',
                      'h-6 w-6 rounded-full bg-base-300 border border-base-100',
                    )}
                  />
                  <span
                    className={classNames(
                      selected?.id === person?.id ? 'font-semibold' : 'font-normal',
                      'block truncate text-card-foreground',
                    )}
                  >
                    {person.name}
                  </span>
                </div>
              </SelectAvatarItem>
            ))}
          </SelectGroup>
          <SelectGroup className="border-t ">
            <div
              className="py-1 pl-10 pr-9 cursor-pointer hover:bg-background rounded-md"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <span className="text-sm">{t('chapter.voiceSelect.changeVoice')}</span>
            </div>
          </SelectGroup>
        </SelectContent>
      </Select>
      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent className="overflow-y-scroll !max-h-[calc(100vh-20%)] !max-w-[800px]">
          <VoiceSelectModal
            selected={selected}
            setSelected={(e) => {
              setSelected(e);
              setSelectOpen(false);
              setShowModal(false);
              onChange && onChange(e);
            }}
            setShowModal={setShowModal}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export function VoiceSelectLocal({
  onChange,
  value,
  disabled = false,
}: {
  onChange?: (e: any) => any;
  value?: any;
  disabled?: boolean;
}) {
  const { t } = useTranslation('translation');
  const { data: voiceList } = useStudioApi('voices');
  const formContext = useFormContext();
  const [selected, setSelected] = useState(value);
  const [showModal, setShowModal] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  const globalVoice = voiceList?.find((e) => e?.id === formContext?.watch('chapter.content.voiceID'));
  const allVoices =
    formContext?.watch('chapter.content.sections').reduce((acc: any[], cur: any) => {
      if (
        (cur.type === 'text' || cur.type === 'speech') &&
        cur.voiceID &&
        acc.findIndex((e) => e?.id === cur.voiceID) === -1
      ) {
        acc.push(voiceList?.find((e) => e?.id === cur.voiceID));
      }
      return acc;
    }, []) || [];
  if (globalVoice && allVoices.findIndex((e) => e?.id === globalVoice?.id) === -1) {
    allVoices.push(globalVoice);
  }

  const clones = voiceList?.filter(
    (voice) => !voice.id.startsWith('global|') && allVoices.findIndex((e) => e?.id === voice?.id) === -1,
  );
  useEffect(() => {
    if (voiceList?.items && !value) {
      setSelected(null);
    } else if (voiceList) {
      setSelected(voiceList?.find((e) => e.id === value));
    }
  }, [voiceList]);

  if (!voiceList) {
    return null;
  }
  return (
    <>
      <Select
        open={selectOpen}
        onOpenChange={(_o) => setSelectOpen(_o)}
        disabled={disabled}
        onValueChange={(value) => {
          if (value === 'none') {
            setSelected(undefined);
            onChange?.(undefined);
            return;
          }

          const person = allVoices.find((p) => p?.id === value) || clones.find((c) => c?.id === value);
          setSelected(person);
          onChange?.(person);
        }}
      >
        <SelectTrigger className="py-2 h-8">
          <img
            src={selected?.image ?? '/logo.svg'}
            className={classNames(
              !selected?.image && 'p-1.5',
              'h-6 w-6 rounded-full bg-base-300 border border-base-100',
            )}
          />
          <span className="block truncate">{selected?.name ?? 'Globale Stimme'}</span>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectAvatarItem
              value="none"
              className={`hover:bg-background py-2 px-2 flex items-center col-span-12 relative w-full cursor-pointer rounded-md text-left text-gray-100 shadow-sm ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-indigo-600 sm:text-sm sm:leading-6`}
            >
              <div className="flex items-center w-full space-x-2">
                <img
                  src={'/logo.svg'}
                  className={classNames('h-6 w-6 rounded-full bg-base-300 border border-base-100')}
                />
                <span
                  className={classNames(
                    selected === undefined ? 'font-semibold' : 'font-normal',
                    'block truncate text-card-foreground',
                  )}
                >
                  {t('chapter.voiceSelect.globalVoice')}
                </span>
              </div>
            </SelectAvatarItem>
            {allVoices.filter(Boolean).map((person) => (
              <SelectAvatarItem
                value={person?.id}
                key={person?.id}
                className={`flex items-center justify-between col-span-12 relative w-full cursor-pointer rounded-md text-left text-gray-100 shadow-sm ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-indigo-600 sm:text-sm sm:leading-6`}
              >
                <div className="flex items-center justify-between w-full space-x-2">
                  <img
                    src={person.image ?? '/logo.svg'}
                    className={classNames(
                      !person.image && 'p-1.5',
                      'h-6 w-6 rounded-full bg-base-300 border border-base-100',
                    )}
                  />
                  <span
                    className={classNames(
                      selected?.id === person?.id ? 'font-semibold' : 'font-normal',
                      'block truncate text-card-foreground',
                    )}
                  >
                    {person.name}
                  </span>
                </div>
              </SelectAvatarItem>
            ))}
            {clones.map((person, i) => (
              <SelectAvatarItem
                value={person?.id}
                key={i}
                className={`flex items-center justify-between col-span-12 relative w-full cursor-pointer rounded-md text-left text-gray-100 shadow-sm ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-indigo-600 sm:text-sm sm:leading-6`}
              >
                <div className="flex items-center justify-between w-full space-x-2">
                  <img
                    src={person.image ?? '/logo.svg'}
                    className={classNames(
                      !person.image && 'p-1.5',
                      'h-6 w-6 rounded-full bg-base-300 border border-base-100',
                    )}
                  />
                  <span
                    className={classNames(
                      selected?.id === person?.id ? 'font-semibold' : 'font-normal',
                      'block truncate text-card-foreground',
                    )}
                  >
                    {person.name}
                  </span>
                </div>
              </SelectAvatarItem>
            ))}
          </SelectGroup>
          <SelectGroup className="border-t">
            <div
              className="py-1 pl-10 pr-9 cursor-pointer hover:bg-background rounded-md"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <span className="text-sm">{t('chapter.voiceSelect.changeVoice')}</span>
            </div>
          </SelectGroup>
        </SelectContent>
      </Select>
      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent className="overflow-y-scroll !max-h-[calc(100vh-20%)] !max-w-[800px]">
          <VoiceSelectModal
            selected={selected}
            setSelected={(e) => {
              setSelected(e);
              setShowModal(false);
              setSelectOpen(false);
              onChange && onChange(e);
            }}
            setShowModal={setShowModal}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export const VoiceContext = createContext<any>(null);

function VoiceSelectModal({
  selected,
  setSelected,
  setShowModal,
}: {
  selected: any;
  setSelected: (id: string) => void;
  setShowModal: (show: boolean) => void;
}) {
  const { t, i18n } = useTranslation('translation');
  const [filter, setFilter] = useState(new Set());
  const { data: voices, mutate } = useStudioApi('voices');
  const { data: tags } = useStudioApi('tags');
  const [createModal, setCreateModal] = useState(false);

  const playingCardRef = useRef(null);

  const globalVoices = useMemo(() => voices?.filter(({ id }) => id.startsWith('global|')), [voices]);
  const localVoices = useMemo(() => voices?.filter(({ id }) => !id.startsWith('global|')), [voices]);

  const onChange = (id: string) => {
    let selectedVoice = localVoices?.find(({ id: voiceID }) => voiceID === id);
    !selectedVoice && (selectedVoice = globalVoices?.find(({ id: voiceID }) => voiceID === id));
    setSelected(selectedVoice);
    setShowModal(false);
  };

  const globalTags = useMemo(
    () =>
      //TODO: groupBy is not supported by Safari
      // Object.groupBy(tags?.filter(({ global }) => global) ?? [], ({ type }) => type),
      (tags?.filter(({ global }) => global) ?? []).reduce((acc, cur) => {
        if (!acc[cur.type]) {
          acc[cur.type] = [];
        }
        acc[cur.type].push(cur);
        return acc;
      }, {}),

    [tags],
  );

  if (!voices) {
    return <PageLoaderWithNav />;
  }

  return (
    <VoiceContext.Provider value={{ playingCardRef }}>
      <div className="flex justify-start gap-x-20">
        <div>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {t('voices.headlineCloned')}
          </h1>
        </div>
      </div>

      <div className="flex justify-end px-4">
        <Dialog open={createModal} onOpenChange={setCreateModal}>
          {localVoices?.length > 0 && (
            <DialogTrigger>
              <Button onClick={() => setCreateModal(true)}>
                <PlusIcon className="w-5 h-5" /> {t('voices.addVoice')}
              </Button>
            </DialogTrigger>
          )}
          <DialogContent>
            <VoiceCreateModal
              id="createModal"
              onCreate={() => {
                mutate();
                setCreateModal(false);
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
      <div className="grid grid-cols-12  gap-2 mt-4" style={{ gridAutoRows: '1fr' }}>
        {localVoices?.length > 0 ? (
          localVoices?.map((voice) => (
            <VoiceCard onChange={(e) => onChange(e)} selected={selected} key={voice.id} {...voice} />
          ))
        ) : (
          <div className="col-span-12 mb-4">
            <EmptyState
              error={t('voices.noVoices')}
              description={t('voices.noVoiceDescription')}
              onClick={() => setCreateModal(true)}
              title={t('voices.addVoice')}
            />
          </div>
        )}
      </div>

      {globalVoices?.length > 0 && (
        <>
          <div className="flex grow items-center mt-4 justify-between col-span-full">
            <div>
              <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
                {t('voices.headlineLibrary')}
              </h1>
            </div>
            <div className="flex gap-x-20">
              <div className="flex gap-x-4">
                {Array.from(filter).length > 0 && (
                  <Button
                    onClick={() => setFilter(new Set())}
                    variant="outline"
                    className="flex gap-x-2 text-destructive/80"
                  >
                    <X className="w-4 h-4" />
                    {t('voices.clearFilter')}
                  </Button>
                )}
                {Object.entries(globalTags).map(([type, tags]: any) => (
                  <>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="outline">
                          {TAGS[i18n.language][type]} <ChevronsUpDown className=" ml-3 w-4 h-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        {tags?.map((tag) => (
                          <DropdownMenuCheckboxItem
                            key={tag.id}
                            checked={filter.has(tag.id)}
                            onCheckedChange={() => {
                              setFilter((old) => {
                                const newFilter = new Set(old);
                                if (newFilter.has(tag.id)) {
                                  newFilter.delete(tag.id);
                                } else {
                                  newFilter.add(tag.id);
                                }
                                return newFilter;
                              });
                            }}
                          >
                            {tag.label}
                          </DropdownMenuCheckboxItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12  gap-2 mt-6" style={{ gridAutoRows: '1fr' }}>
            {globalVoices
              ?.filter(({ tags }) => {
                if (filter.size === 0) {
                  return true;
                }
                return tags.some(({ id }) => filter.has(id));
              })
              .map((voice) => (
                <VoiceCard onChange={(e) => onChange(e)} selected={selected} key={voice.id} global {...voice} />
              ))}
          </div>
        </>
      )}
    </VoiceContext.Provider>
  );
}

export function VoiceCard({
  name,
  description,
  tags,
  id,
  image,
  global = false,
  selected,
  onChange,
}: {
  name: string;
  description: string;
  id: string;
  image?: string;
  tags?: Array<{
    id: string;
    label: string;
    type: string;
  }>;
  global: boolean;
  selected?: any;
  onChange?: (id: string) => void;
}) {
  const [error, setError] = useState(false);
  const [play, setPlay] = useState(false);
  const audio = useRef<HTMLAudioElement>(null);
  const { shortID } = useParams();
  const { playingCardRef } = useContext(VoiceContext);
  const { t } = useTranslation('translation');

  useEffect(() => {
    if (error) {
      setPlay(false);
      toast.error(t('voices.errorPlayingVoice'));
    }
  }, [error]);

  return (
    <Card
      className={`col-span-12 md:col-span-6 lg:col-span-6 xl:col-span-4  flex flex-col ${
        selected?.id === id && 'border-primary'
      }`}
    >
      <CardHeader
        className="mb-auto p-4 cursor-pointer "
        onClick={() => {
          onChange && onChange(id);
        }}
      >
        <div>
          <audio
            src={`${import.meta.env.VITE_API_URL}/${shortID}/voices/${id}`}
            ref={audio}
            crossOrigin="anonymous"
            preload="none"
            onPlay={() => setPlay(true)}
            onPause={() => setPlay(false)}
            onEnded={() => setPlay(false)}
            onError={() => setError(true)}
          />
          <div className="flex">
            {global && (
              <img
                src={image ?? '/logo.svg'}
                className={classNames(
                  !image && 'p-2.5',
                  'mr-4 h-12 w-12 rounded-full bg-base-300 border border-base-100',
                )}
              />
            )}
            <div>
              <h2 className="text-xl mb-2">{name}</h2>
              <p className="text-sm">{description}</p>
              <div className="flex flex-wrap gap-1 mt-2">
                {tags?.map(({ id, label, type }) => (
                  <Badge
                    variant="secondary"
                    className={classNames(
                      'inline-flex items-center rounded-full px-2 text-[0.7em] font-medium ring-1 ring-inset text-[#A6A6A6]',
                      type === 'gender' && 'bg-base-100',
                      type === 'tone' && 'bg-base-200',
                      type === 'topic' && 'bg-base-300',
                    )}
                    key={id}
                  >
                    {label}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardFooter className="border-t gap-1 flex-wrap justify-between p-2">
        <Button
          disabled={error}
          variant="ghost"
          className="flex-1"
          onClick={() => {
            if (playingCardRef?.current === audio.current) {
              if (audio.current?.paused) {
                audio.current.play();
              } else {
                if (audio.current) {
                  audio.current.currentTime = 0;
                  audio.current?.pause();
                }
              }
              return;
            }
            if (playingCardRef?.current) {
              playingCardRef.current.pause();
              playingCardRef.current.currentTime = 0;
            }

            playingCardRef.current = audio.current;
            if (audio.current) {
              audio?.current.play();
            }
          }}
        >
          <div className="flex  items-center gap-x-2 justify-center">
            <SpeakerWaveIcon className={classNames('w-4 h-4', play && 'animate-ping')} />
            {t('voices.play')}
          </div>
        </Button>
      </CardFooter>
    </Card>
  );
}

export function ModalWideLocal({ children }: { children: ReactNode }) {
  return (
    <div className={`fixed inset-0 z-50 bg-background bg-opacity-50 flex justify-center items-center`}>
      <div className={`modal-box w-full max-w-5xl h-full z-50 p-0 border-2 border-border`}>{children}</div>
    </div>
  );
}
export function ModalLocal({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="fixed inset-0 z-50 bg-base-100 bg-opacity-50 flex justify-center items-center ">
      <div className={`modal-box z-50`}>{children}</div>
    </div>
  );
}
