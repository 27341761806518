import { useNavigate, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { Layout } from '../Layout';
import { PageLoaderWithNav } from '../components/PageLoaderWithNav';
import Pricing from '../components/Pricing';
import { fetcher } from '../hooks/useStudios';
import { useTranslation } from 'react-i18next';

export default function AboSelect() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const [searchParams] = useSearchParams();
  const { data, isLoading } = useSWR(`${import.meta.env.VITE_API_URL}/products`, fetcher);

  if (isLoading) {
    return <PageLoaderWithNav />;
  }

  return (
    <Layout>
      <div className="col-span-12 mx-auto w-full rounded-3xl lg:mx-0 lg:flex justify-center p-8">
        <div className="p-8 md:flex justify-between w-full max-w-2xl rounded-3xl ring-white/10 ring-1">
          <div className="lg:flex-auto flex justify-between">
            <div>
              <h3 className="text-2xl font-bold tracking-tight text-gray-100">Lizzen Free</h3>
              <p className="mt-6 text-base leading-7 text-gray-300">{t('aboSelect.freeDescription')}</p>
            </div>
          </div>
          <div>
            <div
              onClick={() =>
                navigate(`/onboarding/freeplan?studioname=${searchParams.get('studioname')}`, { replace: true })
              }
              className="cursor-pointer mt-10 block w-full rounded-md px-3 py-2 text-center text-sm font-semibold text-primary ring-1 ring-primary hover:text-primary/90 hover:ring-primary/90 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t('aboSelect.label')}
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 justify-center">
        <Pricing plans={data} />
      </div>
    </Layout>
  );
}
