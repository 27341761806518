import { useOidc } from '@axa-fr/react-oidc';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Register() {
  const oidc = useOidc();
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  query.set('email', '');
  useEffect(() => {
    oidc.login('/', { extras: query.toString() });
  });
  return (
    <main className="flex min-h-screen items-center justify-center bg-base-200">
      <p>Loading...</p>
    </main>
  );
}
