import { sdk } from 'ec.fdk';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function CreateStudioForm({
  checkoutSessionID,
  customerID,
  onAbort,
}: {
  checkoutSessionID?: string;
  customerID?: string;
  onAbort: any;
}) {
  const navigate = useNavigate();
  const [creating, setCreating] = useState(false);

  const { t } = useTranslation('translation');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: '',
    },
  });

  const submit = async (data: any) => {
    try {
      setCreating(true);
      const { title } = data;
      const url = new URL(`${import.meta.env.VITE_EC_TASK_URL}/lizzen.setup-studio`);
      const res = await (
        await fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title,
            checkoutSessionID: checkoutSessionID ? checkoutSessionID : undefined,
            customerID: checkoutSessionID ? undefined : customerID,
          }),
        })
      ).json();
      const {
        _links: {
          monitor: { href },
        },
      } = res;

      url.pathname = href;
      const getMessages = () => fetch(url.toString()).then((res) => res.json());
      await getMessages();
      const interval = setInterval(async () => {
        const events = await getMessages();
        const latest = events[events.length - 1];
        if (latest.isSuccess) {
          clearInterval(interval);
          const { shortID } = latest;
          const localSDK = sdk(import.meta.env.VITE_ENV).dm(shortID);
          const projects = (await localSDK.model('project').entries()) as any;
          const chapter = (await localSDK.model('chapter').entries()) as any;
          const projectID = projects.items[0].id;
          const chapterID = chapter.items[0].id;
          if (!projectID || !chapterID) {
            toast.error('Studio konnte nicht erstellt werden!');
            console.error('Missing project ID or chapter ID');
          }
          toast.success('Studio wurde erstellt!');
          setTimeout(() => navigate('/' + shortID + '/projects/' + projectID + '/chapters/' + chapterID), 1500);
          setCreating(false);
        }
        if (latest.isError) {
          clearInterval(interval);
          setCreating(false);
          toast.error('Studio konnte nicht erstellt werden!');
          console.log('error', latest);
        }
      }, 5000);
    } catch (error) {
      setCreating(false);
      toast.error('Studio konnte nicht erstellt werden!');
      console.error('error', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Gib hier den Namen für dein Studio ein.</span>
        </label>
        <input
          {...register('title', {
            required: t('global.required'),
            pattern: {
              value: /^[a-zA-Z0-9_\-:*, ]+$/,
              message: 'Nur folgende Zeichen sind im Namen erlaubt: a-z, A-Z, 0-9, _, -, :, *, Leerzeichen',
            },
          })}
          autoComplete="off"
          type="text"
          placeholder="Name eintragen..."
          className="input input-bordered"
          name="title"
        />
        {errors.title && <span className="text-xs text-error mt-2">{errors.title.message}</span>}
      </div>
      <div className="form-control">
        <div className="flex items-end py-4 gap-x-4">
          {/**
           * //TODO: What needs to happen here?
           */}
          <button
            className="btn grow"
            onClick={(e) => {
              onAbort();
              e.preventDefault();
            }}
          >
            Abbrechen
          </button>
          <button className="btn btn-primary grow" type="submit" disabled={creating}>
            Studio erstellen {creating && <span className="loading loading-infinity loading-xs" />}
          </button>
        </div>
      </div>
    </form>
  );
}
