import { useParams } from 'react-router-dom';
import useSWR from 'swr';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export function useStudioApi(path: any, swrOptions?: any) {
  const { shortID } = useParams();
  return useSWR(path ? `${import.meta.env.VITE_API_URL}/${shortID}/${path}` : null, fetcher, {
    revalidateOnFocus: false,
    ...swrOptions,
  });
}
