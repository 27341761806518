import { usePlainFdk } from '@/fdk';
import { TrackContext } from '@/routes/ChapterDetail';
import { classNames } from '@/utils';
import { Bars2Icon, TrashIcon } from '@heroicons/react/20/solid';
import { t } from 'i18next';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import useSWR from 'swr';

export default function DndItem({ index, remove }) {
  const { watch } = useFormContext();
  const { currentSectionIndex } = useContext(TrackContext);
  const title = watch(`chapter.content.sections.${index}.title`);
  const description = watch(`chapter.content.sections.${index}.content`);
  const type = watch(`chapter.content.sections.${index}.type`);

  return (
    <div
      key={index}
      className={classNames(
        'group w-full  h-12 flex items-center gap-3 justify-start rounded-lg p-4',
        //@ts-ignore
        currentSectionIndex === index && 'bg-primary/50 text-dark',
      )}
    >
      <div className="w-5 h-5 p-2 ml-1 flex items-center justify-center">
        <div className="w-4 h-4 flex p-2 text-center align-middle items-center justify-center group-hover:hidden">
          <span className={classNames('text-gray-400', currentSectionIndex === index && 'text-background ')}>
            {index + 1}
          </span>
        </div>
        <div className="hidden p-2  group-hover:flex">
          <Bars2Icon className="w-4 h-4" />
        </div>
      </div>

      <div className="flex flex-col truncate">
        <small
          className={classNames('text-gray-400 text-sm truncate', currentSectionIndex === index && '!text-background')}
        >
          {title || `${t('chapter.section')} ${index + 1}`}
        </small>
        <small
          className={classNames('text-gray-600 text-xs truncate', currentSectionIndex === index && '!text-background')}
        >
          {type === 'text' ? description : <FileName assetID={description} />}
        </small>
      </div>

      <div
        className="invisible ml-auto group-hover:visible items-center bg-base hover:text-destructive rounded p-2"
        onClick={() => {
          remove(index);
        }}
      >
        <TrashIcon className="w-4 h-4 " />
      </div>
    </div>
  );
}

const FileName = ({ assetID }) => {
  const fdk = usePlainFdk();
  const { data } = useSWR(assetID ? `${assetID}/filename` : null, () =>
    fdk.assetGroup('audio_resource').getAsset(assetID),
  );

  return assetID ? data?.title.substring(0, 13) + '...' : null;
};
