import { classNames } from '../utils';

export default function Button({ children, ...props }) {
  return (
    <button {...props} className={classNames('btn btn-primary', props.className)}>
      {children}
    </button>
  );
}

Button.Circle = ({ children, ...props }) => {
  return (
    <button {...props} className="btn btn-primary btn-circle">
      {children}
    </button>
  );
};
Button.RoundedFull = ({
  children,
  primary,
  onClick,
  disabled,
  className,
  ...props
}: {
  children: any;
  primary?: boolean;
  onClick?: () => any;
  disabled?: boolean;
  className?: string;
  props?: any;
}) => {
  return (
    <button
      {...props}
      onClick={onClick}
      className={classNames(
        'border rounded-full h-min',
        primary ? 'bg-primary border-none' : 'bg-transparent border-primary',
        disabled && 'cursor-not-allowed pointer-events-none opacity-75',
        className,
      )}
    >
      {children}
    </button>
  );
};
Button.Rounded = ({
  children,
  primary,
  onClick,
  disabled,
  className,
}: {
  children: any;
  primary?: boolean;
  onClick?: () => any;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'border rounded-md overflow-hidden',
        primary ? 'bg-primary border-none' : 'bg-transparent border-primary',
        disabled && 'cursor-not-allowed pointer-events-none opacity-75',
        className,
      )}
    >
      {children}
    </button>
  );
};
