import { Content } from '@/routes/ChapterDetail';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getUsedAudios = (content: Content) => {
  const usedAudios = content.sections.reduce((acc, cur) => {
    if (cur.type === 'file') {
      acc.push(cur.content);
    }
    return acc;
  }, [] as string[]);
  return usedAudios;
};

export const getUsedLocalVoices = (content: Content) => {
  const voices = content.sections.reduce((acc, cur) => {
    if (cur.voiceID && !cur.voiceID.includes('global')) {
      acc.push(cur.voiceID);
    }
    return acc;
  }, [] as string[]);

  if (content?.voiceID && !content.voiceID.includes('global')) {
    voices.push(content.voiceID);
  }
  return voices;
};
