import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { DataTableColumnHeader } from '@/components/ui/data-table/data-table-column-header';
import DataTable from '@/components/ui/data-table/data-table-skeleton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  ArrowDownTrayIcon,
  EllipsisHorizontalIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { DocumentDuplicateIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { LanguageIcon } from '@heroicons/react/24/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { Layout } from '../Layout';
import { ChapterCopyModal } from '../components/Editor/ChapterCopyModal';
import { ChapterTranslateModal } from '../components/Editor/ChapterTranslateModal';
import EmptyState from '../components/EmptyState';
import { Modal, hideModal, showModal } from '../components/Modal';
import { TopNav } from '../components/TopNav';
import { useFdk, usePlainFdk } from '../fdk';
import { getFlagEmoji, languages } from '../utils';

function LocaleFilter({ setLocale, locale }) {
  const { projectID } = useParams();
  const fdk = usePlainFdk();
  const { i18n, t } = useTranslation('translation');

  const { data: list } = useFdk({
    model: 'chapter',
    action: 'entryList',
    options: { project: projectID, _fields: ['language'], _count: 1000 },
  });

  const locales: any = useMemo(
    () =>
      Array.from(new Set(list?.items?.map((chapter) => chapter.language) ?? []))
        .filter(Boolean)
        .sort((a: any, b: any) => a.localeCompare(b)),
    [list?.items],
  );

  const getLanguage = useCallback(
    (locale) => {
      const { name } = languages[i18n.language]?.find(({ language }) => language === locale) ?? {
        name: 'Deutsch',
      };
      return { name };
    },
    [i18n.language],
  );

  if (locales.length <= 1) {
    return null;
  }

  return (
    <div className="flex gap-2 items-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="flex items-center gap-2">
            {locale ? <span className="text-lg">{getFlagEmoji(locale)}</span> : <LanguageIcon className="w-5 h-5" />}
            {locale ? <span>{getLanguage(locale).name}</span> : t('chapters.language')}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mt-3 w-52 rounded-box bg-background p-2 shadow-2xl">
          {locales.map((locale) => {
            const { name } = getLanguage(locale);

            return (
              <DropdownMenuItem key={locale} onClick={() => setLocale(locale)}>
                <span>{getFlagEmoji(locale)}</span>
                <span className="ml-2">{name}</span>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
      {locale && (
        <Button
          onMouseDown={(e) => {
            e.preventDefault();
            setLocale(null);

            return false;
          }}
          variant="secondary"
          className="p-1"
        >
          <XMarkIcon className="w-5 h-5" />
        </Button>
      )}
    </div>
  );
}

export default function ProjectDetail() {
  const { t } = useTranslation('translation');
  const { projectID } = useParams();
  const navigate = useNavigate();
  const { shortID } = useParams();
  const [selectedChapter, setSelectedChapter] = useState<any>({});

  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => <div className="w-[240px]">{row.getValue('name')}</div>,
      },
      {
        accessorKey: '_created',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Erstellt" />,
        cell: ({ row }) => (
          <div className="w-[240px]">
            {dayjs(row.getValue('_created')).format('DD.MM.YYYY, HH:mm')} {t('projects.time')}
          </div>
        ),
      },
      {
        accessorKey: '_modified',
        header: () => 'Zuletzt bearbeitet',
        cell: ({ row }) => (
          <div className="w-[240px]">
            {dayjs(row.getValue('_modified')).format('DD.MM.YYYY, HH:mm')} {t('projects.time')}
          </div>
        ),
      },
      {
        accessorKey: 'language',
        header: () => 'Sprache',
        cell: ({ row }) => <div className="flex justify-center">{getFlagEmoji(row.original.language)}</div>,
      },
      {
        accessorKey: 'options',
        header: () => null,
        cell: ({ row }) => {
          const chapter = row.original;
          const fdk = usePlainFdk();
          const { t } = useTranslation('translation');

          return (
            <>
              {/* {chapter.state === 'generating' && <Loader fillColor="fill-base-100" textColor="text-primary" size={5} />} */}
              <DropdownMenu>
                <DropdownMenuTrigger tabIndex={0} className="h-full flex items-center justify-center w-full ">
                  <EllipsisHorizontalIcon className="h-4 w-4" />
                </DropdownMenuTrigger>
                <DropdownMenuContent tabIndex={0} className="mt-3 w-52 rounded-box bg-background p-2 shadow-2xl">
                  {chapter.state !== 'draft' && chapter.state !== 'generating' && chapter.currentAudio?.length && (
                    <DropdownMenuItem
                      onClick={async (e: any) => {
                        try {
                          e.stopPropagation();
                          const {
                            file: { url },
                            title,
                          } = await fdk.assetGroup('audio_files').getAsset(chapter.currentAudio[0]);
                          const a = document.createElement('a');
                          a.href = url;
                          a.download = title;
                          a.click();
                        } catch (e) {
                          console.error(e);
                          toast.error('Konnte Datei nicht herunterladen');
                        }
                      }}
                    >
                      <ArrowDownTrayIcon className="w-4 h-4 cursor-pointer mr-2" />
                      <span>{t('chapters.download')}</span>
                    </DropdownMenuItem>
                  )}
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedChapter(chapter);
                      showModal('chapterTranslateModal');
                    }}
                  >
                    <LanguageIcon className="w-4 h-4 cursor-pointer mr-2" />
                    <span>{t('chapters.translate')}</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedChapter(chapter);
                      showModal('copyModal');
                    }}
                  >
                    <DocumentDuplicateIcon className="w-4 h-4 mr-2 cursor-pointer" />
                    <span>{t('chapters.duplicate')}</span>
                  </DropdownMenuItem>
                  {chapter.state !== 'generating' && (
                    <>
                      <DropdownMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedChapter(chapter);
                          showModal('renameChapterModal');
                        }}
                      >
                        <PencilSquareIcon className="w-4 h-4 mr-2 cursor-pointer" />
                        <span>{t('chapters.rename')}</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedChapter(chapter);
                          showModal('deleteModal');
                        }}
                      >
                        <TrashIcon className="w-4 h-4 mr-2 cursor-pointer" />
                        <span>{t('chapters.delete')}</span>
                      </DropdownMenuItem>
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          );
        },
      },

      // {
      //   accessorKey: 'description',
      //   header: ({ column }) => <DataTableColumnHeader column={column} title="Beschreibung" />,
      //   cell: ({ row }) => <div className="w-[240px]">{row.getValue('description')}</div>,
      // },
    ],
    [],
  );
  const [searchValue, setSearchValue] = useState('');
  const [locale, setLocale] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const options = useMemo(() => {
    const options = {
      project: projectID,
      page: pagination.pageIndex + 1,
      size: pagination.pageSize,
    } as any;
    if (locale) {
      options.language = locale;
    }
    searchValue.length > 2 && (options['name~'] = searchValue);
    return options;
  }, [searchValue, pagination, locale]);

  const { data: project, mutate } = useFdk(
    {
      model: 'project',
      action: 'getEntry',
      entryID: projectID,
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  );

  const { data: chapterList, mutate: mutateChapterList } = useFdk(
    {
      model: 'chapter',
      action: 'entryList',
      options,
    },
    // ,
    // {
    //   onSuccess: (data) => {
    //     setChapters((current) => current.concat(data.items));
    //   },
    // },
  );

  // const canLoadMore = useMemo(() => chapterList?.total > perPage * page, [chapterList, page]);

  return (
    <Layout>
      <TopNav>
        <div className="flex grow items-center justify-between space-x-4">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link to={`/${shortID}/projects`}>{t('chapters.projects')}</Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />

              <BreadcrumbItem>
                <Link to={`/${shortID}/projects`}>{project?.name}</Link>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="gap-x-2 hidden sm:flex">
            <Button variant="secondary" className="btn btn-sm" onClick={() => showModal('editModal')}>
              <PencilSquareIcon className="w-5 h-5" />
              <span className="ml-2">{t('chapters.rename')}</span>
            </Button>
            <Link to="create">
              <Button className="btn btn-sm">
                <PlusIcon className="w-5 h-5" />
                <span className="ml-2">{t('chapters.newChapter')}</span>
              </Button>
            </Link>
          </div>
          <div className="!h-8 dropdown z-10 block sm:hidden">
            <DropdownMenu>
              <DropdownMenuTrigger tabIndex={0} className="btn btn-circle !w-8 !h-8 !min-h-[2rem] btn-ghost">
                <div className="w-8 rounded-full flex justify-center items-center">
                  <EllipsisVerticalIcon className="h-6 w-6" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent tabIndex={0} className="mt-3 w-52 rounded-box bg-background p-2 shadow-2xl">
                <DropdownMenuItem onClick={() => showModal('editModal')}>
                  <PencilSquareIcon className="w-5 h-5 mr-2" />
                  <span>{t('chapters.rename')}</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => showModal('createModal')}>
                  <PlusIcon className="w-5 h-5 mr-2" />
                  <span>{t('chapters.newChapter')}</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </TopNav>
      <ProjectEditModal
        id="editModal"
        project={project}
        onMutate={async () => {
          await mutate();
          hideModal('editModal');
        }}
      />
      <ChapterDeleteModal
        id="deleteModal"
        entryID={selectedChapter?.id}
        mutate={() => {
          mutateChapterList();
        }}
      />
      <ChapterEditModal
        id="renameChapterModal"
        chapter={selectedChapter}
        onMutate={() => {
          hideModal('renameChapterModal');
          mutateChapterList();
        }}
      />
      <ChapterCopyModal id="copyModal" chapter={selectedChapter} />
      <ChapterTranslateModal id="chapterTranslateModal" chapter={selectedChapter} />
      <section className="card col-span-12 overflow-hidden bg-base-100 shadow-sm xl:col-span-10 mt-6">
        {chapterList?.total === 0 && searchValue === '' ? (
          <EmptyState
            title={t('chapters.emptyState.headline')}
            description={t('chapters.emptyState.description')}
            error={t('chapters.emptyState.error')}
            onClick={() => navigate('create')}
          />
        ) : (
          <div className="space-y-2">
            <DataTable
              pending={!chapterList?.items}
              skeletonColumns={['Name', 'Erstellt']}
              skeletonRows={5}
              searchValue={searchValue}
              setSearchValue={(x) => {
                setPagination({
                  pageIndex: 0,
                  pageSize: pagination.pageSize,
                });
                setSearchValue(x);
              }}
              filter={<LocaleFilter setLocale={setLocale} locale={locale} />}
              pagination={pagination}
              onRowClick={(row) => navigate(`chapters/${row.original.id}`)}
              setPagination={setPagination}
              data={chapterList?.items || []}
              pageCount={Math.ceil(chapterList?.total / pagination.pageSize)}
              columns={columns}
            />
          </div>
        )}
      </section>
    </Layout>
  );
}

export function ProjectEditModal({ id, project, onMutate }: { id: string; project: any; onMutate: () => void }) {
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation('translation');
  const schema = z.object({
    name: z.string().min(1),
  });
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: { name: project?.name },
  });

  return (
    <Modal id={id}>
      <h3 className="font-bold text-lg">{t('chapters.projectEditModal.headline')}</h3>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (values) => {
            setPending(true);
            await fdk.model('project').editEntry(project.id, { ...values });
            toast.success('Projekt gespeichert!');
            await onMutate();
            setPending(false);
          })}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('chapters.projectEditModal.projectName')}</FormLabel>
                <FormControl>
                  <Input {...field} placeholder={t('chapters.projectEditModal.projectNamePlaceholder')} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="form-control">
            <div className="flex items-end justify-end w-full py-4 gap-x-2">
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  hideModal('editModal');
                }}
              >
                {t('chapters.projectEditModal.cancel')}
              </Button>
              <Button type="submit" disabled={pending}>
                {t('chapters.projectEditModal.save')}{' '}
                {pending && <span className="loading loading-infinity loading-xs" />}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </Modal>
  );
}

export function ProjectDeleteModal({ id, entryID, mutate }: { id: string; entryID: string; mutate: any }) {
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation('translation');
  return (
    <Modal id={id}>
      <p className="py-4">{t('chapters.projectDeleteModal.headline')}</p>
      <div className="modal-action flex gap-3 justify-end">
        <Button variant="secondary" onClick={() => hideModal('deleteModal')}>
          {t('chapters.projectDeleteModal.cancel')}
        </Button>
        <Button
          type="button"
          className="btn btn-accent"
          disabled={pending}
          onClick={async () => {
            try {
              setPending(true);
              await fdk.model('project').deleteEntry(entryID);
              toast.success(t('chapters.projectDeleteModal.success'));
              await mutate();
              hideModal('deleteModal');
            } catch (e: any) {
              if (e.message.includes('Cannot delete entry. Referenced as required.')) {
                toast.error(t('chapters.projectDeleteModal.required'));
              } else {
                toast.error(t('chapters.projectDeleteModal.error'));
              }
            } finally {
              setPending(false);
            }
          }}
        >
          {t('chapters.projectDeleteModal.delete')}{' '}
          {pending && <span className="loading loading-infinity loading-xs" />}
        </Button>
      </div>
    </Modal>
  );
}

export function ChapterDeleteModal({ id, entryID, mutate }: { id: string; entryID: string; mutate: any }) {
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation('translation');
  return (
    <Modal id={id}>
      <p className="py-4">{t('chapters.chapterDeleteModal.headline')}</p>
      <div className="modal-action flex gap-3 justify-end">
        <Button variant="secondary" onClick={() => hideModal('deleteModal')}>
          {t('chapters.chapterDeleteModal.cancel')}
        </Button>
        <Button
          type="button"
          className="btn btn-accent"
          disabled={pending}
          onClick={async () => {
            try {
              setPending(true);
              await fdk.model('chapter').deleteEntry(entryID);
              toast.success(t('chapters.chapterDeleteModal.success'));
              await mutate();
              hideModal('deleteModal');
            } catch (e: any) {
              if (e.message.includes('Cannot delete entry. Referenced as required.')) {
                toast.error(t('chapters.chapterDeleteModal.required'));
              } else {
                toast.error(t('chapters.chapterDeleteModal.error'));
              }
            } finally {
              setPending(false);
            }
          }}
        >
          {t('chapters.chapterDeleteModal.delete')}{' '}
          {pending && <span className="loading loading-infinity loading-xs" />}
        </Button>
      </div>
    </Modal>
  );
}

export function ChapterEditModal({ id, chapter, onMutate }: { id: string; chapter: any; onMutate: () => void }) {
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation('translation');
  const form = useForm({
    resolver: zodResolver(z.object({ name: z.string().min(1, { message: t('global.required') }) })),
    defaultValues: {
      name: chapter.name,
    },
  });

  return (
    <Modal id={id} headline={t('chapters.ChapterEditModal.headline')}>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (values) => {
            setPending(true);
            await fdk.model('chapter').editEntry(chapter.id, { ...values });
            toast.success('Kapitel gespeichert!');
            await onMutate();
            setPending(false);
          })}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('chapters.ChapterEditModal.chapterName')}</FormLabel>
                <FormControl>
                  <Input {...field} placeholder={t('chapters.ChapterEditModal.chapterNamePlaceholder')} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end py-4 gap-x-2">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                hideModal('renameChapterModal');
              }}
            >
              {t('chapters.ChapterEditModal.cancel')}
            </Button>
            <Button type="submit" disabled={pending}>
              {t('chapters.ChapterEditModal.save')}{' '}
              {pending && <span className="loading loading-infinity loading-xs" />}
            </Button>
          </div>
        </form>
      </Form>
    </Modal>
  );
}
