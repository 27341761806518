import { Layout } from '../Layout';
import NewStudioForm from '../components/NewStudioForm';
import { googleTagManagerEvent } from '../utils';
import { useTranslation } from 'react-i18next';

export default function Onboarding() {
  const { t } = useTranslation('translation');
  googleTagManagerEvent('onboarding');

  return (
    <Layout>
      <div className="col-span-12 bg-base-100 w-full h-screen flex flex-col justify-center items-center gap-y-4">
        <h1 className="text-2xl">{t('projects.onboarding.headline')}</h1>
        <NewStudioForm />
      </div>
    </Layout>
  );
}
