import { useOidc } from '@axa-fr/react-oidc';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Button from '../components/Button';
import { PageLoader } from '../components/PageLoader';
import { useTranslation } from 'react-i18next';

export default function EmailVerification({ abort = false }: any) {
  const [message, setMessage] = React.useState('');
  const { jwt } = useParams();
  const { login } = useOidc();
  const { t } = useTranslation('translation');
  useEffect(() => {
    if (!jwt) {
      console.warn('navigate login');
      login('/');
    }
  }, [jwt]);

  useEffect(() => {
    if (jwt) {
      if (abort) {
        fetch(`${import.meta.env.VITE_accountServerUrl}/auth/email-verification/abort/${jwt}`, {
          method: 'GET',
        }).then((resp) => {
          if (resp.ok) {
            setMessage(t('emailVerification.successAbort'));
            toast.success(t('emailVerification.successAbort'));
          } else {
            setMessage(t('emailVerification.errorAbort'));
            toast.error(t('emailVerification.errorAbort'));
          }
        });
      } else {
        fetch(`${import.meta.env.VITE_accountServerUrl}/auth/email-verification/${jwt}`, {
          method: 'GET',
        }).then((resp) => {
          if (resp.ok) {
            setMessage(t('emailVerification.successVerification'));
            toast.success(t('emailVerification.successVerification'));
          } else {
            setMessage(t('emailVerification.errorVerification'));
            toast.error(t('emailVerification.errorVerification'));
          }
        });
      }
    }
  }, [abort, jwt]);

  return (
    <div className="flex gap-4 h-screen items-center justify-center">
      {message ? (
        <div className="flex flex-col justify-center items-center gap-3">
          <h2>{message}</h2>
          <Button $primary onClick={() => login('/')}>
            {t('emailVerification.backToLogin')}
          </Button>
        </div>
      ) : (
        <>
          <h2>{abort ? t('emailVerification.abortLoading') : t('emailVerification.verifyLoading')}</h2>
          <PageLoader />
        </>
      )}
    </div>
  );
}
