import Button from '@/components/Button';
import {
  ColumnFiltersState,
  Row,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type Table as Ttable,
} from '@tanstack/react-table';
import { t } from 'i18next';
import { XIcon } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../input';
import { Skeleton } from '../skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../table';
import { DataTableProps } from './data-table';
import { DataTablePagination } from './data-table-pagination';

export default function DataTableSkeleton<TData, TValue>({
  columns,
  data,
  pageCount,
  pagination,
  setPagination,
  onRowClick,
  searchValue,
  setSearchValue,
  pending,
  skeletonRows,
  skeletonColumns,
  filter = null,
}: DataTableProps<TData, TValue> & {
  tableRef?: React.RefObject<Ttable<TData>>;
  onRowClick?: (row: Row<TData>) => void;
  pagination: any;
  setPagination: any;
  pageCount: number;
  searchValue?: string;
  setSearchValue?: any;
  pending?: boolean;
  skeletonRows?: number;
  skeletonColumns?: string[];
  filter?: any;
}) {
  return (
    <div className="col-span-12 xl:col-span-10">
      <section className="card overflow-hidden bg-base-100 shadow-sm">
        <div className="space-y-4">
          <div className="flex justify-between">
            <div className="flex justify-start items-center">
              <Input
                placeholder={t('translation:global.search')}
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                }}
                className="h-8 w-[150px] lg:w-[250px]"
              />
              {searchValue && (
                <Button type="button" onClick={() => setSearchValue('')} variant="ghost" className="px-2">
                  <XIcon className="w-4 h-4 -ml-8" />
                </Button>
              )}
            </div>
            {filter}
          </div>
          {pending ? (
            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  <TableRow>
                    {(skeletonColumns || Array.from(Array(2).keys())).map((header) => {
                      return (
                        <TableHead key={header} colSpan={header}>
                          {header !== 0 ? header : <Skeleton className="w-[100px] h-[20px] rounded-md  bg-border" />}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Array.from(Array(skeletonRows ?? 3).keys()).map((row) => (
                    <TableRow className={onRowClick && 'cursor-pointer'} key={row}>
                      {(skeletonColumns || Array.from(Array(2).keys())).map((cell) => (
                        <TableCell className="h-[53px]" key={cell}>
                          <Skeleton className="w-[100px] h-[20px] rounded-md bg-border" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <DataTable
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              pagination={pagination}
              setPagination={setPagination}
              pageCount={pageCount}
              onRowClick={onRowClick}
              data={data}
              columns={columns}
            />
          )}
        </div>
      </section>
    </div>
  );
}

export function DataTable<TData, TValue>({
  columns,
  data,
  tableRef,
  pageCount,
  pagination,
  setPagination,
  onRowClick,
}: DataTableProps<TData, TValue> & {
  tableRef?: React.RefObject<Ttable<TData>>;
  onRowClick?: (row: Row<TData>) => void;
  pagination: any;
  searchKey?: string;
  setPagination: any;
  pageCount: number;
  searchValue?: string;
  setSearchValue?: any;
}) {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      pagination,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const { t } = useTranslation('translation');

  if (tableRef) {
    // @ts-expect-error - tableRef is not null
    tableRef.current = table;
  }

  return (
    <>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className={onRowClick && 'cursor-pointer'}
                  onClick={() => onRowClick?.(row)}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className="h-[53px]" key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t('translation:dataTable.noResult')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </>
  );
}
