import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { classNames, getFlagEmoji, languages } from '../../utils';
import { Modal, hideModal } from '../Modal';
import { Button } from '../ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../ui/form';

export function ChapterTranslateModal({ id, chapter }: { id: string; chapter?: any }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('translation');
  const { shortID, chapterID, projectID } = useParams<{ shortID: string; chapterID: string; projectID: string }>();
  const [pending, setPending] = useState(false);

  const form = useForm({
    defaultValues: {
      selected: 'EN-GB',
    },
  });

  return (
    <Modal id={id as string} headline={t('chapter.chapterTranslateModal.headline')}>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (values) => {
            try {
              setPending(true);
              const res = await fetch(
                `${import.meta.env.VITE_API_URL}/${shortID}/translate/${chapter?.id ?? chapterID}`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    translateTo: values.selected,
                  }),
                },
              );
              if (!res.ok) {
                toast.error(t('chapter.chapterTranslateModal.error'));
                setPending(false);
                return;
              }
              const data = await res.json();
              if (!data.chapterId) {
                toast.error(t('chapter.chapterTranslateModal.error'));
                setPending(false);
                hideModal('chapterTranslateModal');
                return;
              } else {
                toast.success(t('chapter.chapterTranslateModal.success'));
                hideModal('chapterTranslateModal');
                setPending(false);
                setTimeout(() => navigate(`/${shortID}/projects/${projectID}/chapters/${data.chapterId}`), 1500);
              }
            } catch (error) {
              console.log('error', error);
              hideModal('chapterTranslateModal');
              setPending(false);
              toast.error(t('chapter.chapterTranslateModal.error'));
            }
          })}
        >
          <FormField
            control={form.control}
            name="selected"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('chapter.chapterTranslateModal.description')}</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder={t('translation:chapter.chapterTranslateModal.pleaseChoose')} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {languages[i18n.language].map((language) => (
                          <SelectItem key={language.language} value={language.language}>
                            {getFlagEmoji(language?.countryCode ?? language?.language)}
                            <span className="ml-3">{language?.name}</span>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {/* <SimpleSelect selected={selected} setSelected={setSelected} /> */}
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end py-4 gap-x-2">
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                hideModal('chapterTranslateModal');
              }}
            >
              {t('chapter.chapterTranslateModal.cancel')}
            </Button>
            <Button type="submit" disabled={pending}>
              {t('chapter.chapterTranslateModal.translate')}{' '}
              {pending && <span className="loading loading-infinity loading-xs" />}
            </Button>
          </div>
        </form>
      </Form>
    </Modal>
  );
}

export default function SimpleSelect({ selected, setSelected }: { selected: any; setSelected: any }) {
  const { i18n } = useTranslation('translation');
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="relative mt-2 w-full flex justify-center">
            <Listbox.Button className="relative cursor-default rounded-full bg-base-200 py-1.5 pl-3 pr-10 text-left text-white shadow-sm ring-1 ring-inset ring-primary focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
              <div className="flex gap-x-2">
                <span className="block truncate">{getFlagEmoji(selected?.countryCode ?? selected?.language)}</span>
                <span className="block truncate">{selected?.name}</span>
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-primary" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-10 max-h-72 w-min overflow-auto rounded-md bg-base-200 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {languages[i18n.language].map((language) => (
                  <Listbox.Option
                    key={language.name}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-lizzen text-white' : 'text-white',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={language}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex gap-x-2">
                          <span className="block truncate">
                            {getFlagEmoji(language?.countryCode ?? language?.language)}
                          </span>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {language?.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
