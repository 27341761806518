import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import translationEN from '@/assets/locales/en/translation.json';
import translationDE from '@/assets/locales/de/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
  })
  .then(async () => {
    const res = await fetch(import.meta.env.VITE_accountServerUrl);
    const { locale } = await res.json();
    if (locale) {
      const { language } = new Intl.Locale(locale);
      i18n.changeLanguage(language);
    }
  });

export default i18n;
