import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { PageLoader } from '../components/PageLoader';
import { fetcher } from '../hooks/useStudios';

export default function AboManageSuccess() {
  const { shortID, checkoutSessionID } = useParams();

  const { data } = useSWR(`${import.meta.env.VITE_API_URL}/${shortID}/upgrade-freeplan/${checkoutSessionID}`, fetcher);
  if (data) {
    window.location.href = `/${shortID}/settings/abo-manage`;
  }
  if (!data) return <PageLoader />;
}
