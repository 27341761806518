import { useState } from 'react';
import toast from 'react-hot-toast';
import { hideModal } from '../Modal';
import { useParams } from 'react-router-dom';
import { Button } from '../ui/button';
import { useTranslation } from 'react-i18next';

export function AboCancelModal({ id }: { id: string }) {
  const [pending, setPending] = useState(false);
  const { t } = useTranslation();
  const { shortID } = useParams();

  return (
    <>
      <h3 className="font-bold text-lg">{t('translation:projects.aboManage.cancelModal.headline')}</h3>
      <form
        onSubmit={async (e: any) => {
          setPending(true);
          e.preventDefault();
          try {
            const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/abo`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (!res.ok) {
              toast.error('Fehler beim Kündigen des Abos');
              console.error('Error canceling abo', res);
              return;
            }
          } catch (error) {
            console.error('error', error);
            toast.error('Fehler beim kündigen des Abos');
          }
          hideModal('cancelAbo');
          setPending(false);
        }}
      >
        <div className="form-control">
          <label className="label">
            <span className="label-text">{t('translation:projects.aboManage.cancelModal.description')}</span>
          </label>
        </div>
        <div className="form-control">
          <div className="flex items-end py-4 gap-x-2">
            <Button
              variant="secondary"
              className="btn grow"
              onClick={(e) => {
                e.preventDefault();
                hideModal('cancelAbo');
              }}
            >
              {t('translation:projects.aboManage.cancelModal.abort')}
            </Button>
            <Button variant="destructive" className="btn btn-primary grow" type="submit" disabled={pending}>
              {t('translation:projects.aboManage.cancelModal.confirm')}
              {pending && <span className="loading loading-infinity loading-xs" />}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
