import { useOidc } from '@axa-fr/react-oidc';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BuildingLibraryIcon, HomeIcon, SpeakerWaveIcon, Squares2X2Icon } from '@heroicons/react/20/solid';
import {
  ArrowDownTrayIcon,
  ArrowLeftEndOnRectangleIcon,
  Cog6ToothIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { UseFieldArrayMove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFdk, usePlainFdk } from '../fdk';
import { useStudios } from '../hooks/useStudios';
import { TrackContext } from '../routes/ChapterDetail';
import { MenuItem } from './Editor/MenuItem';
import { SheetHeader } from './ui/sheet';

const NavLink = ({ to, children, exact = false }: React.PropsWithChildren<{ to: string; exact?: boolean }>) => {
  const location = useLocation();
  const isActive = exact ? location.pathname === to : location.pathname.startsWith(to);
  return (
    <Link to={to} className={isActive ? 'bg-primary text-dark hover:bg-primary focus:bg-primary' : ''}>
      {children}
    </Link>
  );
};

export function SideNav() {
  const pathname = useLocation().pathname;
  const { shortID } = useParams<{ shortID: string }>();
  const { t } = useTranslation('translation');

  const oidc = useOidc();

  return (
    <aside className="drawer-side z-10">
      <label htmlFor="my-drawer" className="drawer-overlay"></label>
      {/* <!-- sidebar menu --> */}
      <nav className="flex min-h-screen w-72 flex-col gap-2 overflow-y-auto bg-base-200 px-6 py-10">
        <Link to="/">
          <div className="mx-4 flex items-center gap-2 font-black">
            <img src="/logo.svg" className="w-8 h-8" />
            lizzen studio
          </div>
        </Link>
        <ul className="menu">
          <StudiosLink />
          {pathname !== '/' && !pathname.includes('/onboarding') && (
            <li>
              <NavLink to={`/${shortID}/projects`}>
                <Squares2X2Icon className="h-5 w-5" />
                {t('global.projects')}
              </NavLink>
              <NavLink to={`/${shortID}/voices`}>
                <SpeakerWaveIcon className="h-5 w-5" />
                {t('global.voices')}
              </NavLink>
            </li>
          )}
        </ul>
        {pathname !== '/' && !pathname.includes('/onboarding') && (
          <>
            <div className="mx-3 mt-6">
              <div className="text-sm text-primary">{t('global.channels')}</div>
            </div>
            <ul className="menu">
              <li>
                <NavLink to={`/${shortID}/audio-guide`}>
                  <BuildingLibraryIcon className="h-5 w-5" />
                  {t('global.audioGuide')}
                </NavLink>
              </li>
              <li>
                <NavLink to={`/${shortID}/audio-guide`}>
                  <BuildingLibraryIcon className="h-5 w-5" />
                  Audioguide Beta
                </NavLink>
              </li>
            </ul>
          </>
        )}

        <div className=" mt-auto">
          <ul className="menu">
            {shortID && (
              <li>
                <NavLink to={`/${shortID}/settings`}>
                  <Cog6ToothIcon className="h-5 w-5" />
                  {t('global.settings')}
                </NavLink>
              </li>
            )}
            <li>
              <a className="cursor-pointer" onClick={async () => oidc.logout('/')}>
                <ArrowLeftEndOnRectangleIcon className="h-5 w-5" />
                {t('global.logout')}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </aside>
  );
}

const StudiosLink = () => {
  const { data: studios } = useStudios();
  const { t } = useTranslation('translation');

  if (!studios || !studios?.['_embedded']?.['ec:datamanager'].length) {
    return null;
  }

  return (
    <li>
      <NavLink to={`/`} exact>
        <HomeIcon className="h-5 w-5" />
        {t('global.studios')}
      </NavLink>
    </li>
  );
};

export function SideNavSections({ fields, move }: { fields: any; move: UseFieldArrayMove }) {
  const { chapterID } = useParams();
  const { getValues, watch } = useFormContext();
  const { setCurrentSection } = useContext(TrackContext);
  const fdk = usePlainFdk();

  const handleDragEnd = async ({ active, over }) => {
    if (active && over && active.id !== over.id) {
      const indexActive = fields.findIndex((item) => item.id === active.id);
      const indexOver = fields.findIndex((item) => item.id === over.id);
      move(indexActive, indexOver);
      await fdk
        .model('chapter')
        .editEntry(chapterID as string, { content: { sections: getValues('chapter.content.sections') } });
    }
  };

  const handleDragStart = (event) => {
    setCurrentSection(event.active);
    document.getElementById(event.active.id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <aside className="drawer-side z-10">
      <label htmlFor="my-drawer" className="drawer-overlay"></label>
      {/* <!-- sidebar menu --> */}
      <nav className="flex w-72 flex-col gap-2 overflow-y-auto bg-base-200">
        <DndContext
          autoScroll={false}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext items={fields}>
            <ul className="menu p-0 gap-y-2 mt-8">
              {fields.map((item, index) => (
                <DndSection
                  key={item.id}
                  id={item.id}
                  title={watch(`chapter.content.sections.${index}.title`)}
                  content={watch(`chapter.content.sections.${index}.content`)}
                  type={watch(`chapter.content.sections.${index}.type`)}
                  index={index}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      </nav>
    </aside>
  );
}

export function SideNavHistory() {
  const { t } = useTranslation('translation');
  const { getValues } = useFormContext();

  const assetID = getValues('chapter.historyAudio').join(',');
  const { data, isLoading } = useFdk(
    assetID
      ? {
          assetGroup: 'audio_files',
          action: 'assetList',
          options: { assetID },
        }
      : null,
  );

  if (!data && isLoading)
    return (
      <>
        <SheetHeader>
          <h5 className="text-xl">{t('chapter.history.history')}</h5>
        </SheetHeader>
        <div className="flex justify-center items-center absolute w-full h-full">
          <span className="loading loading-infinity loading-lg"></span>
        </div>
      </>
    );

  if (!data && !isLoading)
    return (
      <>
        <SheetHeader>
          <h5 className="text-xl">{t('chapter.history.history')}</h5>
        </SheetHeader>
        <div className="flex grow justify-center items-center w-full">
          <span>{t('chapter.history.noHistoryFound')}</span>
        </div>
      </>
    );

  return (
    <>
      <SheetHeader>
        <h5 className="text-xl">{t('chapter.history.history')}</h5>
      </SheetHeader>
      <ul className="menu p-0 gap-y-2">
        {data?.items?.map((file: any) => (
          <MenuItem
            key={file?.assetID}
            headline={file?.created ? dayjs(file?.created).format('DD.MM.YYYY HH:mm') : ''}
            iconLeft={<DocumentTextIcon className="w-6 h-6 text-white" />}
            iconRight={<ArrowDownTrayIcon className="w-6 h-6 text-primary" />}
            onRightButtonHref={file?.file?.url}
          />
        ))}
      </ul>
    </>
  );
}

const DndSection = ({
  id,
  title,
  content,
  type,
  index,
}: {
  id: string;
  title: string;
  content: string;
  type: 'file' | 'text';
  index: number;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };
  const { currentSection } = useContext(TrackContext);

  return (
    <li style={style} ref={setNodeRef} {...attributes} {...listeners}>
      {type === 'file' ? (
        <AudioMenuItem id={id} title={title} index={index} />
      ) : (
        <MenuItem
          key={id}
          headline={title}
          subline={content}
          iconLeft={<EllipsisVerticalIcon className="w-6 h-6 text-white" />}
          active={currentSection?.id === id ? true : false}
        />
      )}
    </li>
  );
};

const AudioMenuItem = ({ id, title, index }: { id: string; title: string; index: number }) => {
  const { getValues } = useFormContext();
  const assetID = getValues(`chapter.content.sections.${index}.content`);
  const { data } = useFdk(
    assetID !== ''
      ? {
          assetGroup: 'audio_resource',
          action: 'getAsset',
          assetID,
        }
      : null,
  );
  const { currentSection } = useContext(TrackContext);

  return (
    <MenuItem
      key={id}
      headline={title}
      subline={data?.title}
      iconLeft={<EllipsisVerticalIcon className="w-6 h-6 text-white" />}
      active={currentSection?.id === id ? true : false}
    />
  );
};
