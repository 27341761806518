import Intercom from '@intercom/messenger-js-sdk';
import { Outlet, useParams } from 'react-router-dom';
import useSWR from 'swr';

export default function () {
  const params = useParams();
  const { data } = useSWR('account', async () => {
    const res = await fetch(import.meta.env.VITE_accountServerUrl);
    if (res.ok) {
      const data = await res.json();

      const {
        _links: {
          'ec:account': { href: url },
        },
      } = data;
      const accountRes = await fetch(url);
      if (res.ok) {
        return accountRes.json();
      }
    }
    return null;
  });

  if (!import.meta.env.DEV) {
    Intercom({
      app_id: 'kqz19o0j',
      email: data?.email ?? null,
      created_at: data?.created ?? 0,
      user_id: data?.accountID ?? null,
      studioID: params?.shortID ?? null,
      projectID: params?.projectID ?? null,
      chapterID: params?.chapterID ?? null,
      baseUrl: location.origin,
    });
  }

  return <Outlet />;
}
