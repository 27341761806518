import useSWR from 'swr';

export const fetcher = (url) => fetch(url).then((res) => res.json());

export function useStudios(page = 1, count = 15, title = undefined) {
  const { data, ...rest } = useSWR(
    `https://datamanager${
      import.meta.env.VITE_ENV === 'stage' ? '.cachena' : ''
    }.entrecode.de/?_list=true&page=${page}&_count=${count}&template=a761c001-9225-44ee-8c57-141ef92693b5${title ? `&title~=${title}` : ''}`,
    fetcher,
    { revalidateOnFocus: false },
  );

  return {
    data,
    ...rest,
  };
}
