import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Layout } from '../Layout';
import { showModal } from '../components/Modal';
import { PageLoaderWithNav } from '../components/PageLoaderWithNav';
import { Frequency, Plan, useFrequency } from '../components/Pricing';
import { AboCancelModal } from '../components/settings/AboCancelModal';
import { fetcher } from '../hooks/useStudios';
import { classNames } from '../utils';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog';

export default function AboManage() {
  const { shortID } = useParams();
  const frequencies = useFrequency();
  const [frequency, setFrequency] = useState(frequencies[1]);
  const { data: plans, isLoading } = useSWR(`${import.meta.env.VITE_API_URL}/${shortID}/products`, fetcher);
  const { t } = useTranslation('translation');

  useEffect(() => {
    const selectedPlan = plans?.find((plan) => plan.selected);
    if (selectedPlan?.selectedInterval) {
      setFrequency(frequencies.find((f) => f.value === selectedPlan.selectedInterval) || frequencies[1]);
    }
  }, [plans]);

  if (isLoading) {
    return <PageLoaderWithNav />;
  }

  return (
    <Layout>
      <div className="col-span-12">
        <div className="bg-background ">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <Tabs defaultValue={frequency.value}>
              <div className="w-full flex items-center justify-center">
                <TabsList>
                  {frequencies.map((option: Frequency) => (
                    <TabsTrigger value={option.value}>{option.label}</TabsTrigger>
                  ))}
                </TabsList>
              </div>
              {frequencies.map((option: Frequency) => (
                <TabsContent value={option.value}>
                  <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none xl:grid-cols-4 lg:grid-cols-2">
                    {plans
                      ?.sort((p) => p.sort)
                      .map((plan, index) => {
                        const selectedPlanIndex = plans.findIndex((p) => p.selected);
                        let type;
                        selectedPlanIndex === index && plan?.selectedInterval === frequency.value
                          ? (type = 'current')
                          : null;
                        selectedPlanIndex > index ? (type = 'downgrade') : null;
                        selectedPlanIndex < index ? (type = 'upgrade') : null;
                        return <SinglePrice plan={plan} frequency={option} type={type} key={index} />;
                      })}
                  </div>
                </TabsContent>
              ))}
            </Tabs>
          </div>
          <div className="flex text-xs mt-4 mx-8">
            {t('aboManage.cancelAbo')}
            <Dialog>
              <DialogTrigger>
                <span className="ml-2 underline cursor-pointer" onClick={() => showModal('cancelAbo')}>
                  {t('aboManage.cancelAboButton')}
                </span>
              </DialogTrigger>
              <DialogContent>
                <AboCancelModal id="cancelAbo" />
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const SinglePrice = ({
  plan,
  frequency,
  type,
}: {
  plan: Plan;
  frequency: Frequency;
  type: 'current' | 'upgrade' | 'downgrade';
}) => {
  const { t } = useTranslation('translation');
  const [pending, setPending] = useState(false);
  const { shortID } = useParams();

  const { mutate } = useSWR(`${import.meta.env.VITE_API_URL}/${shortID}/products`, fetcher);

  const changeAbo = async (plan: Plan) => {
    if (type === 'current') {
      return;
    }

    setPending(true);
    try {
      const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/abo`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productID: plan.id,
          interval: frequency.value,
        }),
      });
      if (res.status === 409) {
        toast.error(t('aboManage.errorMessageTwo'));
        return;
      }
      if (res.status === 402) {
        const resJson = await res.json();
        window.location = resJson.url;
        return;
      }
      if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
      }

      toast.success(t('aboManage.successMessage'));
    } catch (error) {
      console.error('error', error);
      toast.error(t('aboManage.errorMessage'));
    }
    mutate();
    setPending(false);
  };
  return (
    <div
      key={plan.title}
      className={classNames(
        type === 'current' ? 'bg-white/5 ring-2 ring-primary' : 'ring-1 ring-white/10',
        'rounded-3xl p-8 xl:p-10',
      )}
    >
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-4xl font-bold tracking-tight text-white">{plan?.minutes_inclusive}</span>
        <span className="text-sm font-semibold leading-6 text-gray-300">{t('aboManage.minutes')}</span>
      </p>
      <p>
        <span className="text-sm font-semibold leading-6 text-gray-300">{t('aboManage.voiceGeneration')}</span>
      </p>
      <h3 id={plan.title} className="text-lg font-semibold leading-8 text-white mt-8">
        {plan.title}
      </h3>
      <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10 h-28">
        {plan?.highlights?.map((feature) => (
          <li key={feature} className="flex gap-x-3">
            <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
            {feature}
          </li>
        ))}
      </ul>

      <p className="mt-6 flex items-baseline gap-x-1">
        {plan?.prices?.month && plan?.prices?.year && (
          <span className="text-2xl font-bold tracking-tight text-white">
            {plan?.prices[frequency?.value as keyof typeof plan.prices]} €
          </span>
        )}
        <span className="text-sm font-semibold leading-6 text-gray-300">{frequency?.priceSuffix}</span>
      </p>

      <div
        onClick={() => changeAbo(plan)}
        aria-describedby={plan.title}
        className={classNames(
          pending || type === 'current' ? 'cursor-not-allowed' : 'cursor-pointer',
          type === 'current'
            ? 'bg-primary text-white shadow-sm hover:bg-primary/80 focus-visible:outline-primary'
            : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
          'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
        )}
      >
        {!pending ? (
          (type === 'current' && 'Aktuelles Abo') || (type !== 'current' && 'wählen')
        ) : (
          <span className="loading loading-infinity loading-xs" />
        )}
      </div>
    </div>
  );
};
