import { ReloadComponent } from '@/components/ReloadComponent.tsx';
import { ProjectCreateSTT } from '@/routes/ProjectCreateSTT.tsx';
import * as axaPackage from '@axa-fr/oidc-client/package.json';
import { OidcProvider, OidcSecure, useOidc } from '@axa-fr/react-oidc';
import { useEffect, useMemo } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import CreateStudio from './components/CreateStudio.tsx';
import CustomColors from './components/CustomColors.tsx';
import Intercom from './components/Intercom.tsx';
import { PageLoader } from './components/PageLoader.tsx';
import { SubscriptionCheck } from './components/SubscriptionCheck.tsx';
import AboManage from './routes/AboManage.tsx';
import AboManageSuccess from './routes/AboManageSuccess.tsx';
import AboSelect from './routes/AboSelect.tsx';
import AudioGuideCreate from './routes/AudioGuideCreate.tsx';
import AudioGuideDetail from './routes/AudioGuideDetail.tsx';
import AudioGuideList from './routes/AudioGuideList.tsx';
import ChapterDetail from './routes/ChapterDetail.tsx';
import EmailVerification from './routes/EmailVerification.tsx';
import Onboarding from './routes/Onboarding.tsx';
import { ProjectCreate } from './routes/ProjectCreate.tsx';
import ProjectDetail from './routes/ProjectDetail.tsx';
import Projects from './routes/Projects.tsx';
import Register from './routes/Register.tsx';
import Settings from './routes/Settings.tsx';
import Studios from './routes/Studios.tsx';
import Voices from './routes/Voices.tsx';

function Callback() {
  const loc = useLocation();
  const state = new URLSearchParams(loc.search).get('state');
  if (state && state.startsWith('redirect:')) {
    // made up url format for redirects..
    // state is used also for other things so we have to prepend a special string..
    const [_, route] = state.split('redirect:');
    return <Navigate to={route} />;
  }
  return null;
}

function SignupCallback() {
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  const invite = query.get('invite');
  const email = query.get('email');
  const { login } = useOidc();
  useEffect(() => {
    login(`/`, {
      extras: query.toString(),
      //prompt: 'create',
    });
  }, [invite, email]);
  return <p>Du wirst weitergeleitet...</p>;
}

const router = createBrowserRouter([
  {
    element: <Intercom />,
    children: [
      {
        path: '/',
        element: (
          <OidcSecure>
            <Studios />
          </OidcSecure>
        ),
      },
      {
        path: '/:shortID',
        element: (
          <OidcSecure>
            <SubscriptionCheck />
            <Outlet />
            <CustomColors />
          </OidcSecure>
        ),
        children: [
          { index: true, element: <Projects /> },
          {
            path: 'voices',
            element: <Voices />,
          },
          {
            path: 'projects',
            children: [
              { index: true, element: <Projects /> },
              { path: 'create', element: <ProjectCreate /> },
              { path: 'create-stt', element: <ProjectCreateSTT /> },
              {
                path: ':projectID',
                children: [
                  { index: true, element: <ProjectDetail /> },
                  {
                    path: 'create',
                    element: <ProjectCreate chapter />,
                  },
                ],
              },
              {
                path: ':projectID/chapters/:chapterID',
                element: <ChapterDetail />,
              },
            ],
          },
          {
            path: 'audio-guide',
            element: <AudioGuideList />,
          },
          {
            path: 'audio-guide/create',
            element: <AudioGuideCreate />,
          },
          {
            path: 'audio-guide/:tourID',
            element: <AudioGuideDetail />,
          },
          {
            path: 'settings',
            children: [
              { index: true, element: <Settings /> },
              { path: 'abo-manage', element: <AboManage /> },
              { path: 'abo-manage/success/:checkoutSessionID', element: <AboManageSuccess /> },
            ],
          },
        ],
      },
      {
        path: '/onboarding',
        element: (
          <OidcSecure>
            <Onboarding />
          </OidcSecure>
        ),
      },
      {
        path: '/onboarding/aboselect',
        element: (
          <OidcSecure>
            <AboSelect />
          </OidcSecure>
        ),
      },
      {
        path: '/onboarding/:checkoutSessionID',
        element: (
          <OidcSecure>
            <CreateStudio />
          </OidcSecure>
        ),
      },
      {
        path: '/auth/email-verification/:jwt',
        element: <EmailVerification />,
      },
      {
        path: '/auth/email-verification/abort/:jwt',
        element: <EmailVerification abort={true} />,
      },
      {
        path: 'authentication',
        children: [
          {
            // auth would work without this route, but we need to fetch the state for redirects
            path: 'callback',
            element: <Callback />,
          },
        ],
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'signup',
        element: <SignupCallback />,
      },
    ],
  },
]);

export function Router() {
  const configuration = useMemo(
    () => ({
      client_id: import.meta.env.VITE_ENV === 'stage' ? 'lizzen-staging' : 'lizzen',
      redirect_uri: window.location.origin + '/authentication/callback',
      silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
      scope: 'openid profile email ecapi offline_access',
      authority:
        import.meta.env.VITE_ENV === 'stage'
          ? 'https://lizzen-studio-login.cachena.entrecode.de/oidc'
          : 'https://login.lizzen.de/oidc',
      service_worker_relative_url: `/OidcServiceWorker.js?v=${axaPackage.version}`,
      service_worker_only: false,
    }),
    [],
  );

  return (
    <OidcProvider
      configuration={configuration}
      loadingComponent={PageLoader}
      authenticatingComponent={PageLoader}
      callbackSuccessComponent={PageLoader}
      sessionLostComponent={ReloadComponent}
    >
      <RouterProvider router={router} />
    </OidcProvider>
  );
}
