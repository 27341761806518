import { DropZone } from '@/components/FormItems';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { usePlainFdk } from '@/fdk';
import i18n from '@/i18n';
import { getFlagEmoji, languages } from '@/utils';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  title: z.record(z.string(), z.string().min(1, { message: 'Pflichtfeld' })),
  description: z.record(z.string(), z.string()),
  cover: z
    .object({
      assetID: z.string(),
    })
    .or(z.string().or(z.null())),
  duration: z.number(),
  publish_date: z.string(),
  tags: z.array(z.string()),
  stations: z.array(
    z.object({
      title: z.record(z.string(), z.string().min(1, { message: 'Titel ist zu kurz' })),
      artist: z.string(),
      cover: z
        .object({
          assetID: z.string(),
        })
        .or(z.string()),
      description: z.object({}),
    }),
  ),
  languages: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Mind. eine Sprache hinzufügen' }),
});

export default function AudioGuideCreate() {
  const { t } = useTranslation('translation');
  const { shortID } = useParams();

  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      title: {
        de_DE: null,
      },
      description: {},
      cover: null,
      duration: null,
      publish_date: null,
      tags: [],
      stations: [],
      languages: [
        {
          label: 'Deutsch',
          value: 'de_DE',
          flag: '🇩🇪',
        },
      ],
    },
  });

  const fdk = usePlainFdk();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const currentLang = i18n.language;
  const langs = useMemo(() => {
    return languages[currentLang].map((lang) => ({
      label: lang.name,
      value: lang.language,
      flag: getFlagEmoji(lang.countryCode ?? lang.language),
    }));
  }, [currentLang]);

  return (
    <div className="drawer-content flex items-center justify-center min-h-screen w-screen">
      <div className="space-y-24">
        <div className="space-y-6 text-center">
          <h3 className="text-2xl">{t('audioGuide.create')}</h3>
        </div>
        <Card className="card p-6 min-w-[50vw] col-span-12 overflow- bg-base-100 shadow-sm xl:col-span-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <Form {...form}>
            <div className="col-span-3 flex flex-col gap-6 items-center">
              {step === 0 && (
                <>
                  <h2>{t('translation:audioGuide.createPage.langHeadline')}</h2>
                  <div className="flex flex-col gap-6">
                    <FormField
                      control={form.control}
                      name={'languages'}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('audioGuide.detail.tourLanguages')}</FormLabel>
                          <FormControl>
                            <>
                              <div className="flex">
                                <div className="flex flex-wrap gap-2">
                                  {field.value.map((e) => (
                                    <Badge variant="outline" className="mr-1">
                                      {e.label}{' '}
                                      <XMarkIcon
                                        onClick={(ev: any) => {
                                          ev.stopPropagation();
                                          field.onChange(field.value.filter((v) => v !== e));
                                        }}
                                        className=" cursor-pointer ml-2 w-4 h-4"
                                      />
                                    </Badge>
                                  ))}
                                </div>
                                <Select value="" onValueChange={(value) => field.onChange([...field.value, value])}>
                                  <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder={t('audioGuide.detail.tourLanguagesAdd')} />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectGroup>
                                      {langs
                                        .filter((lang) => !field.value.some((v) => v.value === lang.value))
                                        .map((lang) => (
                                          <SelectItem value={lang as any}>{lang.label}</SelectItem>
                                        ))}
                                    </SelectGroup>
                                  </SelectContent>
                                </Select>
                              </div>
                            </>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={'languages'}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('audioGuide.detail.tourDefaultLanguage')}</FormLabel>
                          <FormControl>
                            <Select
                              value={field.value[0] as any}
                              onValueChange={(value) => {
                                const newArray = field.value.filter((v) => v !== (value as any));
                                newArray.unshift(value as any);
                                field.onChange(newArray);
                              }}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Sprache hinzufügen" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  {field.value.map((lang) => (
                                    <SelectItem value={lang as any}>{lang.label}</SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormDescription>{t('audioGuide.detail.tourDefaultLanguageDescription')}</FormDescription>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </>
              )}
              {step === 1 && (
                <>
                  <h2>{t('translation:audioGuide.createPage.titelHeadline')}</h2>
                  <div className=" flex flex-col gap-6 w-full">
                    <Tabs defaultValue={form.watch('languages')[0]?.value}>
                      <TabsList>
                        {form.watch('languages').map((lang: any, i) => (
                          <TabsTrigger
                            key={lang?.value}
                            className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                            value={lang?.value}
                          >
                            <span className="text-sm">{lang?.flag}</span>
                            {lang.label}{' '}
                            {i === 0 && (
                              <div className="text-xs flex gap-2 ">{t('audioGuide.detail.tourDefaultLanguage')}</div>
                            )}
                          </TabsTrigger>
                        ))}
                      </TabsList>
                      {form.watch('languages').map((lang, i) => (
                        <TabsContent value={lang.value} key={lang.value} className="flex flex-col gap-6">
                          <FormField
                            control={form.control}
                            name={`title.${lang.value}` as any}
                            render={({ field }) => (
                              <FormItem className="w-full">
                                <FormLabel> {t('audioGuide.detail.title')} </FormLabel>
                                <FormControl>
                                  <Input {...field} />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`description.${lang.value}` as any}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel> {t('audioGuide.detail.description')} </FormLabel>
                                <FormControl>
                                  <Textarea {...(field as any)} />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TabsContent>
                      ))}
                    </Tabs>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <h2>{t('translation:audioGuide.createPage.coverHeadline')}</h2>
                  <FormField
                    control={form.control}
                    name={`cover`}
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormControl>
                          <DropZone value={field.value} onChange={(e) => field.onChange(e)} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}
              <div className="flex gap-3">
                <Button
                  variant="secondary"
                  onClick={() => {
                    if (step === 0) {
                      navigate('./..');
                    } else {
                      setStep((prev) => Math.max(0, prev - 1));
                    }
                  }}
                >
                  Zurück
                </Button>
                <Button
                  onClick={async () => {
                    if (step === 2) {
                      const values = form.watch();
                      const data = { ...values, languages: values.languages.map((lang) => lang.value).join(',') };

                      const res = await fdk.model('guide_tours').createEntry(data);
                      toast.success('Projekt wurde erstellt!');
                      navigate(`/${shortID}/audio-guide/${res.id}`, { state: { firstView: true } });
                    } else {
                      setStep((prev) => prev + 1);
                    }
                  }}
                >
                  Weiter
                </Button>
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
}
