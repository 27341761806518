import { getUsedAudios } from '@/lib/utils';
import { RadioGroup } from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3BottomLeftIcon,
  ChevronDownIcon,
  MusicalNoteIcon,
  PauseIcon,
  PlusIcon,
  VariableIcon,
} from '@heroicons/react/20/solid';
import { DocumentDuplicateIcon, DocumentPlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  ArrowDownTrayIcon,
  EllipsisVerticalIcon,
  LanguageIcon,
  MicrophoneIcon,
  PlayIcon,
  StopIcon,
} from '@heroicons/react/24/solid';
import { startTour } from '@intercom/messenger-js-sdk';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { ExclamationTriangleIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { chunk } from 'llm-chunk';
import { Children, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useParams } from 'react-router-dom';
import { useFdk, usePlainFdk } from '../../fdk';
import { SectionType, TrackContext } from '../../routes/ChapterDetail';
import { classNames } from '../../utils';
import Loader from '../Loader';
import { showModal } from '../Modal';
import { VoiceSelectLocal } from '../VoiceSelect';
import { Button } from '../ui/button';
import { Card, CardContent, CardHeader } from '../ui/card';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { Input } from '../ui/input';
import { Skeleton } from '../ui/skeleton';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import AudioRecorder from './AudioRecorder';
import mentionClasses from './Section.module.css';

export const Section = ({
  htmlId,
  index,
  remove,
  fields,
}: {
  htmlId: string;
  index: number;
  remove: (index: number) => void;
  fields: any;
}) => {
  const { t } = useTranslation('translation');
  const { register, getValues, control, watch, setValue } = useFormContext();

  const [isPlaying, setIsPlaying] = useState(false);
  const [generatingOverdue, setGeneratingOverdue] = useState(false);
  const player = useRef<any>(null);
  const { chapterID, shortID } = useParams();
  const fdk = usePlainFdk();

  const { currentSection, setCurrentSection, setSectionIsGenerating, errors, editEntry } = useContext(TrackContext);
  const active = fields?.[index].id === currentSection?.id;

  const { mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapterID,
  });
  const section = watch(`chapter.content.sections.${index}`);
  const chapter = watch(`chapter`);

  const isPlayable = !!section?.timestamp;

  const deleteSection = useCallback(async () => {
    remove(index);
    const usedAudios = getUsedAudios(watch('chapter.content'));
    await editEntry({
      content: {
        voiceID: getValues('chapter.content.voiceID'),
        sections: watch('chapter.content.sections'),
      },
      audio_resources: usedAudios,
    });
  }, [remove, index, chapterID, fdk, watch, getValues]);

  const pause = useCallback(() => {
    if (player.current) {
      player.current.pause();
      setIsPlaying(false);
    }
  }, [setIsPlaying, player.current]);

  const play = useCallback(async () => {
    setIsPlaying(true);

    const hashBuffer = await crypto.subtle.digest('sha-1', new TextEncoder().encode(section.content));
    const hashHex = Array.from(new Uint8Array(hashBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');

    const {
      items: [asset],
    } = await fdk.assetGroup('generated_sections').assetList({
      tags: [
        `project:${chapter.project}`,
        `chapter:${chapter.id}`,
        `voice:${section.voiceID ?? chapter.content.voiceID}`,
        `content:${hashHex}`,
      ].join('+'),
    });
    const url = asset?.file.url;
    const audio = new Audio(url);
    player.current = audio;
    audio.oncanplay = () => {
      setIsPlaying(true);
    };
    audio.onended = () => {
      setIsPlaying(false);
    };
    audio.onerror = () => {
      setIsPlaying(false);
    };
    audio.play();
  }, [section?.assetID]);

  const fetchEvents = useCallback(async (jobID: string) => {
    setSectionIsGenerating(true);
    fetchEventSource(`${import.meta.env.VITE_QUEUE_URL}/${shortID}/addJob/${chapterID}/${jobID.split('|')[2]}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      async onmessage({ data, event }) {
        if (event === 'completed') {
          toast.success(t('chapter.sections.generatingSuccess'));
        }
      },
      async onclose() {
        await mutate();
        setSectionIsGenerating(false);
      },
      onerror() {
        editEntry({
          state: 'draft',
          content: {
            ...watch('chapter.content'),
            sections: watch('chapter.content.sections').map((s) => {
              if (s.jobID === jobID) {
                return {
                  ...s,
                  jobID: undefined,
                  generating: undefined,
                };
              }
              return s;
            }),
          },
        });
        setSectionIsGenerating(false);
        toast.error(t('chapter.sections.generatingError'));
        throw new Error('no_retry');
      },
      openWhenHidden: true,
    }).catch(() => null);
  }, []);

  const generate = useCallback(async () => {
    setSectionIsGenerating(true);

    const timestamp = Date.now();

    setValue(`chapter.content.sections.${index}`, {
      ...watch(`chapter.content.sections.${index}`),
      generating: true,
      timestamp,
    });

    await editEntry({
      state: 'draft',
      content: watch('chapter.content'),
    });

    await fetch(`${import.meta.env.VITE_QUEUE_URL}/${shortID}/addJob/${watch('chapter.id')}/${timestamp}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    })
      .then(async (res) => {
        const { jobID } = await res.json();
        if (!jobID) {
          throw new Error('missing jobID');
        }
        fetchEvents(jobID);
      })
      .catch(() => {
        toast.error(t('chapter.sections.generatingError'));
      })
      .finally(() => setSectionIsGenerating(false));
  }, [watch, setValue, fetchEvents]);

  useEffect(() => {
    if (section?.generating === true && section?.jobID) {
      fetchEvents(section.jobID);
    }
    return () => pause();
  }, []);

  useEffect(() => {
    if (section?.generating && !generatingOverdue) {
      const timer = setInterval(() => {
        if (Date.now() - section.timestamp > 60000) {
          setGeneratingOverdue(true);
        }
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [watch(`chapter.content.sections.${index}`)]);

  return (
    <div
      id={htmlId}
      className="mt-8 w-full max-w-[800px] section"
      onClick={() => {
        setCurrentSection(fields?.[index]);
      }}
    >
      <div className="flex flex-col items-center justify-center">
        <Card
          className={`${
            errors.find((error) => error.index === index) ? 'border-red-600' : active ? 'border-primary' : ''
          } rounded-borderRadius-lg mb-4 w-full max-w-[800px]`}
        >
          <CardHeader className="border-b border-border px-2 py-2">
            <div className="flex items-center justify-between flex-wrap gap-y-2 sm:flex-nowrap">
              <Input
                onMouseDown={(e) => {
                  e.stopPropagation();
                  setCurrentSection(fields?.[index]);
                }}
                disabled={section.generating}
                {...register(`chapter.content.sections.${index}.title`)}
                placeholder={`${t('chapter.section')} ${index + 1}`}
                type="text"
                className="rounded-md bg-background block focus:outline-none focus:ring-0 p-2 w-auto h-8"
              />
              <div className="flex items-center gap-x-2 sm:gap-x-4">
                {(section.type === 'text' || section.type === 'speech') && (
                  <Controller
                    name={`chapter.content.sections.${index}.voiceID`}
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <VoiceSelectLocal
                        disabled={section.generating}
                        onChange={async (e) => {
                          onChange(e?.id);
                          setValue(`chapter.content.sections.${index}.timestamp`, Date.now());
                          setValue('chapter.state', 'draft');
                        }}
                        value={value}
                      />
                    )}
                  />
                )}
                <div className="flex sm:gap-x-4 gap-x-1 items-center justify-end sm:w-40">
                  {section?.type === 'text' && !isPlayable && (
                    <span className="text-xs text-gray-400">{t('chapter.sections.changed')}</span>
                  )}
                  {(section?.type === 'text' || section?.type === 'speech') && (
                    <GenerateSectionButton
                      generate={generate}
                      generatingOverdue={generatingOverdue}
                      setGeneratingOverdue={setGeneratingOverdue}
                      section={section}
                      hasDraft={!isPlayable}
                      index={index}
                    />
                  )}

                  {isPlayable && !section?.generating && (
                    <button
                      className="bg-background border rounded-md h-8 w-8 flex items-center justify-center"
                      onClick={() => {
                        !isPlaying ? play() : pause();
                      }}
                    >
                      {isPlaying ? (
                        <StopIcon className="w-5 h-5 text-primary" />
                      ) : (
                        <PlayIcon className="w-5 h-5 text-primary" />
                      )}
                    </button>
                  )}
                  <DropdownMenu>
                    <DropdownMenuTrigger disabled={section.generating}>
                      <div className="w-8 h-8 rounded-md flex justify-center items-center bg-background border">
                        <EllipsisVerticalIcon className="h-6 w-6" />
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="mt-3 w-52 rounded-box bg-background p-2 shadow-2xl">
                      {section.type === 'text' && (
                        <>
                          <DropdownMenuItem onClick={() => showModal('sectionTranslateModal')}>
                            <LanguageIcon className="w-5 h-5" />
                            <span className="ml-1">{t('chapter.sections.translate')}</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={async () => {
                              const { content, pause, title, type, voiceID } = watch(
                                `chapter.content.sections.${index}`,
                              );

                              await editEntry({
                                state: 'draft',
                                content: {
                                  ...watch('chapter.content'),
                                  sections: [
                                    ...watch('chapter.content.sections'),
                                    {
                                      content,
                                      pause,
                                      title: `${title} (Kopie)`,
                                      type,
                                      voiceID,
                                    },
                                  ],
                                },
                              });
                              await mutate();
                            }}
                          >
                            <DocumentDuplicateIcon className="w-5 h-5" />
                            <span className="ml-1">{t('chapter.sections.duplicate')}</span>
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => startTour('537224')}>
                            <VariableIcon className="w-5 h-5" />
                            <span className="ml-1">{t('chapter.sections.addVariable')}</span>
                          </DropdownMenuItem>
                        </>
                      )}
                      {isPlayable && !section.generating && (
                        <DropdownMenuItem
                          onClick={async () => {
                            try {
                              const hashBuffer = await crypto.subtle.digest(
                                'sha-1',
                                new TextEncoder().encode(section.content),
                              );
                              const hashHex = Array.from(new Uint8Array(hashBuffer))
                                .map((b) => b.toString(16).padStart(2, '0'))
                                .join('');
                              const {
                                items: [asset],
                              } = await fdk.assetGroup('generated_sections').assetList({
                                tags: [
                                  `project:${chapter.project}`,
                                  `chapter:${chapter.id}`,
                                  `voice:${section.voiceID ?? chapter.content.voiceID}`,
                                  `content:${hashHex}`,
                                ].join('+'),
                              });

                              const link = document.createElement('a');
                              link.href = asset.file.url;
                              link.download = asset.title;
                              link.click();
                            } catch (error) {
                              console.error('error', error);
                              toast.error(t('chapter.sections.downloadError'));
                            }
                          }}
                        >
                          <ArrowDownTrayIcon className="w-5 h-5" />
                          <span className="ml-1">{t('chapter.sections.download')}</span>
                        </DropdownMenuItem>
                      )}
                      <DropdownMenuItem onClick={deleteSection}>
                        <TrashIcon className="w-5 h-5" />
                        <span className="ml-1">{t('chapter.sections.delete')}</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </div>
          </CardHeader>
          {getValues(`chapter.content.sections.${index}.type`) === 'file' && (
            <CardContent className="flex flex-col min-h-40 space-y-0 rounded-b-xl pt-8">
              <SectionAudio index={index} />
            </CardContent>
          )}
          {getValues(`chapter.content.sections.${index}.type`) === 'speech' && (
            <CardContent className="flex flex-col min-h-40 space-y-0 rounded-b-xl pt-8">
              <SectionSpeech index={index} />
            </CardContent>
          )}
          {getValues(`chapter.content.sections.${index}.type`) === 'text' && <SectionText index={index} />}
        </Card>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Card className="h-8 p-0 px-2 mt-4 flex items-center rounded-borderRadius-xl tooltip">
                <PauseIcon className="w-6 h-6 text-gray-200" />
                <Controller
                  name={`chapter.content.sections.${index}.pause`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      disabled={section.generating}
                      onChange={(e) => {
                        onChange(e);
                        setValue(`chapter.state`, 'draft');
                      }}
                      value={value}
                      defaultValue={0}
                      className={'ml-2 rounded-md flex items-center divide-x divide-base-200'}
                    >
                      {[
                        { value: 0, name: '0' },
                        { value: 0.5, name: '0.5' },
                        { value: 1, name: '1' },
                        { value: 3, name: '3' },
                        { value: 5, name: '5' },
                        { value: 10, name: '10' },
                      ].map((option) => (
                        <RadioGroup.Option
                          disabled={section.generating}
                          key={option.value}
                          value={option.value}
                          className={({ active, checked }) =>
                            classNames(
                              active ? '' : '',
                              checked ? 'bg-primary text-white' : 'bg-base text-gray-900',
                              'w-10 flex items-center justify-center py-1 px-1 text-sm font-semibold sm:flex-1 focus:outline-none focus:ring-0 cursor-pointer',
                              section.generating && 'opacity-50 !cursor-not-allowed',
                            )
                          }
                        >
                          <span className="text-[10px] text-card-foreground">{option.name}</span>
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  )}
                />
              </Card>
            </TooltipTrigger>
            <TooltipContent>
              <p>Pause zwischen Sektionen in Sekunden</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

export const SectionText = ({ index }: { index: number }) => {
  const { setValue, watch } = useFormContext();
  const { setErrors } = useContext(TrackContext);
  const section = watch(`chapter.content.sections.${index}`);
  const sections = watch('chapter.content.sections');
  const { t } = useTranslation();

  return (
    <CardContent className="relative min-h-40 pb-8 space-y-0 rounded-b-xl cursor-text pt-4 flex">
      <MentionsInput
        disabled={section.generating}
        value={section.content ?? null}
        placeholder="Gib hier deinen Text ein"
        id={`section-${index}-textarea`}
        classNames={mentionClasses}
        allowSuggestionsAboveCursor
        onPaste={(e) => {
          const pastedContent = e.clipboardData.getData('text/plain');
          const lines = pastedContent
            .split('\n')
            .map((line) => {
              const content = line.trim();
              if (content.length === 0) {
                return null;
              }

              if (content.length > 850) {
                return chunk(content, {
                  minLength: 800,
                  maxLength: 1000,
                  splitter: 'sentence',
                });
              }

              return content;
            })
            .flat()
            .filter(Boolean);
          if (lines.length > 1) {
            setValue('lastPasted.paragraphs', lines);
            setValue('lastPasted.newContent', pastedContent);
            setValue('lastPasted.oldContent', section.content);
            showModal('ChapterSplitPasteTextModal');
          } else {
            e.clipboardData.setData(
              'text/plain',
              pastedContent.substring(0, pastedContent.length > 2000 ? 2000 : pastedContent.length),
            );
            e.preventDefault();
          }
        }}
        onChange={(e, value) => {
          setValue(`chapter.content.sections.${index}.timestamp`, Date.now());
          setValue(
            `chapter.content.sections.${index}.content`,
            value.substring(0, value.length > 2000 ? 2000 : value.length),
            { shouldDirty: true, shouldValidate: true },
          );
          setErrors((errors) => errors.filter((error) => error.index !== index));
          setValue('chapter.state', 'draft');
        }}
        customSuggestionsContainer={(children) => (
          <div className="text-xs">
            <div className="bg-muted p-2 border-b">Variablen-Name eingeben und mit ⏎ erstellen</div>
            <div className="max-h-28 overflow-y-auto">
              {Children.map(children, (child) => {
                return (
                  <div {...child.props}>
                    {Children.map(child.props.children, (child) => {
                      if (child.props.suggestion.id === null) {
                        return null;
                      }
                      return child;
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      >
        <Mention
          trigger="/"
          data={(search) => [
            ...Array.from(
              new Set(
                [
                  search,
                  ...sections
                    .map(
                      ({ content }) =>
                        content?.match?.(/{{(.*?)}}/g)?.map((variable) => variable.replace(/{{|}}/g, '').trim()) ?? [],
                    )
                    .flat()

                    .filter((variable: any) => variable.includes(search))
                    .sort(),
                ].filter(Boolean),
              ),
            ).map((id) => ({ id })),
            { id: null, display: 'Variablen-Name eingeben und mit ⏎ erstellen' },
          ]}
          markup="{{__id__}}"
          appendSpaceOnAdd
          displayTransform={(id) => ` ${id} `}
          className={mentionClasses.mentions__mention}
        />
      </MentionsInput>
      <TooltipProvider>
        <div className="absolute bottom-4 right-4">
          <span
            className={` text-xs flex gap-3 text-lizzen ${section?.content?.length >= 1000 && 'text-yellow-600'} ${
              section?.content?.length >= 2000 && '!text-red-600'
            }`}
          >
            {section?.content?.length} / 2000
            {section?.content?.length >= 1000 && section?.content?.length < 2000 && (
              <>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <InfoCircledIcon />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t('translation:chapters.noticeLength')}</p>
                  </TooltipContent>
                </Tooltip>
              </>
            )}
            {section?.content?.length >= 2000 && (
              <>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <ExclamationTriangleIcon />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t('translation:chapters.noticeMax')}</p>
                  </TooltipContent>
                </Tooltip>
              </>
            )}
          </span>
        </div>
      </TooltipProvider>
    </CardContent>
  );
};

export const SectionAudio = ({ index }: { index: number }) => {
  const { t } = useTranslation('translation');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const inputRef = useRef<any>(null);
  const fdk = usePlainFdk();
  const { getValues, setValue, watch } = useFormContext();
  const { setErrors, editEntry } = useContext(TrackContext);
  const assetID = getValues(`chapter.content.sections.${index}.content`);
  const { data, mutate } = useFdk(
    assetID !== ''
      ? {
          assetGroup: 'audio_resource',
          action: 'getAsset',
          assetID,
        }
      : null,
  );

  const { watch: watchLocal, setValue: setValueLocal } = useForm({
    defaultValues: {
      file: '',
      fileType: 'file',
      duration: 0,
    },
  });

  useEffect(() => {
    if (data) {
      setValueLocal('file', data.file.url, { shouldDirty: true });
      setValueLocal('fileType', 'url', { shouldDirty: true });
    }
  }, [data]);

  const submit = async (duration: number) => {
    try {
      const response = (await fdk
        .assetGroup('audio_resource')
        .createAsset({ file: watchLocal('file'), options: { deduplicate: true } } as any)) as any;
      if (!response?.assetID) return;
      setValue(`chapter.content.sections.${index}.duration`, duration);
      setValue(`chapter.content.sections.${index}.content`, response?.assetID, { shouldDirty: true });
      setValue(`chapter.content.sections.${index}.timestamp`, Date.now());

      const usedAudios = getUsedAudios(watch('chapter.content'));
      await editEntry({
        state: 'draft',
        content: { ...watch('chapter.content'), sections: watch('chapter.content.sections') },
        audio_resources: usedAudios,
      });
      setErrors((errors) => errors.filter((error) => error.index !== index));
      await mutate();
    } catch (error) {
      console.error('error', error);
    }
  };

  useEffect(() => {
    const files = watchLocal('file') as any;
    const fileType = watchLocal('fileType') as any;
    if (!!files && fileType === 'file' && audioRef.current) {
      audioRef.current.src = files ? URL.createObjectURL(files) : '';
      audioRef.current.onloadeddata = () => {
        setValueLocal('duration', Math.round(audioRef.current ? audioRef.current.duration : 0));
      };
    }
  }, [audioRef.current, watchLocal('file')]);

  return (
    <div className="w-full h-full flex items-center justify-between">
      <div className="h-full w-full">
        <div className="text-center flex justify-center flex-col items-center rounded-md p-4">
          {watchLocal('file') === '' ? (
            <div
              className="flex flex-col items-center justify-center gap-y-2 w-full h-full cursor-pointer"
              onClick={() => {
                inputRef.current.click();
              }}
            >
              <input
                type="file"
                ref={inputRef}
                accept=".mp3"
                className="hidden file-input file-input-bordered file-input-primary bg-base-200 w-full max-w-xs h-8 rounded-md"
                onChange={(e) => {
                  setValue(`chapter.content.sections.${index}.title`, e.target.files?.[0].name);
                  setValueLocal(`file`, e.target.files?.[0] ?? ('' as any), { shouldDirty: true });
                }}
              />
              <DocumentPlusIcon className="w-12 h-12 text-primary/50" />
              <h3 className="mt-2 text-sm font-semibold text-gray-100">{t('chapter.sectionSpeech.uploadFile')}</h3>
              <p className="mt-1 text-sm text-gray-200">{t('chapter.sectionSpeech.selectFile')}</p>
            </div>
          ) : (
            <Player
              url={
                watchLocal('fileType') === 'url' ? watchLocal('file') : URL.createObjectURL(watchLocal('file') as any)
              }
              onDelete={() => {
                setValueLocal('file', '');
                setValueLocal('fileType', 'file');
                setValue(`chapter.content.sections.${index}.title`, '');
                setValue(`chapter.content.sections.${index}.duration`, 0);
                setValue(`chapter.content.sections.${index}.content`, '', { shouldDirty: true });
                setValue(`chapter.content.sections.${index}.timestamp`, Date.now());
                setValue('chapter.state', 'draft');
              }}
              onUpload={submit}
              showUpload={watch(`chapter.content.sections.${index}.content`) === ''}
            />
          )}
        </div>
      </div>
      <MusicalNoteIcon className="w-20 h-20 text-primary/50" />
    </div>
  );
};

export const SectionSpeech = ({ index }: { index: number }) => {
  const { t } = useTranslation('translation');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const inputRef = useRef<any>(null);
  const [recordAudio, setRecordAudio] = useState(false);
  const fdk = usePlainFdk();
  const { getValues, setValue, watch } = useFormContext();
  const { setErrors, editEntry } = useContext(TrackContext);
  const assetID = getValues(`chapter.content.sections.${index}.content`);
  const { data, mutate } = useFdk(
    assetID !== ''
      ? {
          assetGroup: 'audio_resource',
          action: 'getAsset',
          assetID,
        }
      : null,
  );

  const { setValue: setValueLocal, watch: watchLocal } = useForm({
    defaultValues: {
      file: '',
      fileType: 'file',
    },
  });

  useEffect(() => {
    if (data) {
      setValueLocal('file', data.file.url, { shouldDirty: true });
      setValueLocal('fileType', 'url', { shouldDirty: true });
    }
  }, [data]);

  const submit = async (duration: number) => {
    try {
      const response = (await fdk
        .assetGroup('audio_resource')
        .createAsset({ file: watchLocal('file'), options: { deduplicate: true } } as any)) as any;
      if (!response?.assetID) return;
      setValue(`chapter.content.sections.${index}.duration`, duration);
      setValue(`chapter.content.sections.${index}.content`, response?.assetID, { shouldDirty: true });
      setValue(`chapter.content.sections.${index}.timestamp`, Date.now());

      const usedAudios = getUsedAudios(watch('chapter.content'));
      await editEntry({
        state: 'draft',
        content: { ...watch('chapter.content'), sections: watch('chapter.content.sections') },
        audio_resources: usedAudios,
      });

      setErrors((errors) => errors.filter((error) => error.index !== index));

      mutate();
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-between">
      <div className="h-full w-full">
        <div className="text-center flex justify-center flex-col items-center rounded-md p-4">
          {watchLocal('file') === '' ? (
            <div
              className="flex flex-col items-center justify-center gap-y-2 w-full h-full cursor-pointer"
              onClick={() => {
                inputRef.current.click();
              }}
            >
              <input
                type="file"
                ref={inputRef}
                accept=".mp3"
                className="hidden file-input file-input-bordered file-input-primary bg-base-200 w-full max-w-xs h-8 rounded-md"
                onChange={(e) => {
                  setValueLocal(`file`, e.target.files?.[0] ?? ('' as any), { shouldDirty: true });
                }}
              />
              {!recordAudio ? (
                <>
                  <DocumentPlusIcon className="w-12 h-12 text-primary/50" />
                  <h3 className="mt-2 text-sm font-semibold text-gray-100">{t('chapter.sectionSpeech.uploadFile')}</h3>
                  <p className="mt-1 text-sm text-gray-200">{t('chapter.sectionSpeech.selectFile')}</p>
                  <div className="w-4/12 border-b border-border relative flex justify-center my-4">
                    <span className="absolute p-2 bg-muted -top-5 margin-auto rounded-md">
                      {t('chapter.sectionSpeech.or')}
                    </span>
                  </div>

                  <div className="mt-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setRecordAudio(true);
                      }}
                      className="inline-flex items-center"
                    >
                      <MicrophoneIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                      {t('chapter.sectionSpeech.recordAudio')}
                    </Button>
                  </div>
                </>
              ) : (
                <AudioRecorder setValue={setValueLocal} audioRef={audioRef} />
              )}
            </div>
          ) : (
            <Player
              url={
                watchLocal('fileType') === 'url' ? watchLocal('file') : URL.createObjectURL(watchLocal('file') as any)
              }
              onDelete={() => {
                setRecordAudio(false);
                setValueLocal('file', '');
                setValue(`chapter.content.sections.${index}.content`, '', { shouldDirty: true });
                setValue(`chapter.content.sections.${index}.timestamp`, Date.now());
              }}
              onUpload={submit}
              showUpload={watch(`chapter.content.sections.${index}.content`) === ''}
            />
          )}
        </div>
      </div>
      <MicrophoneIcon className="w-20 h-20 text-primary/50" />
    </div>
  );
};

export const Player = ({
  url,
  onDelete,
  onUpload,
  showUpload,
}: {
  url: string;
  onDelete: () => void;
  onUpload: (duration: number) => void;
  showUpload: boolean;
}) => {
  const { t } = useTranslation('translation');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);

  const displayTime = useMemo(() => {
    let totalMinutes = Math.floor(duration / 60);
    let totalSeconds = duration - totalMinutes * 60;
    return `${totalMinutes}:${totalSeconds < 10 ? '0' : ''}${totalSeconds}`;
  }, [duration]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setIsPlaying(false);
      };
      audioRef.current.onloadeddata = () => {
        const duration = Math.round(audioRef?.current ? audioRef?.current.duration : 0);
        console.log('duration', duration);

        setDuration(duration);
      };
    }
  }, [audioRef.current]);

  return (
    <>
      <div className="flex justify-center">
        <div className="flex gap-x-2 items-center justify-between w-full bg-white/5 p-2 rounded-xl">
          <audio src={url} ref={audioRef} controls className="w-full hidden" />
          {isPlaying ? (
            <PauseIcon
              className="w-5 h-5 text-primary cursor-pointer"
              onClick={() => {
                audioRef.current?.pause();
                setIsPlaying(false);
              }}
            />
          ) : (
            <PlayIcon
              className="w-5 h-5 text-primary cursor-pointer"
              onClick={() => {
                if (audioRef.current) {
                  audioRef.current?.load();
                  audioRef.current.oncanplay = () => {
                    setIsPlaying(true);
                    audioRef.current?.play();
                  };
                }
              }}
            />
          )}

          <span>{displayTime}</span>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <TrashIcon className="w-5 h-5 text-primary cursor-pointer" onClick={onDelete} />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Audio löschen</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        {showUpload && (
          <Button disabled={duration > 300} onClick={() => onUpload(duration)} className="ml-2">
            {t('chapter.player.save')}
          </Button>
        )}
      </div>
      {duration > 300 && <span className="text-sm mt-2 text-orange-500">Maximale Länge 5 Minuten</span>}
    </>
  );
};

export const AddSection = ({ append }: { append: any }) => {
  const { t } = useTranslation('translation');
  const { getValues, watch } = useFormContext();
  const { editEntry } = useContext(TrackContext);
  const { mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: watch('chapter.id'),
  });
  const fdk = usePlainFdk();

  const save = useCallback(
    async (data) => {
      await editEntry({
        state: 'draft',
        content: {
          ...watch('chapter.content'),
          sections: [...watch('chapter.content.sections'), data],
        },
      });
    },
    [watch, fdk],
  );

  const addSection = async (data: SectionType) => {
    await save(data);
    await mutate();
  };

  return (
    <div className="flex justify-center mb-12 mt-8">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            <div className="flex items-center gap-x-2 p-2">
              <PlusIcon className="w-5 h-5" />
              {t('chapter.addSection.addSection')}
            </div>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="top"
          className=" border-border border rounded-borderRadius-md overflow-hidden grid grid-cols-3 items-center bg-background divide-x divide-border"
        >
          <DropdownMenuItem
            onClick={async () =>
              await addSection({
                title: `Sektion ${getValues('chapter.content.sections').length + 1}`,
                type: 'file',
                pause: 0,
                content: '',
              })
            }
            className="grid-span-1 flex flex-col items-center justify-center px-6 py-2 hover:bg-muted"
          >
            <MusicalNoteIcon className="w-5 h-5" />
            <span className="text-sm">{t('chapter.addSection.audio')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={async () =>
              await addSection({
                type: 'text',
                pause: 0,
                title: `Sektion ${getValues('chapter.content.sections').length + 1}`,
                content: '',
                voiceID:
                  getValues('chapter.content.sections').length > 0
                    ? getValues(`chapter.content.sections.${getValues('chapter.content.sections').length - 1}.voiceID`)
                    : undefined,
              })
            }
            className="grid-span-1 flex flex-col items-center justify-center px-6 py-2 hover:bg-muted"
          >
            <Bars3BottomLeftIcon className="w-5 h-5" />
            <span className="text-sm">{t('chapter.addSection.text')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={async () =>
              await addSection({
                type: 'speech',
                pause: 0,
                title: `Sektion ${getValues('chapter.content.sections').length + 1}`,
                content: '',
                voiceID:
                  getValues('chapter.content.sections').length > 0
                    ? getValues(`chapter.content.sections.${getValues('chapter.content.sections').length - 1}.voiceID`)
                    : undefined,
              })
            }
            className="grid-span-1 flex flex-col items-center justify-center px-6 py-2 hover:bg-muted"
          >
            <MicrophoneIcon className="w-5 h-5" />
            <span className="text-sm">{t('chapter.addSection.speech')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
export const AddSectionSidebar = ({ append }: { append: any }) => {
  const { t } = useTranslation('translation');
  const [showActions, setShowActions] = useState(false);
  const { editEntry } = useContext(TrackContext);
  const { watch, getValues } = useFormContext();
  const fdk = usePlainFdk();
  const { mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: watch('chapter.id'),
  });

  const save = useCallback(
    async (data) => {
      await editEntry({
        state: 'draft',
        content: {
          ...watch('chapter.content'),
          sections: [...watch('chapter.content.sections'), data],
        },
      });
    },
    [watch, fdk],
  );

  const addSection = async (data: SectionType) => {
    await save(data);
    await mutate();
  };

  return (
    <div className="flex justify-center">
      <div
        className="flex items-center relative w-full"
        onMouseEnter={() => {
          setShowActions(true);
        }}
        onMouseLeave={() => {
          setShowActions(false);
        }}
      >
        {!showActions ? (
          <Button variant="ghost" className="group w-full h-7 flex justify-start text-left gap-3 rounded-lg p-4">
            <div className="w-4 h-4 flex p-2 ml-1 items-center justify-center ">
              <div>
                <PlusIcon className="w-4 h-4 text-" />
              </div>
            </div>

            <div>
              <small className="text-gray-400 group-hover:text-background">{t('chapter.addSection.addSection')}</small>
            </div>
          </Button>
        ) : (
          <div className="bg-transaprent border-border border rounded-md overflow-hidden ml-4">
            <div className="flex grid grid-cols-3 items-center divide-x divide-border">
              <button
                onClick={async () =>
                  await addSection({
                    title: `Sektion ${getValues('chapter.content.sections').length + 1}`,
                    type: 'file',
                    pause: 0,
                    content: '',
                  })
                }
                className="grid-span-1 flex flex-col items-center justify-center px-2 py-1 hover:bg-background"
              >
                <MusicalNoteIcon className="w-4 h-4" />
                <span className="text-xs">{t('chapter.addSection.audio')}</span>
              </button>
              <button
                onClick={async () =>
                  await addSection({
                    type: 'text',
                    pause: 0,
                    title: `Sektion ${getValues('chapter.content.sections').length + 1}`,
                    content: '',
                    voiceID:
                      getValues('chapter.content.sections').length > 0
                        ? getValues(
                            `chapter.content.sections.${getValues('chapter.content.sections').length - 1}.voiceID`,
                          )
                        : undefined,
                  })
                }
                className="grid-span-1 flex flex-col items-center justify-center px-2 py-1 hover:bg-background"
              >
                <Bars3BottomLeftIcon className="w-4 h-4" />
                <span className="text-xs">{t('chapter.addSection.text')}</span>
              </button>
              <button
                onClick={async () =>
                  await addSection({
                    type: 'speech',
                    pause: 0,
                    title: `Sektion ${getValues('chapter.content.sections').length + 1}`,
                    content: '',
                    voiceID:
                      getValues('chapter.content.sections').length > 0
                        ? getValues(
                            `chapter.content.sections.${getValues('chapter.content.sections').length - 1}.voiceID`,
                          )
                        : undefined,
                  })
                }
                className="grid-span-1 flex flex-col items-center justify-center px-2 py-1 hover:bg-background"
              >
                <MicrophoneIcon className="w-4 h-4" />
                <span className="text-xs">{t('chapter.addSection.speech')}</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const GenerateSectionButton = ({ generate, section, hasDraft, generatingOverdue, setGeneratingOverdue, index }) => {
  const [hover, setHover] = useState(false);
  const { sectionIsGenerating, editEntry } = useContext(TrackContext);
  const { t } = useTranslation('translation');
  const showAbort = section?.generating && generatingOverdue && hover;
  const showLoading = section?.generating;
  const disabled =
    sectionIsGenerating ||
    section?.generating ||
    (section?.content.length === 0 && section?.type === 'text') ||
    (section?.type === 'speech' && section.content === '');

  const { setValue, watch } = useFormContext();

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <button
              className={classNames(
                disabled ? 'bg-muted cursor-default' : 'bg-background border',
                showLoading && 'bg-background border cursor-pointer',
                'rounded-md h-8 w-8 !flex items-center justify-center',
              )}
              onClick={async () => {
                if (showAbort) {
                  setValue(`chapter.content.sections.${index}.generating`, undefined);
                  setValue(`chapter.content.sections.${index}.jobID`, undefined);
                  await editEntry({
                    state: 'draft',
                    content: watch(`chapter.content`),
                  });
                  setGeneratingOverdue(false);
                } else {
                  generate();
                }
              }}
              disabled={disabled && !showAbort}
            >
              {showAbort ? (
                <StopIcon className="w-5 h-5 text-primary" />
              ) : section?.generating ? (
                <Loader size={5} textColor="text-base-300" fillColor="fill-primary" />
              ) : (
                <ArrowPathIcon className="w-5 h-5 text-primary" />
              )}
            </button>
          </TooltipTrigger>
          <TooltipContent>
            <p>
              {section?.content.length === 0
                ? `${
                    section.type === 'text'
                      ? t('chapter.sections.tooltipTextNeeded')
                      : t('chapter.sections.tooltipRecordNeeded')
                  }`
                : sectionIsGenerating
                ? t('chapter.sections.tooltipIsGenerating')
                : hasDraft
                ? t('chapter.sections.tooltipChanges')
                : generatingOverdue
                ? t('chapter.sections.tooltipAbort')
                : t('chapter.sections.tooltipRegenerate')}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

Section.Skeleton = () => {
  return (
    <div className="mt-8 w-full max-w-[800px] section">
      <div className="flex flex-col items-center justify-center">
        <Card className="rounded-xl mb-4 w-full max-w-[800px]">
          <CardHeader className="border-b border-border px-2 py-2">
            <div className="flex items-center justify-between flex-wrap gap-y-2 sm:flex-nowrap">
              <Skeleton className="h-10 w-48 border flex items-center px-4">
                <Skeleton className="h-4 w-20 rounded-md bg-border" />
              </Skeleton>
              <div className="flex items-center justify-center gap-x-4">
                <Skeleton className="w-40 h-10 border rounded-md flex items-center justify-center gap-x-2">
                  <Skeleton className="w-6 h-6 bg-border rounded-full" />
                  <Skeleton className="w-20 h-4 bg-border rounded-md" />
                  <ChevronDownIcon className="w-4 h-4 text-border" />
                </Skeleton>
                <Skeleton className="h-8 w-8 rounded-md bg-border" />
                <Skeleton className="h-8 w-8 rounded-md bg-border" />
              </div>
            </div>
          </CardHeader>
          <CardContent className="flex flex-col min-h-40 space-y-0 rounded-b-xl pt-8">
            <Skeleton className="h-48 w-full rounded-md border p-4 space-y-2">
              <Skeleton className="h-4 w-full rounded-md bg-border" />
              <Skeleton className="h-4 w-full rounded-md bg-border" />
              <Skeleton className="h-4 w-full rounded-md bg-border" />
              <Skeleton className="h-4 w-48 rounded-md bg-border" />
            </Skeleton>
          </CardContent>
        </Card>
        <Card className="h-8 p-0 px-2 mt-4 flex items-center rounded-xl tooltip">
          <PauseIcon className="w-6 h-6 text-gray-200 opacity-10" />
          <div className="divide-x divide-base-200 flex items-center ml-2">
            <div className="p-1 w-10 flex items-center justify-center">
              <Skeleton className="h-4 w-4 rounded-md bg-border" />
            </div>
            <div className="p-1 w-10 flex items-center justify-center">
              <Skeleton className="h-4 w-4 rounded-md bg-border" />
            </div>
            <div className="p-1 w-10 flex items-center justify-center">
              <Skeleton className="h-4 w-4 rounded-md bg-border" />
            </div>
            <div className="p-1 w-10 flex items-center justify-center">
              <Skeleton className="h-4 w-4 rounded-md bg-border" />
            </div>
            <div className="p-1 w-10 flex items-center justify-center">
              <Skeleton className="h-4 w-4 rounded-md bg-border" />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
