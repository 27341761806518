import loaderGif from '@/assets/lizzen-loader.gif';
import EmptyState from '@/components/EmptyState';
import { Badge } from '@/components/ui/badge';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Textarea } from '@/components/ui/textarea';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { classNames, getFlagEmoji, languages } from '@/utils';
import { Bars2Icon, XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogClose } from '@radix-ui/react-dialog';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { CommandList } from 'cmdk';
import dayjs from 'dayjs';
import { Check, ChevronsUpDown, Clipboard, Cog, DownloadIcon, Goal, PlusIcon } from 'lucide-react';
import QRCode from 'qrcode';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { z } from 'zod';
import { Layout } from '../Layout';
import { AudioDrop, DndZoneSideMenu, DropZone, InputWithOverlay } from '../components/FormItems';
import { TopNav } from '../components/TopNav';
import { useFdk, usePlainFdk } from '../fdk';
import './../components/react-tags.css';

const formSchema = z.object({
  id: z.string(),
  title: z.record(z.string(), z.string().min(1, { message: 'Pflichtfeld' })),
  description: z.record(z.string(), z.string()),
  cover: z
    .object({
      assetID: z.string(),
    })
    .or(z.string().or(z.null())),
  duration: z.string().or(z.number()).or(z.null()),
  publish_date: z.string(),
  tags: z.array(z.string()),
  stations: z.array(
    z.object({
      id: z.string(),
      title: z.record(z.string(), z.string().min(1, { message: 'Titel ist zu kurz' })),
      artist: z.string(),
      audio_map: z.any(),
      audio_files: z.array(z.string()),
      cover: z
        .object({
          assetID: z.string(),
        })
        .or(z.string().or(z.null())),
      description: z.record(z.string(), z.string()).or(z.any()),
    }),
  ),
  languages: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Mind. eine Sprache hinzufügen' }),
});

export default function AudioGuideDetail() {
  const { shortID, tourID }: any = useParams();
  const { i18n, t } = useTranslation('translation');
  const currentLang = i18n.language;

  const langs = useMemo(() => {
    return languages[currentLang].map((lang) => ({
      label: lang.name,
      value: lang.language,
      countryCode: lang.countryCode,
      flag: getFlagEmoji(lang.countryCode ?? lang.language),
    }));
  }, [currentLang]);

  const fdk = usePlainFdk();

  const { data, isLoading, mutate } = useSWR(tourID, async () => {
    const tour: any = await fdk.model('guide_tours').getEntry(tourID);
    const stationsEntries = await fdk.model('guide_tour_stations').entryList({
      id: tour.stations,
    });

    const tagEntries = await fdk.model('guide_tour_tags').entryList({});

    return {
      tour,
      stationsEntries,
      tagEntries,
    } as any;
  });

  const { tour, stationsEntries, tagEntries } = (isLoading ? {} : data) as any;

  const tags = useMemo(() => {
    if (!tagEntries) {
      return [];
    }
    return !Array.isArray(tagEntries.items) ? [tagEntries.items] : tagEntries.items;
  }, [tagEntries]);

  const form = useForm<z.infer<typeof formSchema>>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),

    defaultValues: {
      title: {
        de_DE: '',
      },
      description: {
        de_DE: '',
      },
      cover: '',
      duration: null,
      publish_date: '',
      tags: [],
      stations: [],
      languages: [{ label: 'Deutsch', value: 'de_DE' }],
    },
  });
  const { watch, control, setValue } = form;

  const stations = useFieldArray({
    name: 'stations',
    control,
    keyName: 'fieldId',
  });

  useEffect(() => {
    if (tour && stationsEntries) {
      form.reset({
        ...tour,
        stations: !Array.isArray(stationsEntries.items)
          ? [stationsEntries.items]
          : tour.stations?.map((id) => stationsEntries.items.find((s) => s.id === id)),
        languages: tour.languages.split(',')?.map((e) => {
          return langs.find((l) => {
            const r =
              l.value.toUpperCase() === e.toUpperCase() ||
              l.value === e.replace('-', '_') ||
              l.value.toUpperCase() === e.substring(0, 2).toUpperCase();
            return r;
          });
        }),
        tags: tags.filter((tag) => tour.tags.includes(tag.id)).map((t) => ({ label: t.tag, value: t.id })),
        publish_date: dayjs(tour.publish_date).format('YYYY-MM-DDTHH:mm'),
      });
    }
  }, [tour, stationsEntries, form, tags]);

  const [qrCode, setQrCode] = useState('');
  const [qrLang, setQrLang] = useState(watch('languages')[0]?.value);
  useEffect(() => {
    //@ts-ignore
    QRCode.toDataURL(
      `https://${
        import.meta.env.VITE_ENV === 'stage' ? 'lizzen-audio-guide.cachena.entrecode.de' : 'audioguide.lizzen.de'
      }/${shortID}/${qrLang}/${tourID}`,
      {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.3,
        width: 200,
        margin: 1,
        color: {
          dark: '#00bf9f',
          light: '#042232',
        },
      },
      (err, url) => setQrCode(url),
    );
  }, [qrLang, tourID, shortID]);

  const [updatePending, setUpdatePending] = useState(false);

  async function onSaveTour(data: any) {
    setUpdatePending(true);

    const values: any = {};
    // parse languages back to comma string
    values.languages = data.languages.map((l: any) => l.value).join(',');
    values.duration = parseInt(data.duration);
    values.title = data.title;
    values.description = data.description;
    values.cover = data.cover;

    try {
      await fdk.model('guide_tours').editEntry(tourID as string, values);
      await mutate();
      toast.success('Tour gespeichert');
    } catch (e) {
      console.log(e);
      toast.error('Tour konnte nicht gespeichert werden');
    } finally {
      setUpdatePending(false);
    }
  }

  async function onSave(data: any) {
    setUpdatePending(true);
    const values = { ...data };
    // parse languages back to comma string
    values.languages = values.languages.map((l: any) => l.value).join(',');
    values.duration = parseInt(values.duration);

    try {
      values.publish_date = new Date(values.publish_date).toISOString();
    } catch (e) {
      values.publish_date = null;
    }

    // check for new Tags
    for (const i in values.tags) {
      const tag = values.tags[i];

      if (tag.value === tag.label) {
        // new Tag -> create
        try {
          const newTag = (await fdk.model('guide_tour_tags').createEntry({
            tag: tag.label,
          })) as any;
          values.tags[i] = newTag.id;
        } catch (e) {
          console.log(e);
          toast.error('Ein Tag konnte nicht gespeichert werden');
        }
        continue;
      }

      // existing Tag
      values.tags[i] = tag.value;
    }

    let newStationId;

    try {
      for (const index in values.stations) {
        const station = { ...values.stations[index] };
        const stationApi = fdk.model('guide_tour_stations');

        // sanitize audio_files and chapters for stations

        const chapters: string[] = [];
        const audio_files: string[] = [];

        // got through all languages
        (Object.values(station.audio_map || {}) as any).forEach((mapping: { type: string; id: string }) => {
          if (mapping.type === 'chapter') {
            chapters.push(mapping.id);
          } else {
            audio_files.push(mapping.id);
          }
        });

        // if (station.chapters.length === 0) station.chapters = null;
        // if (station.audio_files.length === 0) station.audio_files = null;
        if (station.cover === '') {
          station.cover = null;
        }

        // create or save station
        if (station.id.startsWith('new:')) {
          const entry = await stationApi.createEntry({
            ...station,
            chapters,
            audio_files,
          });
          values.stations[index] = entry;
          if (activeSection.startsWith('new:')) {
            ({ id: newStationId } = entry);
          }
        } else {
          const data = {
            ...station,
            chapters,
            audio_files,
          };
          await stationApi.editEntry(station.id, data);
        }
      }
    } catch (e) {
      console.log(e);
      toast.error('Eine Station konnte nicht gespeichert werden');
    }

    try {
      await fdk.model('guide_tours').editEntry(tourID as string, values);
      await mutate();
      if (newStationId) {
        setActiveSection(newStationId);
      }
      toast.success('Tour gespeichert');
    } catch (e) {
      console.log(e);
      toast.error('Tour konnte nicht gespeichert werden');
    } finally {
      setUpdatePending(false);
    }
  }

  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState<any>();

  useEffect(() => {
    if (activeSection) {
      return;
    }
    setActiveSection(stations.fields.length ? stations.fields[0].id : null);
  }, [stations.fields]);

  const [activeSettings, setActiveSettings] = useState('desc');
  const [settingsOpen, setSettingsOpen] = useState(false);

  const index = useMemo(
    () =>
      Math.max(
        0,
        stations.fields.findIndex((station: any) => station.id === activeSection),
      ),
    [stations.fields, activeSection],
  );

  if (isLoading) {
    return (
      <>
        <Form key="liad" {...form}>
          <Layout
            aside={
              <aside className=" min-w-[300px] sticky top-16">
                <nav className="grid items-start px-4 text-sm font-medium lg:px-4 pt-6 md:pt-10">
                  <div className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
                    <Cog className="w-5 h-5 text-gray-400" />
                    <Skeleton className="w-9/12 h-5 bg-border" />
                  </div>
                  <div className="flex mt-2 items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all ">
                    <Goal className="w-5 h-5 text-gray-400" /> <Skeleton className="w-9/12 h-5 bg-border" />
                  </div>
                </nav>

                <ul className="mx-8 px-2 text-sm font-medium pt-1 flex flex-col gap-y-2">
                  <DndZoneSideMenu
                    setActiveSection={() => {}}
                    items={Array(11).fill(0)}
                    move={() => {}}
                    remove={() => {}}
                  >
                    {(index: number) => {
                      return (
                        <>
                          <Skeleton
                            className={classNames(
                              'group w-full h-12 flex items-center gap-3 justify-start rounded-lg p-4 border',
                            )}
                          >
                            <div className="w-5 h-5 p-2 ml-1 flex items-center justify-center">
                              <div className="w-4 h-4 flex p-2 text-center align-middle items-center justify-center group-hover:hidden">
                                {form.formState.errors?.stations?.[index] ? (
                                  <div>
                                    <ExclamationTriangleIcon className="w-4 h-4 text-destructive" />
                                  </div>
                                ) : (
                                  <span className={classNames('text-gray-400')}>{index + 1}</span>
                                )}
                              </div>
                              <div className="hidden p-2 group-hover:flex">
                                <Bars2Icon className="w-4 h-4" />
                              </div>
                            </div>

                            <Skeleton className="h-4 w-full rounded-md bg-border" />
                          </Skeleton>
                        </>
                      );
                    }}
                  </DndZoneSideMenu>
                </ul>
              </aside>
            }
          >
            <main className="drawer-content px-6 pb-6 space-y-6">
              <TopNav>
                <div className="flex grow items-baseline space-x-4 pt-1.5">
                  <Breadcrumb>
                    <BreadcrumbList>
                      <BreadcrumbItem>
                        <Skeleton className="w-40 h-6 bg-border" />
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <Skeleton className="w-52 h-6 bg-border" />
                      </BreadcrumbItem>
                    </BreadcrumbList>
                  </Breadcrumb>
                </div>
                <div className="sticky bottom-0 bg-base flex items-center justify-end p-2 pb-3">
                  <Skeleton className="w-24 h-10 bg-border rounded-md" />
                </div>
              </TopNav>
              <Card>
                <CardContent className=" flex flex-col gap-3 py-3 pb-7">
                  <FormLabel>{t('audioGuide.detail.section.coverImage')}</FormLabel>
                  <Skeleton className="aspect-video w-12/12 md:w-6/12 bg-border" />
                  <FormLabel>{t('audioGuide.detail.section.artist')}</FormLabel>
                  <Skeleton className="h-10 w-full border flex items-center px-4">
                    <Skeleton className="h-4 w-20 rounded-md bg-border" />
                  </Skeleton>
                </CardContent>
              </Card>
              <div className="h-10 w-6/12 bg-muted flex items-center px-1 py-1 rounded-md">
                <Skeleton className="h-full w-44 rounded-md bg-border" />
              </div>
              <Card>
                <CardContent className=" flex flex-col gap-3 py-3 pb-7">
                  <FormLabel>{t('audioGuide.detail.section.title')}</FormLabel>
                  <Skeleton className="h-10 w-full border flex items-center px-4">
                    <Skeleton className="h-4 w-20 rounded-md bg-border" />
                  </Skeleton>
                  <FormLabel>{t('audioGuide.detail.section.description')}</FormLabel>
                  <Skeleton className="h-24 w-full border flex px-4 py-4">
                    <Skeleton className="h-4 w-20 rounded-md bg-border" />
                  </Skeleton>
                  <div className="pt-3">
                    <FormLabel>{t('audioGuide.detail.section.upload.headline')}</FormLabel>
                  </div>
                </CardContent>
              </Card>
            </main>
          </Layout>
        </Form>
      </>
    );
  }

  return (
    <>
      <Form {...form}>
        <Layout
          aside={
            <aside className=" min-w-[300px] sticky top-16">
              {/* <!-- sidebar menu --> */}
              <div className="flex-1 sticky top-16 bg-muted flex">
                <nav className="grid items-start px-4 text-sm font-medium lg:px-4 pt-6 md:pt-10">
                  <div className="flex items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary">
                    <Dialog
                      open={settingsOpen}
                      onOpenChange={(open) => {
                        setSettingsOpen(open);
                      }}
                    >
                      <DialogTrigger className="flex gap-3 items-center">
                        {form.formState.errors.title ||
                        form.formState.errors.description ||
                        form.formState.errors.cover ||
                        form.formState.errors.languages ? (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger>
                                <ExclamationTriangleIcon className="w-5 h-5 text-destructive" />
                              </TooltipTrigger>
                              <TooltipContent side="right">
                                <p>Nicht alle Werte sind korrekt ausgefüllt </p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        ) : (
                          <Cog className="w-5 h-5 text-gray-400" />
                        )}{' '}
                        {t('audioGuide.detail.tourSettings')}
                      </DialogTrigger>
                      <DialogContent
                        onCloseAutoFocus={() => {
                          form.resetField('cover');
                          form.resetField('title');
                          form.resetField('description');
                        }}
                        className="!bg-base-100 max-w-[80vw] overflow-x-auto min-h-[550px] flex flex-col items-start justify-between"
                      >
                        <div>
                          <DialogHeader>
                            <DialogTitle className="flex item-center gap-3">
                              <Cog className="w-5 h-5 text-gray-400 " />
                              {t('audioGuide.detail.tourSettings')}
                            </DialogTitle>
                          </DialogHeader>
                          <DialogDescription className="w-full">
                            <div className="mx-auto mt-4 grid w-full items- gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
                              <nav
                                className="flex flex-col gap-4 text-sm text-muted-foreground"
                                x-chunk="dashboard-04-chunk-0"
                              >
                                <div
                                  onClick={() => setActiveSettings('desc')}
                                  className={
                                    activeSettings === 'desc' ? 'font-semibold text-primary' : 'cursor-pointer'
                                  }
                                >
                                  {t('audioGuide.detail.titleDescription')}{' '}
                                  {form.formState.errors.title && (
                                    <ExclamationTriangleIcon className="w-4 h-4 inline text-destructive" />
                                  )}
                                </div>
                                <div
                                  onClick={() => setActiveSettings('cover')}
                                  className={
                                    activeSettings === 'cover' ? 'font-semibold text-primary' : 'cursor-pointer'
                                  }
                                >
                                  {t('audioGuide.detail.titleCover')}
                                  {form.formState.errors.cover && (
                                    <ExclamationTriangleIcon className="w-4 h-4 inline text-destructive" />
                                  )}
                                </div>
                                <div
                                  onClick={() => setActiveSettings('langs')}
                                  className={
                                    activeSettings === 'langs' ? 'font-semibold text-primary' : 'cursor-pointer'
                                  }
                                >
                                  {t('audioGuide.detail.tourLanguages')}{' '}
                                  {form.formState.errors.languages && (
                                    <ExclamationTriangleIcon className="w-4 h-4 inline text-destructive" />
                                  )}
                                </div>
                                <div
                                  onClick={() => setActiveSettings('qr')}
                                  className={activeSettings === 'qr' ? 'font-semibold text-primary' : 'cursor-pointer'}
                                >
                                  {t('audioGuide.detail.tourShare')}
                                </div>
                                <div
                                  onClick={() => setActiveSettings('delete')}
                                  className={
                                    activeSettings === 'delete' ? 'font-semibold text-primary' : 'cursor-pointer'
                                  }
                                >
                                  {t('translation:audioGuide.detail.delete')}
                                </div>
                              </nav>
                              <div className="grid w-full">
                                {activeSettings === 'qr' && (
                                  <Tabs
                                    defaultValue={watch('languages')[1]?.value}
                                    onValueChange={(value) => setQrLang(value)}
                                  >
                                    <TabsList>
                                      {watch('languages').map((lang: any, i) => (
                                        <TabsTrigger
                                          key={lang?.value + i}
                                          className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                                          value={lang?.value}
                                        >
                                          <span className="text-sm">{lang?.flag}</span>
                                          {lang.label}{' '}
                                          {i === 0 && (
                                            <div className="text-xs flex gap-2 ">
                                              {t('audioGuide.detail.tourDefaultLanguage')}
                                            </div>
                                          )}
                                        </TabsTrigger>
                                      ))}
                                    </TabsList>

                                    {watch('languages').map((lang: any, i) => (
                                      <>
                                        <TabsContent value={lang.value}>
                                          <div className="my-3 w-full flex gap-6 col-span-2">
                                            <div className="flex flex-col gap-2">
                                              {qrCode && <img src={qrCode} alt="QR Code" />}
                                              <Button
                                                variant="secondary"
                                                onClick={() => {
                                                  //@ts-ignore
                                                  QRCode.toDataURL(
                                                    `https://${
                                                      import.meta.env.VITE_ENV === 'stage'
                                                        ? 'lizzen-audio-guide.cachena.entrecode.de'
                                                        : 'audioguide.lizzen.de'
                                                    }/${shortID}/${lang.value}/${tourID}`,
                                                    {
                                                      errorCorrectionLevel: 'H',
                                                      type: 'image/jpeg',
                                                      quality: 0.3,
                                                      width: 200,
                                                      margin: 1,
                                                    },
                                                    (err, url) => {
                                                      const a = document.createElement('a');
                                                      a.href = url;
                                                      a.download = 'qr-code.jpg';
                                                      a.click();
                                                    },
                                                  );
                                                }}
                                              >
                                                <DownloadIcon className="mr-2" />
                                                QR-Code speichern
                                              </Button>
                                            </div>

                                            <div className="w-full">
                                              <div>{t('audioGuide.detail.tourShareHeadline')}</div>
                                              <div className="flex gap-4">
                                                <Input
                                                  readOnly
                                                  value={`https://${
                                                    import.meta.env.VITE_ENV === 'stage'
                                                      ? 'lizzen-audio-guide.cachena.entrecode.de'
                                                      : 'audioguide.lizzen.de'
                                                  }/${shortID}/${lang.value}/${tourID}`}
                                                />
                                                <Button
                                                  onClick={() => {
                                                    navigator.clipboard.writeText(
                                                      `https://${
                                                        import.meta.env.VITE_ENV === 'stage'
                                                          ? 'lizzen-audio-guide.cachena.entrecode.de'
                                                          : 'audioguide.lizzen.de'
                                                      }/${shortID}/${lang.value}/${tourID}`,
                                                    );
                                                    toast.success('Link kopiert');
                                                  }}
                                                >
                                                  <Clipboard className="w-4 h-4 " />
                                                </Button>
                                              </div>

                                              <a
                                                href={`https://${
                                                  import.meta.env.VITE_ENV === 'stage'
                                                    ? 'lizzen-audio-guide.cachena.entrecode.de'
                                                    : 'audioguide.lizzen.de'
                                                }/${shortID}/${lang.value}/${tourID}`}
                                                target="_blank"
                                                className="block pt-3"
                                              >
                                                <Button variant="link">{t('audioGuide.detail.tourShareButton')}</Button>
                                              </a>
                                            </div>
                                          </div>
                                        </TabsContent>
                                      </>
                                    ))}
                                  </Tabs>
                                )}

                                {activeSettings === 'delete' && (
                                  <div className="max-w-screen-sm">
                                    <div className="mb-3">{t('translation:audioGuide.detail.deleteModal')}</div>
                                    <Dialog>
                                      <DialogTrigger>
                                        <Button className="w-full" variant="destructive">
                                          {t('translation:audioGuide.detail.delete')}
                                        </Button>
                                      </DialogTrigger>
                                      <DialogContent>
                                        <DialogHeader>
                                          <DialogTitle>{t('translation:audioGuide.detail.deleteHeadline')}</DialogTitle>
                                        </DialogHeader>
                                        <div className="flex gap-3 justify-end">
                                          <DialogClose>
                                            <Button variant="secondary">Abbrechen</Button>
                                          </DialogClose>

                                          <Button
                                            onClick={async () => {
                                              if (!tourID) return;
                                              await fdk.model('guide_tours').deleteEntry(tourID);
                                              navigate(`/${shortID}/audio-guide`);
                                              toast.success(t('translation:audioGuide.detail.tourDeleted'));
                                            }}
                                          >
                                            {t('translation:audioGuide.detail.delete')}
                                          </Button>
                                        </div>
                                      </DialogContent>
                                    </Dialog>
                                  </div>
                                )}
                                {activeSettings === 'langs' && (
                                  <div className="flex flex-col gap-6">
                                    <FormField
                                      control={form.control}
                                      name={'languages'}
                                      render={({ field }) => (
                                        <FormItem>
                                          <FormLabel>{t('audioGuide.detail.tourLanguages')}</FormLabel>
                                          <FormControl>
                                            <>
                                              <div className="flex">
                                                <div className="flex flex-wrap gap-2">
                                                  {field.value.map((e, i) => (
                                                    <Badge key={e.label + i} variant="outline" className="mr-1">
                                                      {e.label}{' '}
                                                      <XMarkIcon
                                                        onClick={(ev: any) => {
                                                          ev.stopPropagation();
                                                          field.onChange(field.value.filter((v) => v !== e));
                                                        }}
                                                        className=" cursor-pointer ml-2 w-4 h-4"
                                                      />
                                                    </Badge>
                                                  ))}
                                                </div>
                                                <Select
                                                  value=""
                                                  onValueChange={(value) => field.onChange([...field.value, value])}
                                                >
                                                  <SelectTrigger className="w-[180px]">
                                                    <SelectValue
                                                      placeholder={t('audioGuide.detail.tourLanguagesAdd')}
                                                    />
                                                  </SelectTrigger>
                                                  <SelectContent>
                                                    <SelectGroup>
                                                      {langs
                                                        .filter(
                                                          (lang) => !field.value.some((v) => v.value === lang.value),
                                                        )
                                                        .map((lang, i) => (
                                                          <SelectItem key={lang.value + i} value={lang as any}>
                                                            {lang.label}
                                                          </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                  </SelectContent>
                                                </Select>
                                              </div>
                                            </>
                                          </FormControl>
                                          <FormMessage />
                                        </FormItem>
                                      )}
                                    />
                                    <FormField
                                      control={form.control}
                                      name={'languages'}
                                      render={({ field }) => (
                                        <FormItem>
                                          <FormLabel>{t('audioGuide.detail.tourDefaultLanguage')}</FormLabel>
                                          <FormControl>
                                            <Select
                                              value={field.value[0] as any}
                                              onValueChange={(value) => {
                                                const newArray = field.value.filter((v) => v !== (value as any));
                                                newArray.unshift(value as any);
                                                field.onChange(newArray);
                                              }}
                                            >
                                              <SelectTrigger className="w-[180px]">
                                                <SelectValue placeholder="Sprache hinzufügen" />
                                              </SelectTrigger>
                                              <SelectContent>
                                                <SelectGroup>
                                                  {field.value.map((lang, i) => (
                                                    <SelectItem key={lang.value + i} value={lang as any}>
                                                      {lang.label}
                                                    </SelectItem>
                                                  ))}
                                                </SelectGroup>
                                              </SelectContent>
                                            </Select>
                                          </FormControl>
                                          <FormDescription>
                                            {t('audioGuide.detail.tourDefaultLanguageDescription')}
                                          </FormDescription>
                                          <FormMessage />
                                        </FormItem>
                                      )}
                                    />
                                  </div>
                                )}
                                {activeSettings === 'cover' && (
                                  <>
                                    <FormField
                                      control={form.control}
                                      name={'cover'}
                                      render={({ field }) => (
                                        <FormItem>
                                          <FormLabel> {t('audioGuide.detail.titleCover')}</FormLabel>
                                          <FormControl>
                                            <>
                                              <DropZone
                                                //@ts-ignore
                                                key={field.value?.assetID}
                                                value={field.value}
                                                onChange={(e) => field.onChange(e)}
                                              />
                                            </>
                                          </FormControl>
                                          <FormMessage />
                                        </FormItem>
                                      )}
                                    />
                                  </>
                                )}
                                {activeSettings === 'desc' && (
                                  <div className="w-full">
                                    <div className=" flex flex-col gap-6">
                                      <Tabs defaultValue={watch('languages')[0]?.value}>
                                        <TabsList>
                                          {watch('languages').map((lang: any, i) => (
                                            <TabsTrigger
                                              key={lang?.value + i}
                                              className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                                              value={lang?.value}
                                            >
                                              <span className="text-sm">{lang?.flag}</span>
                                              {lang.label}{' '}
                                              {i === 0 && (
                                                <div className="text-xs flex gap-2 ">
                                                  {t('audioGuide.detail.tourDefaultLanguage')}
                                                </div>
                                              )}
                                            </TabsTrigger>
                                          ))}
                                        </TabsList>
                                        {watch('languages').map((lang, i) => (
                                          <TabsContent
                                            key={lang.value + i}
                                            value={lang.value}
                                            className="flex flex-col gap-6"
                                          >
                                            <FormField
                                              control={form.control}
                                              name={`title.${lang.value}`}
                                              render={({ field }) => (
                                                <FormItem>
                                                  <FormLabel> {t('audioGuide.detail.title')} </FormLabel>
                                                  <FormControl>
                                                    <Input {...field} />
                                                  </FormControl>
                                                  <FormMessage />
                                                </FormItem>
                                              )}
                                            />
                                            <FormField
                                              control={form.control}
                                              name={`description.${lang.value}`}
                                              render={({ field }) => (
                                                <FormItem>
                                                  <FormLabel> {t('audioGuide.detail.description')} </FormLabel>
                                                  <FormControl>
                                                    <Textarea {...(field as any)} />
                                                  </FormControl>
                                                  <FormMessage />
                                                </FormItem>
                                              )}
                                            />
                                            {i !== 0 && (
                                              <TranslateButton
                                                form={form}
                                                lang={lang.value}
                                                onTranslate={({ title, description }) => {
                                                  if (title) {
                                                    setValue(`title.${lang.value}`, title);
                                                  }
                                                  if (description) {
                                                    setValue(`description.${lang.value}`, description);
                                                  }
                                                }}
                                              />
                                            )}
                                          </TabsContent>
                                        ))}
                                      </Tabs>
                                      <FormField
                                        control={form.control}
                                        name={'duration'}
                                        render={({ field }) => (
                                          <FormItem>
                                            <FormLabel> {t('audioGuide.detail.duration')} </FormLabel>
                                            <FormControl>
                                              <InputWithOverlay {...(field as any)} />
                                            </FormControl>
                                            <FormDescription>
                                              {t('audioGuide.detail.durationDescription')}
                                            </FormDescription>
                                            <FormMessage />
                                          </FormItem>
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </DialogDescription>
                        </div>

                        {['desc', 'cover', 'langs'].includes(activeSettings) && (
                          <div className="flex justify-end gap-3 mt-3 w-full">
                            <DialogClose>
                              <Button
                                onClick={() => {
                                  form.resetField('cover');
                                  form.resetField('title');
                                  form.resetField('description');
                                }}
                                variant="secondary"
                              >
                                {t('translation:audioGuide.cancel')}
                              </Button>
                            </DialogClose>
                            <DialogClose
                              disabled={
                                !!(
                                  form.formState.errors.title ||
                                  form.formState.errors.description ||
                                  form.formState.errors.cover ||
                                  form.formState.errors.languages
                                )
                              }
                            >
                              <Button
                                //@ts-ignore
                                disabled={
                                  form.formState.errors.title ||
                                  form.formState.errors.description ||
                                  form.formState.errors.cover ||
                                  form.formState.errors.languages
                                }
                                onClick={() => {
                                  onSaveTour(form.getValues());
                                }}
                              >
                                {t('translation:audioGuideDetail.save')}
                              </Button>
                            </DialogClose>
                          </div>
                        )}
                      </DialogContent>
                    </Dialog>
                  </div>
                  <div className="flex mt-2 items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all ">
                    <Goal className="w-5 h-5 text-gray-400" /> {t('audioGuide.detail.stations')}
                  </div>
                </nav>
              </div>
              <ul className="mx-8 px-2 text-sm font-medium pt-1 flex flex-col gap-y-2">
                <DndZoneSideMenu
                  setActiveSection={setActiveSection}
                  items={stations.fields}
                  move={stations.move}
                  remove={stations.remove}
                >
                  {(index: number, item: any) => {
                    const locale = watch('languages.0.value');
                    const title = item.title[locale];
                    const isActive = activeSection === item.id;

                    return (
                      <>
                        <div
                          className={classNames(
                            'group w-full h-12 flex items-center gap-3 justify-start rounded-lg p-4',
                            isActive && 'bg-primary/50 text-dark',
                          )}
                        >
                          <div className="w-5 h-5 p-2 ml-1 flex items-center justify-center">
                            <div className="w-4 h-4 flex p-2 text-center align-middle items-center justify-center group-hover:hidden">
                              {form.formState.errors?.stations?.[index] ? (
                                <div>
                                  <ExclamationTriangleIcon className="w-4 h-4 text-destructive" />
                                </div>
                              ) : (
                                <span className={classNames('text-gray-400', isActive && 'text-background ')}>
                                  {index + 1}
                                </span>
                              )}
                            </div>
                            <div className="hidden p-2 group-hover:flex">
                              <Bars2Icon className="w-4 h-4" />
                            </div>
                          </div>

                          <div className="flex flex-col">
                            <small className={classNames(isActive && 'text-background ')}>
                              {title ? title.substring(0, 27) : t('audioGuide.detail.noTitle')}
                            </small>
                          </div>

                          <div className="hidden ml-auto group-hover:flex items-center bg-base hover:text-destructive rounded p-2">
                            <Dialog>
                              <DialogTrigger>
                                <TrashIcon className="w-4 h-4 " />
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>{t('translation:audioGuideDetail.sections.deleteHeadline')}</DialogTitle>
                                </DialogHeader>
                                <div className="flex justify-end gap-3">
                                  <DialogClose asChild>
                                    <Button variant="secondary">
                                      {t('translation:audioGuideDetail.sections.cancel')}
                                    </Button>
                                  </DialogClose>
                                  <Button
                                    onMouseDown={(e) => {
                                      e.preventDefault();
                                      setActiveSection(stations.fields[index - 1]?.id);
                                      fdk.model('guide_tour_stations').deleteEntry(item.id);
                                      stations.remove(index);
                                      return false;
                                    }}
                                  >
                                    {t('translation:audioGuideDetail.sections.delete')}
                                  </Button>
                                </div>
                              </DialogContent>
                            </Dialog>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </DndZoneSideMenu>
                <Button
                  variant="ghost"
                  onClick={() => {
                    const id = `new:${crypto.randomUUID()}`;
                    stations.append({
                      title: {
                        de_DE: '',
                      },
                      id,
                      cover: '',
                      artist: '',
                      description: {
                        de_DE: '',
                      },
                      chapter: null,
                      audio_map: {
                        [langs[0].value]: { type: 'chapter' },
                      },
                      audio_files: [],
                    } as any);
                    setActiveSection(id);
                  }}
                  className={classNames('group w-full h-7 flex justify-start text-left gap-3 justify- rounded-lg p-4')}
                >
                  <div className="w-4 h-4 flex p-2 ml-1 items-center justify-center ">
                    <div>
                      <PlusIcon className="w-4 h-4 text-" />
                    </div>
                  </div>

                  <div>
                    <small className="text-gray-400 group-hover:text-background">
                      {t('audioGuide.detail.addSection')}
                    </small>
                  </div>
                </Button>
              </ul>
            </aside>
          }
        >
          <main className="drawer-content px-6 pb-6">
            <TopNav>
              <div className="flex grow items-baseline space-x-4 pt-1.5">
                <Breadcrumb>
                  <BreadcrumbList>
                    <BreadcrumbItem>
                      <BreadcrumbLink href={`/${shortID}/audio-guide`}>{t('audioGuideList.headline')}</BreadcrumbLink>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbLink>{tour?.title?.[tour.languages.split(',')[0]]}</BreadcrumbLink>
                    </BreadcrumbItem>
                  </BreadcrumbList>
                </Breadcrumb>
              </div>
              {Object.keys(form.formState.touchedFields || {}).length !== 0 && form.formState.isDirty && (
                <div className=" flex items-center p-2 pb-3">
                  <small className="flex items-center h-10 text-gray-500">
                    {t('translation:audioGuideDetail.unsavedChanges')}
                  </small>
                </div>
              )}
              <div className="sticky bottom-0 bg-base flex items-center justify-end p-2 pb-3">
                <Button onClick={form.handleSubmit(onSave)}>
                  {t('audioGuide.save')} {updatePending && <span className="loading loading-ring loading-sm"></span>}
                </Button>
              </div>
            </TopNav>
            {isLoading ? (
              <img src={loaderGif} className="w-40" />
            ) : stations?.fields.length ? (
              <Fragment key={index}>
                <Card>
                  <CardContent className=" flex flex-col gap-3 py-3 pb-7">
                    <FormField
                      control={form.control}
                      name={`stations.${index}.cover`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('audioGuide.detail.section.coverImage')}</FormLabel>
                          <FormControl>
                            <DropZone
                              key={field.value as any}
                              value={field.value}
                              onChange={(e: any) => field.onChange(e)}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={`stations.${index}.artist`}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{t('audioGuide.detail.section.artist')}</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </CardContent>
                </Card>
                <Tabs className="" defaultValue={watch('languages')[0]?.value}>
                  <TabsList className="mt-5 mb-3">
                    {watch('languages').map((lang: any, i) => (
                      <TabsTrigger
                        key={lang.value + i}
                        className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
                        value={lang.value}
                      >
                        <span>{lang?.flag}</span>
                        {lang.label}{' '}
                        {i === 0 && (
                          <div className="text-xs flex gap-2 ">{t('audioGuide.detail.tourDefaultLanguage')}</div>
                        )}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                  {watch('languages').map((lang: any, i) => (
                    <TabsContent value={lang.value} key={lang.value + i} className="space-y-6">
                      <Card>
                        <CardContent className=" flex flex-col gap-3 py-3 pb-7">
                          <FormField
                            control={form.control}
                            name={`stations.${index}.title.${lang.value}`}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>{t('audioGuide.detail.section.title')}</FormLabel>
                                <FormControl>
                                  <Input {...field} />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`stations.${index}.description.${lang.value}` as any}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>{t('audioGuide.detail.section.description')}</FormLabel>
                                <FormControl>
                                  <Textarea {...(field as any)} />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          {i !== 0 && (
                            <TranslateButton
                              form={form}
                              lang={lang.value}
                              station={watch(`stations.${index}`).id}
                              onTranslate={({ title, description }) => {
                                if (title) {
                                  setValue(`stations.${index}.title.${lang.value}` as any, title);
                                }
                                if (description) {
                                  setValue(`stations.${index}.description.${lang.value}` as any, description);
                                }
                              }}
                            />
                          )}

                          <StationUpload
                            index={index}
                            control={control}
                            setValue={setValue}
                            watch={watch}
                            lang={lang}
                          />
                        </CardContent>
                      </Card>
                      {!watch(`stations.${index}`)?.id?.startsWith('new:') && (
                        <Card>
                          <CardContent className=" flex flex-col gap-3 py-3 pb-7">
                            <StationQR language={lang.value} station={watch(`stations.${index}`)} />
                          </CardContent>
                        </Card>
                      )}
                    </TabsContent>
                  ))}
                </Tabs>
              </Fragment>
            ) : (
              <EmptyState
                error={t('audioGuide.detail.section.noSections')}
                description={t('audioGuide.detail.section.noSectionsDescription')}
                title={t('audioGuide.detail.addSection')}
                onClick={() => {
                  const id = `new:${crypto.randomUUID()}`;
                  stations.append({
                    title: {
                      de_DE: '',
                    },
                    id,
                    cover: '',
                    artist: '',
                    description: {
                      de_DE: '',
                    },
                    chapter: null,
                    audio_map: {
                      [langs[0].value]: { type: 'chapter' },
                    },
                    audio_files: [],
                  } as any);
                  setActiveSection(id);
                }}
              />
            )}
          </main>
        </Layout>
      </Form>
    </>
  );
}
const StationUpload = ({ control, index, setValue, watch, lang }) => {
  const { t } = useTranslation('translation');
  const { data: projects } = useFdk({
    model: 'project',
    action: 'entryList',
  });

  const languageFilters = lang.value.substring(0, 2);

  const { data: chapter } = useFdk(
    {
      model: 'chapter',
      action: 'entryList',
      options: {
        // language: languageFilters,
        ['language~']: languageFilters,
      },
    },
    { revalidateOnFocus: true },
  );

  const chapterGrouped = useMemo<any>(() => {
    const grouped = {};
    if (!chapter?.items) return [];
    for (const c of chapter.items) {
      const project = projects?.items.find((p) => p.id === c.project);
      if (!grouped[project?.name]) {
        grouped[project?.name] = {
          group: project?.name,
          options: [{ label: c.name, value: c.id }],
        };
      } else {
        grouped[project?.name].options.push({ label: c.name, value: c.id });
      }
    }
    return Object.values(grouped);
  }, [chapter?.items, projects?.items]);

  if (!chapter) {
    return null;
  }

  const current = `stations.${index}.audio_map.${lang.value}`;
  return (
    <div className="pt-3 bg-base-100 flex flex-col w-full gap-3 rounded-lg">
      <div className="flex flex-col gap-3" key={lang.value}>
        <label className="flex items-center justify-between gap-6 mb-3">
          <span>{t('audioGuide.detail.section.upload.headline')}</span>
        </label>
        <Tabs defaultValue={watch(`${current}.type`) || 'chapter'}>
          <TabsList>
            <TabsTrigger
              onClick={() => {
                setValue(`${current}.type`, 'chapter');
                setValue(`${current}.id`, null);
                setValue(`stations.${index}.chapter`, null);
                setValue(`stations.${index}.audio_file`, null);
              }}
              className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
              value="chapter"
            >
              {t('audioGuide.detail.section.upload.chapter')}
            </TabsTrigger>
            <TabsTrigger
              onClick={() => {
                setValue(`${current}.type`, 'audio');
                setValue(`${current}.id`, null);
                setValue(`stations.${index}.chapter`, null);
                setValue(`stations.${index}.audio_file`, null);
              }}
              className="gap-3 flex rounded-lg data-[state=active]:!bg-secondary data-[state=active]:!text-dark"
              value="audio"
            >
              {t('audioGuide.detail.section.upload.ownAudio')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="audio">
            <Controller
              name={`${current}.id`}
              control={control}
              render={({ field }) => (
                <AudioDrop
                  value={field.value}
                  onChange={(e) => {
                    setValue(`${current}.type`, 'audio');
                    field.onChange(e.assetID);
                  }}
                />
              )}
            />
          </TabsContent>
          <TabsContent value="chapter">
            <div className="flex gap-3 justify-start items-end">
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" className="w-[200px] justify-between">
                    <span className="text-ellipsis overflow-hidden">
                      {watch(`${current}.group`)
                        ? chapterGrouped.find((option) => option.group === watch(`${current}.group`))?.group
                        : t('audioGuide.chapterSearch.placeholder.project')}
                    </span>
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                  <Command
                    filter={(value, search, keywords) => {
                      const current = chapterGrouped.find((item) => item.group === value);
                      if (current?.group.toLowerCase().includes(search.toLowerCase())) {
                        return 1;
                      }
                      return -1;
                    }}
                  >
                    <CommandInput placeholder={t('audioGuide.chapterSearch.placeholder.project')} />

                    <CommandList>
                      <CommandEmpty>{t('audioGuide.chapterSearch.noResults')}</CommandEmpty>
                      {chapterGrouped?.map((item, i) => (
                        <CommandItem
                          key={item.group + i}
                          value={item.group}
                          onSelect={(currentValue) => {
                            setValue(`${current}.type`, 'chapter');
                            setValue(`${current}.group`, currentValue);
                            setValue(`${current}.id`, null);
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              watch(`${current}.group`) === item.group ? 'opacity-100' : 'opacity-0',
                            )}
                          />
                          {item.group}
                        </CommandItem>
                      ))}
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              {watch(`${current}.group`) && (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" className="w-[200px] justify-between">
                      <span className="text-ellipsis overflow-hidden">
                        {watch(`${current}.id`)
                          ? chapterGrouped
                              .map((item) => item.options)
                              .flat()
                              .find((option) => option.value === watch(`${current}.id`))?.label
                          : t('audioGuide.chapterSearch.placeholder.chapter')}
                      </span>
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[200px] p-0">
                    <Command
                      filter={(value, search, keywords) => {
                        const current = chapter.items.find((item) => item.id === value);
                        if (current?.name.toLowerCase().includes(search.toLowerCase())) {
                          return 1;
                        }
                        return -1;
                      }}
                    >
                      <CommandInput placeholder={t('audioGuide.chapterSearch.placeholder.chapter')} />

                      <CommandList>
                        <CommandEmpty>{t('audioGuide.chapterSearch.noResults')}</CommandEmpty>
                        {chapterGrouped
                          ?.filter((item) => {
                            return item.group === watch(`${current}.group`);
                            return true;
                          })
                          .map((item, i) => (
                            <CommandGroup key={item.group + i} heading={item.group}>
                              {item.options?.map((option, i) => (
                                <CommandItem
                                  key={option.value + i}
                                  value={option.value}
                                  onSelect={(currentValue) => {
                                    setValue(`${current}.type`, 'chapter');
                                    setValue(`${current}.id`, currentValue);
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      'mr-2 h-4 w-4',
                                      watch(`${current}.id`) === option.value ? 'opacity-100' : 'opacity-0',
                                    )}
                                  />
                                  {option.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          ))}
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              )}

              <div>
                {watch(`${current}.id`) && (
                  <Button variant="secondary" onClick={() => setValue(`${current}.id`, '')}>
                    <div className="flex items-center gap-2">
                      <XCircleIcon className=" inline-block w-6 h-6" />
                      {t('audioGuide.detail.section.upload.empty')}
                    </div>
                  </Button>
                )}
              </div>
            </div>
            <small className="mt-2 block">
              {t('translation:audioGuideDetail.stationUpload.notice', { lang: lang.label })}
            </small>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

const StationQR = ({ language, station }: { language: string; station: any }) => {
  const { t } = useTranslation('translation');
  const { shortID, tourID } = useParams();
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    //@ts-ignore
    QRCode.toDataURL(
      `https://${
        import.meta.env.VITE_ENV === 'stage' ? 'lizzen-audio-guide.cachena.entrecode.de' : 'audioguide.lizzen.de'
      }/${shortID}/${language}/${tourID}/station/${station.id}`,
      {
        errorCorrectionLevel: 'H',
        type: 'image/jpeg',
        quality: 0.3,
        width: 200,
        margin: 1,
        color: {
          dark: '#00bf9f',
          light: '#042232',
        },
      },
      (err, url) => setQrCode(url),
    );
  }, [language, tourID, shortID]);
  return (
    <>
      <span>QR-Code</span>
      <div className="mb-3 w-full flex gap-6 col-span-2">
        <div className="flex flex-col gap-2">
          {qrCode && <img src={qrCode} alt="QR Code" />}
          <Button
            variant="secondary"
            onClick={() => {
              //@ts-ignore
              QRCode.toDataURL(
                `https://${
                  import.meta.env.VITE_ENV === 'stage'
                    ? 'lizzen-audio-guide.cachena.entrecode.de'
                    : 'audioguide.lizzen.de'
                }/${shortID}/${language}/${tourID}/station/${station.id}`,
                {
                  errorCorrectionLevel: 'H',
                  type: 'image/jpeg',
                  quality: 0.3,
                  width: 200,
                  margin: 1,
                },
                (err, url) => {
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = 'qr-code.jpg';
                  a.click();
                },
              );
            }}
          >
            <DownloadIcon className="mr-2" />
            QR-Code speichern
          </Button>
        </div>

        <div className="w-full">
          <div>{t('audioGuide.detail.tourShareHeadline')}</div>
          <div className="flex gap-4">
            <Input
              readOnly
              value={`https://${
                import.meta.env.VITE_ENV === 'stage'
                  ? 'lizzen-audio-guide.cachena.entrecode.de'
                  : 'audioguide.lizzen.de'
              }/${shortID}/${language}/${tourID}/station/${station.id}`}
            />
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://${
                    import.meta.env.VITE_ENV === 'stage'
                      ? 'lizzen-audio-guide.cachena.entrecode.de'
                      : 'audioguide.lizzen.de'
                  }/${shortID}/${language}/${tourID}/station/${station.id}`,
                );
                toast.success('Link kopiert');
              }}
            >
              <Clipboard className="w-4 h-4 " />
            </Button>
          </div>

          <a
            href={`https://${
              import.meta.env.VITE_ENV === 'stage' ? 'lizzen-audio-guide.cachena.entrecode.de' : 'audioguide.lizzen.de'
            }/${shortID}/${language}/${tourID}/station/${station.id}`}
            target="_blank"
            className="block pt-3"
          >
            <Button variant="link">{t('audioGuide.detail.tourShareButton')}</Button>
          </a>
        </div>
      </div>
    </>
  );
};

function TranslateButton({
  form,
  lang,
  station,
  onTranslate,
}: {
  form: any;
  lang: string;
  station?: string;
  onTranslate: (content: any) => void;
}) {
  const { shortID, tourID }: any = useParams();
  const { i18n } = useTranslation('translation');
  const [pending, setPending] = useState(false);
  const currentLang = i18n.language;

  const { watch } = form;

  const defaultLocale = useMemo(() => {
    const [{ value }] = watch('languages');
    return languages[currentLang].find(({ language }) => language === value)?.name ?? 'Deutsch';
  }, [currentLang, watch('languages')]);

  const translate = useCallback(async () => {
    try {
      setPending(true);
      const url = new URL(
        station
          ? `/${shortID}/translate/audio-guide/station/${station}`
          : `/${shortID}/translate/audio-guide/${tourID}`,
        import.meta.env.VITE_API_URL,
      );

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          translateTo: lang,
        }),
      });
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      const { title, description } = await res.json();

      onTranslate({
        title,
        description,
      });
    } catch (err) {
      throw err;
    } finally {
      setPending(false);
    }
  }, [lang]);

  return (
    <Button
      variant="outline"
      onClick={() =>
        toast.promise(translate(), {
          loading: 'Übersetze...',
          success: 'Erfolgreich übersetzt',
          error: 'Fehler beim Übersetzen',
        })
      }
      disabled={pending}
    >
      automatisch aus {defaultLocale} übersetzen
    </Button>
  );
}

export type Tour = {
  id: string;
  created: string;
  modified: string;
  title: Record<string, string>;
  description: Record<string, string>;
  cover: string;
  duration: number;
  publish_date: string;
  tags: Array<string>;
  stations: Array<{
    id: string;
    created: string;
    modified: string;
    private: boolean;
    title: Record<string, string>;
    cover: string;
    artist: string;
    description: Record<string, string>;
    chapter: any;
    audio_file: any;
  }>;
  languages: Array<{
    label: string;
    value: string;
    flag: string;
  }>;
};
