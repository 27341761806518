import { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useFdk } from '../../fdk';
import { getFlagEmoji, languages } from '../../utils';
import { Modal, hideModal } from '../Modal';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';
import { useForm } from 'react-hook-form';
import { FormField, FormItem, FormLabel, FormControl, Form } from '../ui/form';
import { SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem, Select } from '../ui/select';

export default function SectionTranslateModal({ id, sectionIndex }: { id: string; sectionIndex: number }) {
  const { t, i18n } = useTranslation('translation');
  const { shortID, chapterID } = useParams<{ shortID: string; chapterID: string; projectID: string }>();
  const [pending, setPending] = useState(false);

  const { mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapterID,
  });

  const form = useForm({
    defaultValues: {
      language: languages[i18n.language].find((e) => e.language === 'de_DE').language,
    },
  });

  return (
    <Modal headline={t('chapter.sectionTranslateModal.headline')} id={id as string}>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (values: any) => {
            try {
              setPending(true);
              const { language } = values;

              const res = await fetch(
                `${import.meta.env.VITE_API_URL}/${shortID}/translate/chapter/${chapterID}/section/${sectionIndex}`,
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    translateTo: language,
                    createNew: values.new === 'true',
                  }),
                },
              );
              if (!res.ok) {
                toast.error(t('chapter.sectionTranslateModal.error'));
                setPending(false);
                return;
              }
              const data = await res.json();
              if (!data.chapterId) {
                toast.error(t('chapter.sectionTranslateModal.error'));
                setPending(false);
                hideModal('sectionTranslateModal');
                return;
              } else {
                toast.success(t('chapter.sectionTranslateModal.success'));
                hideModal('sectionTranslateModal');
                setPending(false);
                await mutate();
              }
            } catch (error) {
              console.log('error', error);
              hideModal('sectionTranslateModal');
              setPending(false);
              toast.error(t('chapter.sectionTranslateModal.error'));
            }
          })}
        >
          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('chapter.sectionTranslateModal.description')}</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder={t('chapter.sectionTranslateModal.pleaseChoose')} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {languages[i18n.language].map((language) => (
                          <SelectItem value={language.language} key={language.language}>
                            <div className="flex gap-x-2">
                              <span className="block truncate">
                                {getFlagEmoji(language?.countryCode ?? language?.language)}
                              </span>
                              <span className="block truncate">{language?.name}</span>
                            </div>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="flex items-end py-4 gap-x-2">
            <Button
              variant="secondary"
              className="btn grow"
              onClick={(e) => {
                e.preventDefault();
                hideModal('sectionTranslateModal');
              }}
            >
              {t('chapter.sectionTranslateModal.cancel')}
            </Button>
            <Button className="btn btn-primary grow" type="submit" disabled={pending}>
              {t('chapter.sectionTranslateModal.translate')}{' '}
              {pending && <span className="loading loading-infinity loading-xs" />}
            </Button>
          </div>
        </form>
      </Form>
    </Modal>
  );
}
