import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { Button } from './ui/button';

export default function StripeBilling({
  primary,
  className,
  text = 'Rechnungsstellung',
  hideIcon = false,
}: {
  primary?: boolean;
  className?: string;
  text?: string;
  hideIcon?: boolean;
}) {
  const { shortID } = useParams();
  return (
    <Button variant={'secondary'} className={className}>
      <div
        className={`cursor-pointer h-full flex p-2 gap-2 items-center ${primary && 'text-dark'}`}
        onClick={async () => {
          const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/billing-portal`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              return_url: window.location.href,
            }),
          });
          if (!res.ok) {
            toast.error('Fehler bei der Weiterleitung zum Zahlungsportal');
            console.error('Error creating billing portal session', res);
            return;
          }
          const { url } = await res.json();
          window.location.href = url;
        }}
      >
        {!hideIcon && <CreditCardIcon className="w-5 h-5" />}
        {text}
      </div>
    </Button>
  );
}
