import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { Layout } from '../Layout';
import { googleTagManagerEvent } from '../utils';
import AILoader from './AILoader';

export default function CreateStudio() {
  const { t } = useTranslation('translation');
  const [searchParams] = useSearchParams();

  const { checkoutSessionID } = useParams();
  const navigate = useNavigate();

  const { error, isLoading } = useSWR(
    'create',
    async () => {
      try {
        const title = searchParams.get('studioname');
        if (!title) {
          toast.error(t('createStudio.noStudioName'));
        }
        const url = new URL(`${import.meta.env.VITE_EC_TASK_URL}/lizzen.setup-studio`);
        const res = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title,
            checkoutSessionID,
          }),
        });

        if (!res.ok) {
          console.error('res', res);
          throw new Error('Studio konnte nicht erstellt werden!');
        }

        const {
          _links: {
            monitor: { href },
          },
        } = await res.json();

        url.pathname = href;

        const shortID = await new Promise((resolve, reject) => {
          const interval = setInterval(async () => {
            try {
              const res = await fetch(url.toString());
              const events = await res.json();
              const { isSuccess, isError, shortID } = events[events.length - 1];
              if (isError) {
                throw new Error('Studio konnte nicht erstellt werden!');
              }
              if (isSuccess) {
                clearInterval(interval);
                resolve(shortID);
              }
            } catch (err) {
              clearInterval(interval);
              reject(err);
            }
          }, 5000);
        });
        toast.success('Studio wurde erstellt!');
        googleTagManagerEvent('purchase', { aboType: checkoutSessionID ? 'paid' : 'free' });
        navigate(`/${shortID}/projects/create`, { replace: true });
      } catch (error) {
        console.error('error', error);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    },
  );

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  if (isLoading) {
    return (
      <main className="drawer-content flex items-center justify-center min-h-screen w-screen">
        <AILoader type="studio" />
      </main>
    );
  }

  return (
    <Layout>
      <div className="col-span-12 mx-auto w-full rounded-3xl lg:mx-0 lg:flex justify-center p-8">
        {t('createStudio.error')}
      </div>
    </Layout>
  );
}
