import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { useOidc } from '@axa-fr/react-oidc';
import { Cog6ToothIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';
import { fetcher } from 'ec.fdk';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Layout } from '../Layout';
import { PageLoader } from '../components/PageLoader';
import StripeBilling from '../components/StripeBillingLink';
import { TopNav } from '../components/TopNav';
dayjs.extend(relativeTime);
dayjs.locale('de');

export default function Settings() {
  const { t } = useTranslation('translation');
  const oidc = useOidc();
  const { shortID } = useParams();
  const {
    data: abo,
    isLoading,
    error,
  } = useSWR(`${import.meta.env.VITE_API_URL}/${shortID}/abo`, fetcher, {
    shouldRetryOnError: false,
    revalidateOnFocus: false,
  });

  if (isLoading) return <PageLoader />;

  return (
    <Layout>
      <TopNav>
        <div className="flex grow items-center space-x-4">
          <div className="mr-auto">
            <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
              {t('settings.headline')}
            </h1>
          </div>
          <div className="flex gap-2">
            <Button variant="secondary">
              <a className="cursor-pointer flex p-2 gap-2 items-center" href={`/${shortID}/settings/abo-manage`}>
                <Squares2X2Icon className="w-5 h-5" />
                {t('settings.manageAbo')}
              </a>
            </Button>
            <StripeBilling />
            <Button variant="secondary">
              <a
                className="cursor-pointer flex p-2 gap-2 items-center"
                onClick={async () => oidc.login('/', { prompt: 'profile' })}
              >
                <Cog6ToothIcon className="w-5 h-5" />
                {t('settings.mailAndSecurity')}
              </a>
            </Button>
          </div>
        </div>
      </TopNav>
      {!error ? (
        <Card className="border col-span-12 mt-6 overflow-hidden  xl:col-span-10">
          <div className="overflow-x-auto  ">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.bookedAbo')}</TableCell>
                  <TableCell className="text-sm">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={async () => {
                        try {
                          const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/billing-portal`, {
                            method: 'POST',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              return_url: window.location.href,
                            }),
                          });
                          if (!res.ok) {
                            toast.error(t('settings.redirectError'));
                            console.error('Error creating billing portal session', res);
                            return;
                          }
                          const { url } = await res.json();
                          window.location.href = url;
                        } catch (error) {
                          console.error('error', error);
                          toast.error(t('settings.redirectError'));
                        }
                      }}
                    >
                      {abo?.product ?? t('settings.unknownSubscription')}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">Status</TableCell>
                  <TableCell className="text-sm">
                    {abo?.subscription.status === 'trialing' && (
                      <div className="badge badge-primary badge-outline">{t('settings.testMode')}</div>
                    )}
                    {abo?.subscription.status === 'active' && (
                      <div className="badge badge-primary badge-outline">{t('settings.active')}</div>
                    )}
                    {abo?.subscription.status === 'canceled' && (
                      <div className="badge badge-error badge-outline">{t('settings.canceled')}</div>
                    )}
                    {(abo?.subscription.status === 'incomplete' ||
                      abo?.subscription.status === 'incomplete_expired' ||
                      abo?.subscription.status === 'past_due' ||
                      abo?.subscription.status === 'unpaid') && (
                      <div className="badge badge-error badge-outline">{t('settings.paymentPending')}</div>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.usedVolume')}</TableCell>
                  <TableCell className="text-sm">
                    {dayjs.duration({ seconds: abo?.usage.total_usage }).asMinutes().toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.newVolume')}</TableCell>
                  <TableCell className="text-sm">{dayjs.unix(abo?.subscription.period_end).fromNow()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.nextPayment')}</TableCell>
                  <TableCell className="text-sm">
                    {dayjs.unix(abo?.next_invoice.next_payment_attempt).fromNow()}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="text-sm">{t('settings.invoiceAmount')}</TableCell>
                  <TableCell className="text-sm">{abo?.next_invoice.amount_due / 100} €</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Card>
      ) : (
        <Card className="border col-span-12 mt-6 overflow-hidden  xl:col-span-10">
          <div className="flex justify-center items-center p-8">{t('settings.noSubscriptionFound')}</div>
        </Card>
      )}
    </Layout>
  );
}
