import { useEffect, useRef, useState } from 'react';

// https://github.com/vercel/swr/issues/110#issuecomment-552637429
export default function useDebounce(value, delay, onChange?) {
  // make sure onChange is wrapped in useCallback!
  const [debouncedValue, setDebouncedValue] = useState(value);
  let prev = useRef();

  useEffect(() => {
    let handler;
    if (prev.current !== value) {
      prev.current = value;
      handler = setTimeout(() => {
        setDebouncedValue(value);
        onChange?.(value);
      }, delay);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, onChange]);

  return debouncedValue;
}
