import ChapterErrorModal from '@/components/Editor/ChapterErrorModal';
import DndItem from '@/components/Editor/DndItem';
import SpeechToTextModal from '@/components/Editor/SpeechToTextModal';
import { DndZoneSideMenu } from '@/components/FormItems';
import { Alert } from '@/components/ui/alert';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Sheet, SheetContent } from '@/components/ui/sheet';
import { Skeleton } from '@/components/ui/skeleton';
import { publish } from '@/lib/events';
import { getUsedAudios, getUsedLocalVoices } from '@/lib/utils';
import {
  Bars2Icon,
  ChatBubbleBottomCenterTextIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  SpeakerWaveIcon,
  VariableIcon,
} from '@heroicons/react/20/solid';
import { DocumentDuplicateIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { LanguageIcon, ListBulletIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { startTour } from '@intercom/messenger-js-sdk';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { DialogClose } from '@radix-ui/react-dialog';
import { t } from 'i18next';
import { produce } from 'immer';
import { createContext, lazy, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutEditor } from '../Layout';
import ChangeLanguageModal from '../components/Editor/ChangeLanguageModal';
import { ChapterCopyModal } from '../components/Editor/ChapterCopyModal';
import { ChapterEditModal } from '../components/Editor/ChapterEditModal';
import ChapterGenerateModal from '../components/Editor/ChapterGenerateModal';
import ChapterGeneratingModal from '../components/Editor/ChapterGeneratingModal';
import { ChapterSplitPasteTextModal } from '../components/Editor/ChapterSplitPasteTextModal';
import { ChapterTranslateModal } from '../components/Editor/ChapterTranslateModal';
import GeneratingFailedModal from '../components/Editor/GeneratingFailedModal';
import { AddSection, AddSectionSidebar, Section } from '../components/Editor/Section';
import SectionTranslateModal from '../components/Editor/SectionTranslateModal';
import Loader from '../components/Loader';
import { showModal } from '../components/Modal';
import { SideNavHistory } from '../components/SideNav';
import { TopNav } from '../components/TopNav';
import VoiceSelect from '../components/VoiceSelect';
import { useFdk, usePlainFdk } from '../fdk';
import useDebounce from '../hooks/useDebounce';
import { useStudioApi } from '../useStudioAPI';
import { classNames, getFlagEmoji } from '../utils';

const Waveform = lazy(() => import('@/components/Editor/Waveform'));
const VariableEditorModal = lazy(() => import('@/components/Editor/VariableEditorModal'));

export type SectionType = {
  _prev?: string;
  modified?: number;
  title: string;
  type: 'text' | 'file' | 'speech';
  pause: number;
  content: string;
  voiceID?: string;
  timestamp?: number;
  generating?: boolean;
  jobID?: string;
};
export type Content = {
  voiceID?: string;
  sections: SectionType[];
};

type ValidationError = {
  type: string;
  index: number;
};

declare interface ChapterEntry {
  content: Content;
  state: string;
  modified: string;
}
const css = `
    #crisp-chatbox .cc-nsge {
      bottom: 90px !important;
    }
    `;

export const TrackContext = createContext<any>(null);

export default function ChapterDetail() {
  const { projectID, chapterID } = useParams();

  const { data: project } = useFdk({
    model: 'project',
    action: 'getEntry',
    entryID: projectID,
  });

  const { data: chapter, mutate: mutateChapter } = useFdk(
    {
      model: 'chapter',
      action: 'getEntry',
      entryID: chapterID,
    },
    {
      revalidateOnFocus: false,
    },
  );

  const loadPreview = useMemo(() => chapter && !chapter?.content?.sections?.length, [chapter]);

  const { data: preview } = useStudioApi(loadPreview && `generate/${projectID}`);

  if (!chapter || !project || (loadPreview && !preview)) {
    return <SkeletonLayout />;
  }
  return (
    <>
      <style>{css}</style>
      <Form chapter={chapter} mutateChapter={mutateChapter} project={project} preview={preview} />
    </>
  );
}

const Form = ({ chapter, mutateChapter, project, preview }) => {
  const { shortID } = useParams();
  const fdk = usePlainFdk();
  const saveAttempts = useRef(0);

  const { data: parent } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapter.parent,
  });

  const decoratedChapter = useMemo(
    () =>
      produce(chapter as ChapterEntry, (draft) => {
        draft.content.sections.forEach((section) => {
          section._prev = section.content;
        });
      }),
    [chapter],
  );
  const methods = useForm({
    defaultValues: {
      chapter: decoratedChapter,
      lastPasted: {
        paragraphs: null,
        newContent: null,
        oldContent: null,
      },
    },
  });
  const { watch, setValue } = methods;
  const { projectID, chapterID } = useParams();
  const { mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapterID,
  });

  const editEntry = useCallback(
    async (payload: any) => {
      try {
        const res = await fdk.model('chapter').editEntrySafe(chapterID as string, {
          ...payload,
          _modified: watch('chapter.modified'),
        });
        setValue('chapter.modified', res.modified as any);
        saveAttempts.current = 0;
      } catch (error: any) {
        if (error.message.includes('Somebody else changed')) {
          console.log('Somebody else changed the ressource');
          await mutate();
          if (saveAttempts.current < 5) {
            saveAttempts.current++;
            await editEntry(payload);
          }
        }
      }
    },
    [fdk, watch],
  );

  const save = useCallback(async () => {
    editEntry({
      state: 'draft',
      content: {
        ...watch('chapter.content'),
        sections: watch('chapter.content.sections'),
      },
    });
  }, [watch, fdk]);

  useDebounce(JSON.stringify(watch(`chapter.content`)), 1000, (e) => {
    save();
  });

  const [deleteSection, setDeleteSection] = useState<any>(null);
  const {
    fields,
    remove: removeSection,
    move,
    append,
  } = useFieldArray({
    keyName: 'id',
    control: methods.control,
    name: 'chapter.content.sections',
  });

  const remove = (index) => {
    setDeleteSection(index);
  };

  const [sm, setSm] = useState(false);
  const [currentSection, setCurrentSection] = useState(fields?.[0] ? fields?.[0] : null) as any;
  const [generatingError, setGeneratingError] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [sectionIsGenerating, setSectionIsGenerating] = useState(false);
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState([] as ValidationError[]);
  const [summary, setSummary] = useState<null | string>(null);
  const [length, setLength] = useState<null | number>(null);

  const currentSectionIndex = useMemo(() => {
    const index = fields.findIndex((item) => item.id === currentSection?.id);
    document.getElementById(`section-${index}-textarea`)?.focus();
    return index;
  }, [currentSection, fields]);

  const type = useMemo(
    () => ({ podcast: 'Episode', undefined: 'Inhalt' }[project?.content?.type]),
    [project?.content?.type],
  );

  const variables = useMemo(
    () =>
      Array.from(
        new Set(
          fields
            .map(
              ({ content }) =>
                content?.match?.(/{{(.*?)}}/g)?.map((variable) => variable.replace(/{{|}}/g, '').trim()) ?? [],
            )
            .flat(),
        ),
      ),
    [fields],
  );

  useEffect(() => {
    if (decoratedChapter) {
      methods.reset({ chapter: decoratedChapter });
    }
  }, [decoratedChapter]);

  const generate = useCallback(
    () =>
      fetchEventSource(`${import.meta.env.VITE_QUEUE_URL}/${shortID}/aiJob`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          projectID: project.id,
          chapterID: chapter.id,
          summary,
          length,
        }),
        async onmessage({ data, event }) {
          if (event === 'started') {
            setPending(true);
            toast.loading('Generiere Inhalt...', { id: shortID, className: 'text-xs' });
          }
          if (event === 'progress') {
            const { percentage, message } = JSON.parse(data);
            toast.loading(`${percentage}% [${message}]`, { id: shortID, className: 'text-xs' });
          }
          if (event === 'completed') {
            toast.success('Episode erfolgreich erstellt');
            await mutateChapter();
          }
        },
        async onclose() {
          toast.dismiss(shortID);
          setPending(false);
        },
        onerror() {
          toast.error('Fehler beim Erstellen der Episode');
          throw new Error('no_retry');
        },
        openWhenHidden: true,
      }).catch(() => null),
    [shortID, chapter, project, summary, length],
  );

  return (
    <TrackContext.Provider
      value={{
        editEntry,
        currentSection,
        setCurrentSection,
        generatingError,
        setGeneratingError,
        setShowGenerate,
        setSectionIsGenerating,
        sectionIsGenerating,
        currentSectionIndex,
        setErrors,
        errors,
      }}
    >
      <FormProvider {...methods}>
        <LayoutEditor
          aside={
            <div className="w-[320px] sticky top-3.5">
              <nav className="grid sticky top-0 bg-muted items-start px-4 text-sm font-medium">
                <div className="flex sticky top-0 items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all lg:px-4 pt-3 md:pt-6">
                  <ListBulletIcon className="w-5 h-5 text-gray-400" /> {t('chapter.sections.sections')}
                </div>
              </nav>
              <div className="mx-1 px-2 text-sm font-medium pb-3 pt-1 flex flex-col gap-y-2">
                <DndZoneSideMenu
                  setActiveSection={(e) => {
                    const section = fields.find((item) => item.id === e);
                    setCurrentSection(section);
                  }}
                  items={fields}
                  move={move}
                  remove={remove}
                  onDragStart={(id) => {
                    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                  }}
                >
                  {(index: number) => (
                    <DndItem
                      index={index}
                      remove={async () => {
                        remove(index);
                        const usedAudios = getUsedAudios(watch('chapter.content'));
                        await editEntry({
                          state: 'draft',
                          content: {
                            voiceID: watch('chapter.content.voiceID'),
                            sections: watch('chapter.content.sections'),
                          },
                          audio_resources: usedAudios,
                        });
                      }}
                    />
                  )}
                </DndZoneSideMenu>
                <AddSectionSidebar append={append} />
              </div>
            </div>
          }
        >
          <main className="drawer-content px-6 pb-6 min-h-[calc(100vh_-_theme(spacing.16))]">
            <Dialog open={deleteSection !== null} onOpenChange={() => setDeleteSection(null)}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>{t('chapter.removeSection.headline')}</DialogTitle>
                </DialogHeader>
                <div className="flex gap-3 justify-end">
                  <DialogClose asChild>
                    <Button variant={'secondary'}>{t('chapter.removeSection.cancel')}</Button>
                  </DialogClose>
                  <DialogClose
                    asChild
                    onClick={async () => {
                      removeSection(deleteSection as any);
                      await editEntry({
                        state: 'draft',
                        content: watch('chapter.content'),
                      });
                      setDeleteSection(null);
                    }}
                  >
                    <Button variant={'destructive'}>{t('chapter.removeSection.delete')}</Button>
                  </DialogClose>
                </div>
              </DialogContent>
            </Dialog>
            <ChapterSplitPasteTextModal id="ChapterSplitPasteTextModal" />
            <ChapterGenerateModal
              setShowGenerate={setShowGenerate}
              showGenerate={showGenerate}
              chapter={chapter}
              mutate={mutateChapter}
            />
            <VariableEditorModal onVariables={(v) => setShowGenerate(v)} project={project} chapter={chapter} />
            {chapter.state === 'generating' && <ChapterGeneratingModal mutate={mutateChapter} />}
            {chapter.state === 'errored' && <ChapterErrorModal mutate={mutateChapter} />}
            <GeneratingFailedModal id="generatingFailedModal" />
            <ChapterCopyModal id="copyModal" chapter={chapter} />
            <ChangeLanguageModal id="changeLanguageModal" chapter={chapter} />
            <ChapterTranslateModal id="chapterTranslateModal" />
            {shortID && <SpeechToTextModal id="speechToTextModal" shortID={shortID} mutate={mutate} />}
            <SectionTranslateModal id="sectionTranslateModal" sectionIndex={currentSectionIndex} />
            <ChapterEditModal id="editModal" chapter={chapter} mutate={mutate} />

            <div className="grid sticky top-0 grid-cols-12 grid-rows-[min-content] gap-y-12 py-6 lg:gap-x-12 bg-background z-10">
              <TopNav>
                <div className="flex grow items-center justify-between space-x-4 pt-1.5">
                  <div className="flex flex-col">
                    <Breadcrumb>
                      <BreadcrumbList>
                        <BreadcrumbItem>
                          <BreadcrumbLink href="./..">{t('chapters.headline')}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                          <NameInlineEdit />
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                          <DropdownMenu>
                            <DropdownMenuTrigger>
                              <div className="w-8 rounded-full flex justify-center items-center">
                                <EllipsisVerticalIcon className="h-6 w-6" />
                              </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem onClick={() => showModal('changeLanguageModal')}>
                                <LanguageIcon className="w-5 h-5 mr-2" />
                                <span>{t('chapter.changeLanguage')}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem onClick={() => startTour('537224')}>
                                <VariableIcon className="w-5 h-5 mr-2" />
                                <span>{t('chapter.variables')}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem onClick={() => showModal('speechToTextModal')}>
                                <ChatBubbleBottomCenterTextIcon className="w-5 h-5 mr-2" />
                                <span>{t('chapter.addSection.stt')}</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem onClick={() => showModal('copyModal')}>
                                <DocumentDuplicateIcon className="w-5 h-5 mr-2" />
                                <span>
                                  {t('chapters.headline')} {t('chapter.duplicate').toLowerCase()}
                                </span>
                              </DropdownMenuItem>
                              {chapter?.historyAudio?.length > 0 && (
                                <DropdownMenuItem onClick={() => setShowHistory(!showHistory)}>
                                  <ListBulletIcon className="w-5 h-5 mr-2" />
                                  {t('chapter.history.history')}
                                </DropdownMenuItem>
                              )}
                              <div className="block md:hidden">
                                {/* {!parent && (
                            <DropdownMenuItem className="" onClick={() => showModal('chapterTranslateModal')}>
                              <LanguageIcon className="w-5 h-5 mr-2" />
                              <span>Übersetzen</span>
                            </DropdownMenuItem>
                          )} */}
                                <DropdownMenuItem onClick={() => setSm(true)}>
                                  <SpeakerWaveIcon className="w-5 h-5 mr-2" />
                                  <span>Stimme ändern</span>
                                </DropdownMenuItem>
                                <DropdownMenuItem onClick={() => showModal('editModal')}>
                                  <PencilSquareIcon className="w-5 h-5 mr-2" />
                                  <span>Umbenennen</span>
                                </DropdownMenuItem>
                              </div>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </BreadcrumbItem>
                      </BreadcrumbList>
                    </Breadcrumb>
                    {parent && (
                      <div className="flex text-sm">
                        <a href={`/${shortID}/projects/${projectID}/chapters/${parent.id}`} className="underline">
                          Übersetzung von: {parent.name}
                        </a>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-end gap-4">
                    <Controller
                      name={'chapter.content.voiceID'}
                      control={methods.control}
                      rules={{
                        required: t('global.required'),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <VoiceSelect
                          showModal={sm}
                          onChange={async (e) => {
                            onChange(e.id);
                            const usedVoices = getUsedLocalVoices(methods.watch('chapter.content'));
                            await editEntry({
                              state: 'draft',
                              content: {
                                ...methods.getValues('chapter.content'),
                                voiceID: e.id,
                              },
                              voice_preset: usedVoices,
                            });
                          }}
                          value={value}
                        />
                      )}
                    />
                    {!parent && (
                      <div className="hidden sm:flex">
                        <VariantSelect />
                      </div>
                    )}

                    {chapter?.content?.sections?.length > 0 &&
                      (!(chapter.currentAudio?.length && methods.watch('chapter.state') === 'generated') ||
                        variables.length > 0) && (
                        <Button
                          className="w-full sm:w-auto flex justify-center self-center my-1"
                          disabled={
                            methods.watch('chapter.state') === 'generating' ||
                            !methods.watch('chapter.content.sections')?.length
                          }
                          onClick={() => {
                            const errors = methods
                              .getValues('chapter.content.sections')
                              .reduce((acc, section, index) => {
                                if (!section.content?.trim()?.length) {
                                  acc.push({ type: section.type, index });
                                }
                                return acc;
                              }, [] as ValidationError[]);
                            console.log('errors', errors);

                            const globalVoiceError = [undefined, null, ''].includes(
                              methods.getValues('chapter.content.voiceID'),
                            );

                            if (errors.length === 0 && !globalVoiceError) {
                              publish('variableEditor', true);
                              //setShowGenerate(true);
                            } else {
                              if (globalVoiceError) {
                                toast.error('Es ist keine globale Stimme hinterlegt. Bitte wähle eine Stimme aus.');
                              }
                              if (errors.length > 0) {
                                setErrors(errors);
                                console.log('errors', errors);

                                if (errors.length === 1 && errors[0].type === 'speech') {
                                  toast.error('Die Sektion ist leer. Bitte speichere deine Eingabe.');
                                } else {
                                  toast.error('Es gibt leere Sektionen. Bitte gib einen Inhalt ein.');
                                }
                                const [firstError] = errors;
                                const element = document.querySelectorAll('.section')[firstError.index];
                                element.scrollIntoView({ behavior: 'smooth' });
                              }
                            }
                          }}
                        >
                          <div className="flex items-center lg:gap-x-2 lg:p-2">
                            {methods.watch('chapter.state') === 'generating' ? (
                              <Loader size={5} />
                            ) : (
                              <SparklesIcon className="w-5 h-5 text-base" />
                            )}
                            Generieren
                          </div>
                        </Button>
                      )}
                  </div>
                </div>
              </TopNav>
            </div>
            <div className="flex flex-col items-center grow px-4 sm:px-0">
              {fields.map((section: any, index: number) => (
                <Section htmlId={section.id} key={section.id} index={index} remove={remove} fields={fields} />
              ))}
              {preview?.items?.length && !chapter?.content?.sections?.length ? (
                <div className="mx-12">
                  <Alert className="flex items-center gap-x-4">
                    <div>
                      <InformationCircleIcon className="w-6 h-6 block" />
                    </div>
                    <div
                      className="outline-none max-w-screen-md border border-border rounded-md  !transform-none p-2 min-w-20"
                      contentEditable
                      suppressContentEditableWarning={true}
                      onInput={({ target: { innerText } }: any) => {
                        if (preview?.items[chapter?.content?.openAI?.index - 1]?.content !== innerText) {
                          setSummary(innerText);
                        } else {
                          setSummary(null);
                        }
                      }}
                    >
                      {preview?.items[chapter?.content?.openAI?.index - 1]?.content}
                    </div>
                    <Select onValueChange={(value) => setLength(Number(value))} defaultValue="3750">
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Text" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {Array.from(Array(20).keys())
                            .map((k) => (k + 1) * 100)
                            .map((length) => (
                              <SelectItem value={(length * 7.5).toString()} key={length}>
                                {length} Wörter (ca. {length / 100} Minute{length / 100 > 1 ? 'n' : ''})
                              </SelectItem>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                    <Button onClick={generate} disabled={pending}>
                      <div className="flex items-center gap-x-2 p-2 px-6">
                        {pending ? <Loader size={5} /> : <SparklesIcon className="w-5 h-5 text-base" />}
                        <span className="text-base">
                          {type} {t('chapter.createEpisode.create')}
                        </span>
                      </div>
                    </Button>
                  </Alert>
                </div>
              ) : (
                <AddSection append={append} />
              )}
            </div>
          </main>
          <Sheet open={showHistory} onOpenChange={setShowHistory}>
            <SheetContent>
              <SideNavHistory />
            </SheetContent>
          </Sheet>
          {chapter.state === 'generated' && (
            <Waveform chapter={chapter} project={project} currentAudio={chapter.currentAudio} variables={variables} />
          )}
        </LayoutEditor>
      </FormProvider>
    </TrackContext.Provider>
  );
};

const NameInlineEdit = () => {
  const [editing, setEditing] = useState(false);
  const { setValue, watch } = useFormContext();
  const { editEntry } = useContext(TrackContext);

  const beforeEdit = useRef();
  const inputRef = useRef(null);

  if (!editing) {
    return (
      <span
        onClick={() => {
          beforeEdit.current = watch('chapter.name');
          setEditing(true);
        }}
        className="chapter-name-input px-1 max-w-64 truncate col-span-5 border-2 border-background hover:border-border rounded-md sm:text-xl text-xl"
      >
        {watch('chapter.name')}
      </span>
    );
  }
  return (
    <input
      ref={inputRef}
      autoFocus
      onChange={(e) => {
        setValue('chapter.name', e.target.value);
      }}
      onBlur={async (e) => {
        if (watch('chapter.name') === '') {
          setValue('chapter.name', beforeEdit.current);
        } else {
          const name = watch('chapter.name');
          editEntry({
            name,
          });
        }

        setEditing(false);
      }}
      required
      autoComplete="off"
      defaultValue={watch('chapter.name')}
      type="text"
      placeholder="Name eintragen..."
      style={{ width: `${watch('chapter.name').length * 14}px` }}
      className={`border-2 max-w-[400px] px-1 border-base-200 rounded-md bg-background input input-bordered chapter-name-input focus:ring-0 focus:outline-none col-span-5 text-xl`}
      name="name"
    />
  );
};

function VariantSelect() {
  const { chapterID, shortID, projectID } = useParams();
  const { setErrors } = useContext(TrackContext);
  const navigate = useNavigate();

  const { data: variants } = useFdk({
    model: 'chapter',
    action: 'entryList',
    options: {
      parent: chapterID,
    },
  });

  const methods = useFormContext();

  const validateTranslation = () => {
    setErrors([]);
    const errors = methods.getValues('chapter.content.sections').reduce((acc, section, index) => {
      if (!section.content?.trim()?.length && section.type === 'text') {
        acc.push({ type: section.type, index });
      }
      return acc;
    }, [] as ValidationError[]);

    if (errors.length === 0) {
      showModal('chapterTranslateModal');
    } else {
      if (errors.length > 0) {
        setErrors(errors);
        if (errors.length === 1 && errors[0].type === 'speech') {
          toast.error('Die Sektion ist leer. Leere Sektionen können nicht übersetzt werden.');
        } else {
          toast.error('Es gibt leere Sektionen. Leere Sektionen können nicht übersetzt werden.');
        }
        const [firstError] = errors;
        const element = document.querySelectorAll('.section')[firstError.index];
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
    setErrors([]);
  };

  if (!variants?.items || variants.items.length === 0) {
    return (
      <Button variant="outline" onClick={() => validateTranslation()}>
        <div className="flex items-center gap-x-2 p-1 px-3">
          <LanguageIcon className="w-5 h-5" />
          Übersetzen
        </div>
      </Button>
    );
  }

  return (
    <Select
      onValueChange={(e) => {
        navigate(`/${shortID}/projects/${projectID}/chapters/${e}`);
      }}
    >
      <SelectTrigger>
        <span className="block truncate">Übersetzungen</span>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {variants.items.map((variant) => (
            <SelectItem key={variant.id} value={variant.id}>
              <div className="flex gap-x-2">
                <span className="block truncate">{getFlagEmoji(variant.language)}</span>
                <span className="block truncate">{variant.name}</span>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
        <div className="border-t" />
        <div
          onClick={() => validateTranslation()}
          className="py-2 pl-8 pr-9 hover:bg-background text-white cursor-pointer rounded-md text-sm"
        >
          <span>Übersetzung hinzufügen</span>
        </div>
      </SelectContent>
    </Select>
  );
}

const SkeletonLayout = () => {
  return (
    <>
      <LayoutEditor
        aside={
          <aside className=" min-w-[300px] sticky top-16">
            <nav className="grid  items-start px-4 text-sm font-medium lg:px-4 pt-6 md:pt-10">
              <div className="flex mt-2 items-center gap-3 rounded-lg px-3 py-2 text-muted-foreground transition-all ">
                <ListBulletIcon className="w-5 h-5 text-gray-400" /> <Skeleton className="w-9/12 h-5 bg-border" />
              </div>
            </nav>

            <ul className="mx-8 px-2 text-sm font-medium  pt-1 flex flex-col gap-y-2">
              <DndZoneSideMenu setActiveSection={(e) => {}} items={Array(11).fill(0)} move={() => {}} remove={() => {}}>
                {(index: number) => {
                  return (
                    <>
                      <Skeleton
                        className={classNames(
                          'group w-full  h-12 flex items-center gap-3 justify-start rounded-lg p-4 border',
                        )}
                      >
                        <div className="w-5 h-5 p-2 ml-1 flex items-center justify-center">
                          <div className="w-4 h-4 flex p-2 text-center align-middle items-center justify-center group-hover:hidden">
                            <span className={classNames('text-gray-400')}>{index + 1}</span>
                          </div>
                          <div className="hidden p-2  group-hover:flex">
                            <Bars2Icon className="w-4 h-4" />
                          </div>
                        </div>

                        <Skeleton className="h-4 w-full rounded-md bg-border" />
                      </Skeleton>
                    </>
                  );
                }}
              </DndZoneSideMenu>
            </ul>
          </aside>
        }
      >
        <main className="drawer-content px-6 pb-6 min-h-[calc(100vh_-_theme(spacing.16))]">
          <div className="grid sticky top-0 grid-cols-12 grid-rows-[min-content] gap-y-12 py-6 lg:gap-x-12 bg-background z-10 items-center">
            <TopNav>
              <div className="flex grow items-center justify-between space-x-4 pt-1.5">
                <div className="flex grow items-baseline space-x-4 pt-1.5">
                  <Breadcrumb>
                    <BreadcrumbList>
                      <BreadcrumbItem>
                        <Skeleton className="w-40 h-8 bg-border" />
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <Skeleton className="w-52 h-8 bg-border" />
                      </BreadcrumbItem>
                    </BreadcrumbList>
                  </Breadcrumb>
                </div>
                <div className="sticky bottom-0 bg-base flex items-center justify-end p-2 pt-1.5 pb-3 gap-x-4">
                  <Skeleton className="w-40 h-10 border rounded-md flex items-center justify-center gap-x-2">
                    <Skeleton className="w-6 h-6 bg-border rounded-full" />
                    <Skeleton className="w-20 h-4 bg-border rounded-md" />
                  </Skeleton>
                  <Skeleton className="w-32 h-10 border rounded-md flex items-center justify-center">
                    <Skeleton className="w-20 h-4 bg-border rounded-md" />
                  </Skeleton>
                  <Skeleton className="w-32 h-10 bg-border rounded-md" />
                </div>
              </div>
            </TopNav>
          </div>
          <div className="flex flex-col items-center grow px-4 sm:px-0">
            <Section.Skeleton />
            <Section.Skeleton />
          </div>
        </main>
      </LayoutEditor>
    </>
  );
};
