import { useTranslation } from 'react-i18next';
import { Modal, hideModal } from '../Modal';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormAudio } from '@/routes/VoiceCreateModal';
import toast from 'react-hot-toast';
import { useCallback, useRef, useState } from 'react';
import { Button } from '@/components/ui/button';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Progress } from '@/components/ui/progress';
import { useParams } from 'react-router-dom';
import { usePlainFdk } from '@/fdk';
import { VoiceSelectLocal } from '@/components/VoiceSelect';
import { Label } from '@/components/ui/label';
import { AssetResource, EntryResource, Sdk } from 'ec.fdk';
import { PlayIcon, PauseIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon } from 'lucide-react';

export const uploadFormSchema = z.object({
  file: z.any(),
  fileType: z.enum(['file', 'url']),
});

export function useSTTForm({ shortID }: { shortID: string | undefined }) {
  const { t, i18n } = useTranslation('translation');
  const [pending, setPending] = useState(false);
  const [progress, setProgress] = useState(0);
  const [examples, setExamples] = useState({});
  const [buffer, setBuffer] = useState<AudioBuffer | null>(null);
  const [message, setMessage] = useState('');
  const [speakerData, setSpeakerData] = useState<SpeakerSegment[] | undefined>(undefined);
  const [activeExample, setActiveExample] = useState<string | undefined>(undefined);
  const activeBufferSource = useRef<AudioBufferSourceNode>();
  const fdk = usePlainFdk();
  const uploadForm = useForm<z.infer<typeof uploadFormSchema>>({
    resolver: zodResolver(uploadFormSchema),
    mode: 'onChange',
    defaultValues: {
      file: '',
      fileType: 'file',
    },
  });

  const speakerForm = useForm<z.infer<typeof speakerFormSchema>>({
    resolver: zodResolver(speakerFormSchema),
    mode: 'onChange',
    defaultValues: {
      speakers: {},
    },
  });

  const speakers = speakerForm.watch('speakers');
  const hasSpeakers = !!Object.keys(speakers).length;
  const file = uploadForm.watch('file');

  function toggleExample(speakerName) {
    if (activeExample && activeBufferSource.current) {
      activeBufferSource.current.stop();
      setActiveExample(undefined);
    } else {
      const example = examples[speakerName];
      const ac = new AudioContext();
      const src = ac.createBufferSource();
      src.connect(ac.destination);
      src.buffer = buffer;
      const len = Math.min(example.range[1] - example.range[0], 7.5);
      src.start(ac.currentTime, example.range[0]);
      src.stop(ac.currentTime + len);
      src.onended = function () {
        setActiveExample(undefined);
      };
      setActiveExample(speakerName);
      activeBufferSource.current = src;
    }
  }

  async function onSubmitFile(values) {
    try {
      setPending(true);
      setMessage('Sprachdatei wird hochgeladen...');
      const ac = new AudioContext();
      const _buffer = await ac.decodeAudioData(await values.file.arrayBuffer());
      setBuffer(_buffer);
      const speed = 4; // e.g. 3 = 3s of audio is processed in 1s
      const msEstimate = Math.round(_buffer.duration / speed) * 1000;
      let started = performance.now();
      const interval = setInterval(() => {
        const msElapsed = performance.now() - started;
        const prog = Math.min((msElapsed / msEstimate) * 100, 100);
        setProgress(prog);
        if (prog >= 100) {
          setMessage('Nur noch einen Moment...');
          clearInterval(interval);
        }
      }, 100);
      const asset = await uploadSpeechFile(fdk, values.file);
      await fetchEventSource(`${import.meta.env.VITE_QUEUE_URL}/${shortID}/stt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          assetID: asset.assetID,
        }),
        async onmessage(msg) {
          const { data, event } = msg;
          if (event === 'progress') {
            const { percentage } = JSON.parse(data);
            if (percentage >= 50) {
              setMessage('Datei wird verarbeitet...');
            }
          }
          if (event === 'completed') {
            const _speakerData = JSON.parse(data) as SpeakerSegment[];
            setSpeakerData(_speakerData);
            const speakerNames = [...new Set(_speakerData.map((s) => s.speaker))];
            // map speaker name like SPEAKER_01 to speaker id for voice select
            const speakers = Object.fromEntries(speakerNames.map((name) => [name, 'none']));
            const _examples = Object.fromEntries(
              speakerNames.map((name) => [name, _speakerData?.find((s) => s.speaker === name)]),
            );
            setExamples(_examples);
            if (!speakerNames.length) {
              throw new Error('no speakers found in audio file');
            }
            speakerForm.reset({
              speakers,
            });
          }
          if (event === 'failed') {
            console.error(event);
            toast.error(t('chapter.speechToTextModal.error'));
          }
        },
        async onclose() {
          toast.dismiss(shortID);
          setPending(false);
        },
        onerror() {
          toast.error('Fehler beim Generieren');
          throw new Error('no_retry');
        },
        openWhenHidden: true,
      }).catch(() => null);
    } catch (error) {
      console.error(error);
      toast.error(t('chapter.speechToTextModal.error'));
    }
    setPending(false);
  }

  const getSections = useCallback(() => {
    if (!speakerData || !speakers) {
      throw new Error('cannot get sections: speakerData or speakers not set yet');
    }
    return speakerData.map((segment, i) => {
      return {
        type: 'text',
        pause: 1,
        title: `Section ${i + 1}`,
        content: segment.text,
        voiceID: speakers[segment.speaker],
      };
    });
  }, [speakerData, speakers]);
  const reset = () => {
    speakerForm.reset({ speakers: {} });
    uploadForm.reset();
  };
  return {
    pending,
    setPending,
    hasSpeakers,
    uploadForm,
    onSubmitFile,
    file,
    progress,
    speakers,
    getSections,
    speakerForm,
    t,
    fdk,
    reset,
    message,
    toggleExample,
    activeExample,
  };
}

const speakerFormSchema = z.object({
  speakers: z.object({
    name: z.string(),
    id: z.string(),
  }),
});

export declare interface SpeakerSegment {
  speaker: string;
  text: string;
  range: [number, number];
}

export function uploadSpeechFile(fdk: Sdk, file: File): Promise<AssetResource> {
  return fdk.assetGroup('audio_resource').createAsset({
    file,
    options: {
      deduplicate: true,
    },
  } as any);
}

export default function SpeechToTextModal({
  id,
  shortID,
  mutate,
}: {
  id: string;
  shortID: string;
  mutate: () => void;
}) {
  const { chapterID } = useParams<{ shortID: string; chapterID: string; projectID: string }>();
  const {
    pending,
    setPending,
    getSections,
    hasSpeakers,
    uploadForm,
    onSubmitFile,
    file,
    progress,
    speakers,
    speakerForm,
    t,
    fdk,
    reset,
    message,
    toggleExample,
    activeExample,
  } = useSTTForm({ shortID });

  async function onSubmitSpeakers() {
    setPending(true);
    try {
      if (!chapterID) {
        throw new Error('chapterID not set!');
      }
      const sectionsToCreate = getSections();
      const chapter = (await fdk.dm(shortID).model('chapter').getEntry(chapterID)) as EntryResource & { content: any };
      const existingSections = (chapter.content.sections || []).filter((section, i) => section.content !== '');
      const editedChapter = {
        content: {
          ...chapter.content,
          sections: [...existingSections, ...sectionsToCreate],
        },
      };
      await fdk.dm(shortID).model('chapter').editEntry(chapterID, editedChapter);
      hideModal('speechToTextModal');
      mutate?.();
      toast.success(t('chapter.speechToTextModal.success'));
      setTimeout(() => {
        reset();
      }, 200);
    } catch (err) {
      console.error(err);
      toast.error(t('chapter.speechToTextModal.error'));
    }
    setPending(false);
  }

  return (
    <Modal id={id as string} dontCloseOnClickOutside headline={t('chapter.speechToTextModal.headline')}>
      {!pending && !hasSpeakers && (
        <form onSubmit={uploadForm.handleSubmit(onSubmitFile)}>
          <FormAudio f={uploadForm} description={t('chapter.speechToTextModal.selectFile')} maxSize={25} />
          {file && (
            <div className="form-control">
              <div className="flex items-end justify-center py-4 gap-x-2">
                <Button type="submit" disabled={pending}>
                  {t('chapter.speechToTextModal.save')}
                  {pending && <span className="loading loading-infinity loading-xs" />}
                </Button>
              </div>
            </div>
          )}
        </form>
      )}
      {pending && !hasSpeakers && (
        <div className="flex flex-col">
          <Label>{t('chapter.speechToTextModal.description2')}</Label>
          <div className="w-full py-2 px-4 mt-4">
            <Progress className="progress w-full" value={progress} />
            <span className="text-center block text-xs mt-1">{message}</span>
          </div>
        </div>
      )}
      {hasSpeakers && (
        <>
          <div className="flex flex-col pt-2">
            <Label>
              Es wurde{Object.keys(speakers).length !== 1 ? 'n' : ''} {Object.keys(speakers).length} Stimme
              {Object.keys(speakers).length !== 1 ? 'n' : ''} erkannt. Bitte weise die erkannten Stimmen je einer lizzen
              Stimme zu:
            </Label>
            <div className="space-y-8 pt-6">
              {Object.entries(speakers).map(([name, id], i) => (
                <div key={name} className="flex space-x-4 justify-center min-w-[200px] items-center w-full">
                  <div className="flex space-x-4 items-center">
                    <Button type="button" onClick={() => toggleExample(name)} className="p-0 px-3 rounded-full w-9 h-9">
                      {activeExample === name ? <PauseIcon className="w-4 h-4" /> : <PlayIcon className="w-4 h-4" />}
                    </Button>
                    <span>Stimme&nbsp;{i + 1}</span>
                    <ArrowRightIcon className="w-4 h-4" />
                  </div>
                  <VoiceSelectLocal
                    value={id}
                    onChange={(e) => {
                      speakerForm.setValue('speakers', { ...speakers, [name]: e?.id });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="form-control">
            <div className="flex items-end justify-center py-4 gap-x-2">
              <Button type="button" onClick={onSubmitSpeakers} disabled={pending}>
                {t('chapter.speechToTextModal.save')}
                {pending && <span className="loading loading-infinity loading-xs" />}
              </Button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
