import { usePlainFdk } from '@/fdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

export function SubscriptionCheck() {
  const { t } = useTranslation('translation');
  const [status, setStatus] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { shortID } = useParams();
  const fdk = usePlainFdk();
  const navigate = useNavigate();
  const isExpired = useMemo(
    () => ['incomplete_expired', 'past_due', 'unpaid', 'paused', 'canceled'].includes(status as string),
    [status],
  );
  const action = useMemo(() => {
    switch (status) {
      case 'incomplete_expired':
      case 'past_due':
      case 'unpaid':
        return {
          headline: t('subscriptionCheck.unpaid.headline'),
          text: t('subscriptionCheck.unpaid.text'),
          cta: t('subscriptionCheck.unpaid.button'),
          action: async () => {
            const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/billing-portal`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                return_url: window.location.href,
              }),
            });
            if (!res.ok) {
              toast.error(t('subscriptionCheck.unpaid.errorRedirecting'));
              console.error('Error creating billing portal session', res);
              return;
            }
            const { url } = await res.json();
            window.location.href = url;
          },
        };
      case 'paused':
        return {
          headline: t('subscriptionCheck.paused.headline'),
          text: t('subscriptionCheck.paused.text'),
          cta: t('subscriptionCheck.paused.button'),
          action: async () => {
            const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/billing-portal`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                return_url: window.location.href,
              }),
            });
            if (!res.ok) {
              toast.error(t('subscriptionCheck.paused.errorRedirecting'));
              console.error('Error creating billing portal session', res);
              return;
            }
            const { url } = await res.json();
            window.location.href = url;
          },
        };
      case 'canceled':
        return {
          headline: t('subscriptionCheck.cancelled.headline'),
          text: t('subscriptionCheck.cancelled.text'),
          cta: t('subscriptionCheck.cancelled.button'),
          action: () => {
            navigate(`/onboarding/aboselect?id=${shortID}`);
          },
        };
      default:
        return null;
    }
  }, [status, shortID, navigate]);

  useEffect(() => {
    fdk
      .resource('dataManager')
      .route('')
      .raw({
        shortID,
      })
      .then(
        ({
          _embedded: {
            'ec:datamanager': {
              config: { stripe },
            },
          },
        }) => setStatus(stripe?.subscriptionStatus ?? null),
        //}) => setStatus('unpaid'), // for testing
      );
  }, []);

  useEffect(() => {
    setOpen(isExpired);
    return () => {
      setOpen(false);
    };
  }, [isExpired]);

  return (
    <AlertDialog open={open}>
      {/*  onOpenChange={setOpen} */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{action?.headline}</AlertDialogTitle>
          <AlertDialogDescription>{action?.text}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {/* <AlertDialogCancel>Cancel</AlertDialogCancel> */}
          <AlertDialogAction onClick={() => action?.action?.()}>{action?.cta}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
