import { useStore } from '@nanostores/react';
import { atom } from 'nanostores';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './ui/dialog';
export const $modals = atom<string | undefined>(undefined);

export const showModal = (id: string) => {
  $modals.set(id);
};

export const hideModal = (id: string) => {
  $modals.set(undefined);
};

export function Modal({
  id,
  headline,
  children,
  dontCloseOnClickOutside,
}: React.PropsWithChildren<{
  headline?: string;
  id: string;
  dontCloseOnClickOutside?: boolean;
}>) {
  const modals = useStore($modals);
  return (
    <Dialog open={modals === id} onOpenChange={(e) => $modals.set(e ? id : undefined)}>
      <DialogContent onPointerDownOutside={(e) => dontCloseOnClickOutside && e.preventDefault()}>
        <DialogHeader>
          <DialogTitle>{headline}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}
