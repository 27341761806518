import { Layout } from '../Layout';
import LizzenLoader from '../assets/lizzen-loader.gif';

export function PageLoaderWithNav() {
  return (
    <Layout>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-background bg-opacity-90">
        <img src={LizzenLoader} className="w-20 h-auto" />
      </div>
    </Layout>
  );
}
