import hexToHsl from 'hex-to-hsl';
import { useParams } from 'react-router-dom';

const colorsMap = {
  '25469537': {
    colors: {
      '--primary': '#e10915',
      '--primary-foreground': '#ffffff',
      '--secondary': '#e10915',
      '--secondary-foreground': '#ffffff',
      '--background': '#FFFFFF',
      '--foreground': '#1c1c1c',
      '--muted': '#f0f0f0',
      '--muted-foreground': '#424242',
      '--radius': '0px',
      '--card': '#f0f0f0',
      '--card-foreground': '#424242',
      '--border': '#d9d9d9',
      '--popover': '#ffffff',
      '--popover-foreground': '#e10915',
      '--accent': '#e10915',
      '--accent-foreground': '#ffffff',
    },
  },
  '9de61e3e': {
    colors: {
      '--primary': '#e10915',
      '--primary-foreground': '#ffffff',
      '--secondary': '#e10915',
      '--secondary-foreground': '#ffffff',
      '--background': '#FFFFFF',
      '--foreground': '#1c1c1c',
      '--muted': '#f0f0f0',
      '--muted-foreground': '#424242',
      '--radius': '0px',
      '--card': '#f0f0f0',
      '--card-foreground': '#424242',
      '--border': '#d9d9d9',
      '--popover': '#ffffff',
      '--popover-foreground': '#e10915',
      '--accent': '#e10915',
      '--accent-foreground': '#ffffff',
    },
  },
};

const CustomColors = () => {
  const { shortID } = useParams();
  const customColors = colorsMap[shortID as string];
  if (!customColors) {
    return null;
  }
  const css = `:root {
${Object.entries(customColors.colors)
  .map(([key, value]) => {
    if (key === '--radius') return `${key}: ${value};`;
    const [h, s, l] = hexToHsl(value);
    return `${key}: ${h} ${s}% ${l}%;`;
  })
  .join('\n')}
}`;
  return (
    <div className="h-12">
      <style>{css}</style>
      <div className="fixed bottom-0 left-0 right-0 p-3 text-sm bg-card text-card-foreground text-center border-t">
        Powered by{' '}
        <a className="font-bold" target="_blank" href="https://lizzen.de/">
          lizzen
        </a>{' '}
        in Kooperation mit{' '}
        <a className="font-bold" target="_blank" href="https://heardis.com/">
          HearDis!
        </a>
      </div>
    </div>
  );
};

export default CustomColors;
