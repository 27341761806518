function subscribe(eventName, listener) {
  document.addEventListener(eventName, listener);
}

function unsubscribe(eventName, listener) {
  document.removeEventListener(eventName, listener);
}

function publish(eventName, data?: any) {
  document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
}

export { publish, subscribe, unsubscribe };
