import { CheckIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { classNames } from '../utils';
import { useTranslation } from 'react-i18next';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/tabs';

export const useFrequency = () => {
  const { t } = useTranslation();
  return [
    {
      value: 'month',
      label: t('translation:projects.aboSelect.monthly'),
      priceSuffix: t('translation:projects.aboSelect.perMonth'),
    },
    {
      value: 'year',
      label: t('translation:projects.aboSelect.yearly'),
      priceSuffix: t('translation:projects.aboSelect.perYear'),
    },
  ];
};

export type Plan = {
  id: string;
  sort: number;
  title: string;
  href: string;
  selectedInterval?: string;
  prices: { month: string; year: string };
  description: string;
  features: { name: string }[];
  highlights: string[];
  selected: false;
  priceMonthly: string;
  audioGeneration: string;
  minutes_inclusive: string;
  audioGenerationExtra: string;
  contentAssistant: string;
  layout: string;
  languages: string;
  customVoice: string;
  audioQuality: string;
  onboarding: string;
  support: string;
  visitors: string;
};

export type Frequency = {
  value: any;
  label: string;
  priceSuffix: string;
};

export default function Pricing({ plans }: { plans: Plan[] }) {
  const frequencies = useFrequency();
  return (
    <>
      <Tabs defaultValue="month">
        <div className="w-full flex items-center justify-center">
          <TabsList>
            {frequencies.map((option: Frequency) => (
              <TabsTrigger value={option.value}>{option.label}</TabsTrigger>
            ))}
          </TabsList>
        </div>
        {frequencies.map((option: Frequency) => (
          <TabsContent value={option.value}>
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none xl:grid-cols-4 lg:grid-cols-2">
              {plans
                ?.sort((p) => p.sort)
                .map((plan) => <SinglePrice plan={plan} frequency={option} key={plan.title} />)}
            </div>
          </TabsContent>
        ))}
      </Tabs>
    </>
  );
}

const SinglePrice = ({ plan, frequency }: { plan: Plan; frequency: Frequency }) => {
  const [pending, setPending] = useState(false);
  const { t } = useTranslation('translation');
  const getCheckoutUrl = async (plan: Plan) => {
    const studioname = new URLSearchParams(window.location.search).get('studioname');
    if (!studioname) {
      return toast.error(t('aboSelect.errorName'));
    }
    setPending(true);
    try {
      const res = await fetch(
        `${import.meta.env.VITE_API_URL}/abo-book/${plan.id}?interval=${frequency.value}&studioname=${studioname}`,
      );
      const { url } = await res.json();

      window.location = url;
    } catch (error) {
      console.error('error', error);
      toast.error(t('aboSelect.errorFreePlan'));
    }
    setPending(false);
  };
  return (
    <div
      className={classNames(
        plan.selected ? 'bg-white/5 ring-2 ring-primary' : 'ring-1 ring-white/10',
        'rounded-3xl p-8 xl:p-10',
      )}
    >
      <div className="flex items-center justify-between gap-x-4 h-4">
        {plan.selected ? (
          <p className="rounded-full bg-primary px-2.5 py-1 text-xs font-semibold leading-5 text-white">
            {t('aboSelect.mostPopular')}
          </p>
        ) : null}
      </div>
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-4xl font-bold tracking-tight text-white">{plan?.minutes_inclusive}</span>
        <span className="text-sm font-semibold leading-6 text-gray-300">{t('aboSelect.minutes')}</span>
      </p>
      <p>
        <span className="text-sm font-semibold leading-6 text-gray-300">{t('aboSelect.speechGeneration')}</span>
      </p>
      <h3 id={plan.title} className="text-lg font-semibold leading-8 text-white mt-8">
        {plan.title}
      </h3>
      <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10 h-28">
        {plan?.features?.map((feature) => (
          <li key={feature?.name} className="flex gap-x-3">
            <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
            {feature?.name}
          </li>
        ))}
      </ul>

      <p className="mt-6 flex items-baseline gap-x-1">
        {plan?.prices?.month && plan?.prices?.year && (
          <span className="text-2xl font-bold tracking-tight text-white">
            {plan?.prices[frequency?.value as keyof typeof plan.prices]} €
          </span>
        )}
        <span className="text-sm font-semibold leading-6 text-gray-300">{frequency?.priceSuffix}</span>
      </p>

      <div
        onClick={() => getCheckoutUrl(plan)}
        aria-describedby={plan.title}
        className={classNames(
          pending ? 'cursor-not-allowed ' : 'cursor-pointer',
          plan.selected
            ? 'bg-primary text-white shadow-sm hover:bg-primary/80 focus-visible:outline-primary'
            : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
          'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
        )}
      >
        {!pending ? t('aboSelect.bookPlan') : <span className="loading loading-infinity loading-xs" />}
      </div>
    </div>
  );
};
