import { PhotoIcon, PlusIcon } from '@heroicons/react/20/solid';

import { Button } from '@/components/ui/button';
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Form, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import { getFlagEmoji } from '@/utils';
import { DialogClose } from '@radix-ui/react-dialog';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../Layout';
import EmptyState from '../components/EmptyState';
import { hideModal } from '../components/Modal';
import { TopNav } from '../components/TopNav';
import { useFdk, usePlainFdk } from '../fdk';

function AudioGuideList() {
  const navigate = useNavigate();
  const { t } = useTranslation('translation');
  const fdk = usePlainFdk();

  const { data: tourList, mutate } = useFdk({
    action: 'entryList',
    model: 'guide_tours',
  });

  const tours = useMemo(() => {
    if (!tourList?.items) return;
    if (!Array.isArray(tourList?.items)) return [tourList?.items];
    return tourList?.items;
  }, [tourList]);

  const form = useForm({});
  const [createModal, setCreateModal] = useState(false);

  // if (!tours) return <PageLoaderWithNav />;
  return (
    <Layout>
      <TopNav>
        <div className="flex grow space-x-4">
          <div className="flex-row grow">
            <div className="flex justify-between">
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
                    {t('audioGuide.list.headline')}
                  </h1>
                </div>
              </div>
              <div className="grid justify-items-end">
                <Button onClick={() => navigate('create')}>
                  <PlusIcon className="w-5 h-5" /> {t('audioGuide.create')}
                </Button>
                <Dialog open={createModal} onOpenChange={setCreateModal}>
                  <DialogTrigger className="btn btn-sm"></DialogTrigger>
                  <DialogContent className="!bg-base-100">
                    <DialogHeader>
                      <DialogTitle>{t('audioGuide.create')}</DialogTitle>
                      <DialogDescription>
                        <Form {...form}>
                          <form
                            onSubmit={async (e: any) => {
                              e.preventDefault();
                              const formData = new FormData(e.target);
                              const values = Object.fromEntries(formData.entries());
                              const res = (await fdk.model('guide_tours').createEntry({
                                title: {
                                  de_DE: values.title,
                                },
                                description: {},
                                cover: null,
                                duration: null,
                                publish_date: null,
                                tags: [],
                                stations: [],
                                languages: 'de_DE',
                              })) as any;
                              toast.success('Projekt wurde erstellt!');
                              navigate(res.id, { state: { firstView: true } });
                              hideModal('createModal');
                            }}
                          >
                            <div className="form-control mt-3">
                              <FormItem>
                                <FormLabel>{t('audioGuide.tour.name')}</FormLabel>
                                <Input
                                  required
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Name eintragen..."
                                  className="input input-bordered"
                                  name="title"
                                />
                              </FormItem>
                            </div>

                            <div className="form-control">
                              <div className="flex items-end justify-end py-4 gap-x-4">
                                <DialogClose>
                                  <Button variant="outline">{t('audioGuide.cancel')}</Button>
                                </DialogClose>
                                <Button className="" type="submit">
                                  {t('audioGuide.save')}
                                </Button>
                              </div>
                            </div>
                          </form>
                        </Form>
                      </DialogDescription>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </TopNav>
      <section className="card col-span-12 overflow- bg-base-100 shadow-sm xl:col-span-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {!tours && Array.from(Array(5).keys()).map((i) => <TourCard.Skeleton key={i} />)}
        {tours?.length ? (
          tours?.map((tour: any) => <TourCard key={tour.id} tour={tour} mutateList={mutate} />)
        ) : (
          <div className="col-span-3">
            <EmptyState
              description={t('audioGuide.list.empty.description')}
              error={t('audioGuide.list.empty.error')}
              title={t('audioGuide.list.empty.title')}
              onClick={() => setCreateModal(true)}
            />
          </div>
        )}
      </section>
    </Layout>
  );
}

const TourCard = ({ tour, mutateList }) => {
  const fdk = usePlainFdk();
  const navigate = useNavigate();
  const { shortID } = useParams<{ shortID: string }>();
  const { t } = useTranslation();

  const [cover, setCover] = useState(null);
  useEffect(() => {
    if (!tour.cover) return;
    fdk
      .assetGroup('guide_tour_images')
      .getAsset(tour.cover)
      .then((res) => {
        setCover(res.file.url as any);
      });
  }, [tour, fdk]);

  return (
    <Card className="">
      <a href={`/${shortID}/audio-guide/${tour.id}`}>
        <CardHeader>
          {cover ? (
            <img
              src={cover}
              className="mb-3 rounded-lg aspect-video object-cover"
              alt={tour.title[tour.languages.split(',')[0]]}
            />
          ) : (
            <div className="w-full aspect-video bg-card-foreground flex items-center rounded-lg mb-3 justify-center">
              <PhotoIcon className="w-8 h-8 text-card" />
            </div>
          )}
          <CardTitle className="flex gap-3 ">
            {tour.title[tour.languages.split(',')[0]]}
            {tour.languages.split(',').map((lang) => {
              const [, region] = lang.split('-');

              return (
                region && (
                  <span key={lang} className="text-lg">
                    {getFlagEmoji(region)}
                  </span>
                )
              );
            })}
          </CardTitle>
        </CardHeader>
      </a>

      <CardFooter className="flex self-end justify-between mt-auto gap-3">
        <CardDescription>
          <span className="text-xs">{dayjs(tour?.created).format('DD.MM.YYYY')}</span>
        </CardDescription>
        <div>
          <Button className="mr-3" onClick={() => navigate(`/${shortID}/audio-guide/${tour.id}`)}>
            {t('translation:audioGuide.list.edit')}
          </Button>
          {/* <Dialog>
            <DialogTrigger>
              <Button variant="outline" className="p-4">
                <TrashIcon className="w-4 h-4 text- cursor-pointer" />
              </Button>
            </DialogTrigger>
            <DialogContent className="!bg-base-100">
              <DialogHeader>
                <DialogTitle>Wirklich löschen?</DialogTitle>
                <DialogDescription>
                  <h3 className="py-4 text-large text-left"> Dies kann nicht rückgängig gemacht werden</h3>
                  <div className="modal-action">
                    <DialogClose className="btn">Abbrechen</DialogClose>
                    <Button
                      variant="destructive"
                      disabled={pending}
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setPending(true);
                        try {
                          await fdk.model('guide_tours').deleteEntry(tour.id);
                          mutateList();
                          toast.success('Projekt gelöscht!');
                        } catch (e) {
                          toast.error('Projekt konnte nicht gelöscht werden!');
                        }
                        setPending(false);
                      }}
                    >
                      Löschen {pending && <span className="loading loading-infinity loading-xs" />}
                    </Button>
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog> */}
        </div>
      </CardFooter>
    </Card>
  );
};

TourCard.Skeleton = () => (
  <Card>
    <CardHeader>
      <Skeleton className="aspect-video w-full rounded-lg bg-border" />
      <CardTitle className="flex gap-6 ">
        <Skeleton className="h-6 w-8/12 rounded-lg bg-border mt-3" />
      </CardTitle>
    </CardHeader>

    <CardFooter className="flex self-end justify-between mt-auto">
      <CardDescription>
        <Skeleton className="h-3 w-20 rounded-sm bg-border" />
      </CardDescription>
      <Skeleton className="h-10 w-28 rounded-lg bg-border" />
    </CardFooter>
  </Card>
);

export default AudioGuideList;
