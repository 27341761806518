import { PencilIcon, SpeakerWaveIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { VoiceContext } from '../routes/Voices';
import { classNames } from '../utils';
import { hideModal } from './Modal';
import { Badge } from './ui/badge';
import { Button } from './ui/button';
import { Card, CardFooter, CardHeader } from './ui/card';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import { Skeleton } from './ui/skeleton';
import VoiceEditDialog from './VoiceEditDialog';

function VoiceDeleteModal({ voiceID }: { id: string; voiceID: string }) {
  const { shortID } = useParams();
  const [pending, setPending] = useState(false);
  const { t } = useTranslation('translation');

  return (
    <>
      <p>{t('voices.voiceDeleteModal.description')}</p>
      <div className="modal-action flex gap-3 justify-end">
        <Button variant="secondary" disabled={pending} className="btn btn-sm" onClick={() => hideModal('deleteModal')}>
          {t('voices.voiceDeleteModal.cancel')}
        </Button>
        <Button
          disabled={pending}
          variant="destructive"
          onClick={async () => {
            try {
              setPending(true);
              const res = await fetch(`${import.meta.env.VITE_API_URL}/${shortID}/voices/${voiceID}`, {
                method: 'DELETE',
              });

              if (!res.ok) {
                throw new Error(t('voices.voiceDeleteModal.deleteError'));
              }

              await mutate(`${import.meta.env.VITE_API_URL}/${shortID}/voices`);
              hideModal('deleteModal');
              toast.success(t('voices.voiceDeleteModal.deleteSuccess'));
            } catch ({ message }: any) {
              toast.error(message);
            } finally {
              setPending(false);
            }
          }}
        >
          {t('voices.voiceDeleteModal.delete')} {pending && <span className="loading loading-infinity loading-xs" />}
        </Button>
      </div>
    </>
  );
}

export function VoiceCard({
  name,
  description,
  tags,
  id,
  image,
  global = false,
  modified,
}: {
  name: string;
  description: string;
  id: string;
  image?: string;
  tags?: Array<{
    id: string;
    label: string;
    type: string;
  }>;
  global: boolean;
  modified: string;
}) {
  const [error, setError] = useState(false);
  const [play, setPlay] = useState(false);
  const audio = useRef<HTMLAudioElement>(null);
  const { shortID } = useParams();
  const { playingCardRef } = useContext(VoiceContext);
  const { t } = useTranslation('translation');
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (error) {
      setPlay(false);
      toast.error(t('voices.errorPlayingVoice'));
    }
  }, [error]);

  return (
    <Card className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 flex flex-col justify-between group">
      <CardHeader className="p-4">
        <div className="relative">
          {!global && (
            <Dialog open={edit} onOpenChange={setEdit}>
              <DialogTrigger asChild>
                <Button
                  className="absolute top-0 right-0 hidden group-hover:block cursor-pointer px-3 py-1 h-8"
                  onClick={() => setEdit(true)}
                >
                  <PencilIcon className="w-4 h-4" />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <VoiceEditDialog name={name} id={id} setEdit={setEdit} />
              </DialogContent>
            </Dialog>
          )}

          <audio
            key={modified}
            src={`${import.meta.env.VITE_API_URL}/${shortID}/voices/${id}`}
            ref={audio}
            crossOrigin="anonymous"
            preload="none"
            onPlay={() => setPlay(true)}
            onPause={() => setPlay(false)}
            onEnded={() => setPlay(false)}
            onLoadStart={() => setPlay(false)}
            onError={() => setError(true)}
          />
          <div className="flex pb-4">
            <img
              src={image ?? '/logo.svg'}
              className="p-2.5 mr-4 h-12 w-12 rounded-full bg-base-300 border border-base-100"
            />
            <div>
              <h2 className="text-xl mb-2">{name}</h2>
              <p className="text-sm">{description}</p>
              <div className="flex flex-wrap gap-1 mt-2">
                {tags?.map(({ id, label, type }) => (
                  <Badge
                    variant="secondary"
                    className={classNames(
                      'inline-flex items-center rounded-full px-2 text-[9px] font-medium ring-1 ring-inset text-[#A6A6A6]',
                      type === 'gender' && 'bg-base-100',
                      type === 'tone' && 'bg-base-200',
                      type === 'topic' && 'bg-base-300',
                    )}
                    key={id}
                  >
                    {label}
                  </Badge>
                ))}
              </div>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardFooter className="border-t gap-1 flex-wrap justify-between p-2 relative">
        {!global && (
          <span className="text-[10px] absolute -top-5 right-2 opacity-65">
            aktualisiert am{' '}
            {new Date(modified).toLocaleString('de-DE', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        )}
        <Button
          disabled={error}
          variant="ghost"
          className="flex-1"
          onClick={() => {
            if (playingCardRef?.current === audio.current) {
              if (audio.current?.paused) {
                audio.current.play();
              } else {
                if (audio.current) {
                  audio.current.currentTime = 0;
                  audio.current?.pause();
                }
              }
              return;
            }
            if (playingCardRef?.current) {
              playingCardRef.current.pause();
              playingCardRef.current.currentTime = 0;
            }

            playingCardRef.current = audio.current;
            if (audio.current) {
              audio?.current.play();
            }
          }}
        >
          <div className="flex  items-center gap-x-2 justify-center">
            <SpeakerWaveIcon className={classNames('w-4 h-4', play && 'animate-ping')} />
            {t('voices.play')}
          </div>
        </Button>
        {!global && (
          <div className="flex-1">
            <Dialog>
              <DialogTrigger className="w-full" asChild>
                <Button variant="ghost" className="w-full">
                  <div className="flex  items-center gap-x-2 justify-center">
                    <TrashIcon className="w-4 h-4" />
                    {t('voices.delete')}
                  </div>
                </Button>
              </DialogTrigger>
              <DialogContent>
                <VoiceDeleteModal id="deleteModal" voiceID={id} />
              </DialogContent>
            </Dialog>
          </div>
        )}
      </CardFooter>
    </Card>
  );
}

VoiceCard.Skeleton = () => (
  <Card className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 justify-between flex flex-col">
    <CardHeader className="p-4">
      <div className="flex space-x-4">
        <Skeleton className="h-12 w-12 aspect-square rounded-full bg-border" />
        <div className="grow space-y-2">
          <Skeleton className="h-5 w-20 rounded-md bg-border" />
          <Skeleton className="h-4 w-full rounded-md bg-border" />
          <Skeleton className="h-4 w-20 rounded-md bg-border" />
          <div className="flex flex-wrap gap-1 !mt-4">
            <Skeleton className="h-4 w-16 rounded-lg border " />
            <Skeleton className="h-4 w-20 rounded-lg border " />
            <Skeleton className="h-4 w-20 rounded-lg border " />
          </div>
        </div>
      </div>
    </CardHeader>
    <CardFooter className="border-t gap-1 flex-wrap justify-between p-2">
      <Skeleton className="h-10 w-full rounded-md bg-border" />
    </CardFooter>
  </Card>
);
