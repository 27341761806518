export const MenuItem = ({
  iconLeft,
  iconRight,
  headline,
  subline,
  align = 'left',
  active = false,
  onRightButtonHref,
}: {
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  headline: string;
  subline?: string;
  align?: 'left' | 'right';
  active?: boolean;
  onRightButtonHref?: string;
}) => {
  return (
    <div
      className={`grid grid-cols-10 bg-base-100 rounded-md p-2 w-full ${
        active ? 'border-2 border-primary' : 'border-2 border-transparent'
      }`}
    >
      <div className="col-span-2 flex items-center justify-center">{iconLeft}</div>
      <div className="col-span-6 flex items-center">
        <div className="flex flex-col items-center overflow-clip">
          <span className={`text-sm font-medium text-${align} leading-tight text-ellipsis overflow-clip`}>
            {headline}
          </span>
        </div>
      </div>
      <a
        href={onRightButtonHref}
        download
        className={`${onRightButtonHref ? 'cursor-pointer' : ''} col-span-2 flex items-center justify-center`}
      >
        {iconRight ? iconRight : null}
      </a>
    </div>
  );
};
