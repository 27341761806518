import { useCallback, useEffect, useMemo, useState } from 'react';

declare const window: any;

window.dataLayer = window.dataLayer ?? [];

export default function CookieConsent() {
  const [cookies, setCookies] = useState('unknown');
  const domain = useMemo(() => {
    const parts = location.hostname.split('.');
    if (parts.length === 2) {
      return location.hostname;
    }
    if (parts.length === 1) {
      return parts[0];
    }

    return parts.slice(1).join('.');
  }, [location.hostname]);

  const getCookieConsent = useCallback(() => {
    try {
      // @ts-expect-error - cookie is not defined
      const consent = document.cookie.match(/cookie-consent=([^;]+)/)[1];
      return consent;
    } catch (error) {
      return 'unknown';
    }
  }, [document.cookie]);

  useEffect(() => {
    setCookies(getCookieConsent());
  }, []);

  const handleAccept = useCallback(() => {
    setCookies('granted');
    const d = new Date();
    const oneYear = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate());
    document.cookie = `cookie-consent=granted; domain=.${domain}; expires=${oneYear}; path=/`;
    setCookies('granted');
  }, [domain]);

  const handleDecline = useCallback(() => {
    document.cookie = `cookie-consent=denied; domain=.${domain}; path=/`;
    setCookies('denied');
  }, [domain]);

  useEffect(() => {
    if (cookies === 'unknown') {
      return;
    }
    window.dataLayer.push('consent', 'default', {
      ad_storage: cookies === 'granted' ? 'granted' : 'denied',
      analytics_storage: cookies === 'granted' ? 'granted' : 'denied',
      functionality_storage: cookies === 'granted' ? 'granted' : 'denied',
      personalization_storage: cookies === 'granted' ? 'granted' : 'denied',
      security_storage: cookies === 'granted' ? 'granted' : 'denied',
    });

    // if (cookies === 'granted') {
    //   window.$crisp = [];
    //   window.CRISP_WEBSITE_ID = 'b9a79706-b2ed-40d2-b02d-9fe6a0298c7e';
    //   const script = document.createElement('script');
    //   script.src = 'https://client.crisp.chat/l.js';
    //   script.async = true;
    //   document.head.appendChild(script);
    // }
  }, [cookies]);

  if (['granted', 'denied'].includes(cookies)) {
    return null;
  }

  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 z-[100] backdrop-blur-sm flex items-center justify-center">
      <div className="m-2 max-w-screen-sm rounded-lg border-2 border/10 bg-base-100 shadow-xl">
        <div className="p-4 text-center">
          <p className="mb-4 text-sm sm:text-base">
            Wir verwenden Cookies, um unsere Website zu analysieren und Ihr Erlebnis noch besser zu machen. Weitere
            Informationen finden Sie in unserer{' '}
            <a className="text-primary underline hover:text-opacity-85" href="/datenschutz">
              Datenschutzerklärung
            </a>
            .
          </p>

          <div className="mx-auto space-x-2">
            <button className="rounded-md p-2 transition hover:bg-primary/5" onClick={handleDecline}>
              Ablehnen
            </button>
            <button
              className="rounded-md bg-primary text-dark p-2 transition hover:bg-primary/85"
              onClick={handleAccept}
            >
              Akzeptieren
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
