import { act, sdk } from 'ec.fdk';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

export function useFdk(config: any, swrOptions?: any) {
  const { shortID } = useParams();

  const key = config ? JSON.stringify({ config, shortID }) : null;

  return useSWR(
    config?.action === 'getEntry' && !config?.entryID ? null : [key],
    async () => {
      const resp = await act({ env: import.meta.env.VITE_ENV, dmShortID: shortID, ...config });
      return resp;
    },
    {
      revalidateOnFocus: false,
      ...swrOptions,
    },
  );
}

export const usePlainFdk = () => {
  const { shortID } = useParams();
  return sdk(import.meta.env.VITE_ENV).dm(shortID as string);
};
