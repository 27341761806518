import { PlusIcon } from '@heroicons/react/24/solid';
import { Button } from './ui/button';

export default function EmptyState({
  title,
  description,
  error,
  onClick,
}: {
  title: string;
  description: string;
  error: string;
  onClick: () => void;
}) {
  return (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-400 ">{error}</h3>
      <p className="mt-1 text-sm text-gray-200 font-light">{description}</p>
      <div className="mt-6">
        <Button
          onClick={onClick}
          type="button"
          // className="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-base-200 shadow-sm hover:bg-primray/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          {title}
        </Button>
      </div>
    </div>
  );
}
