import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFdk, usePlainFdk } from '../../fdk';
import { TrackContext } from '../../routes/ChapterDetail';
import { Modal, hideModal } from '../Modal';
import { Button } from '../ui/button';

export function ChapterSplitPasteTextModal({ id }: { id: string }) {
  const { t } = useTranslation('translation');
  const { watch, getValues, setValue } = useFormContext();
  const { currentSectionIndex } = useContext(TrackContext);
  const { chapterID } = useParams<{ chapterID: string }>();
  const fdk = usePlainFdk();
  const { data: chapter, mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapterID,
  });

  const keepText = useCallback(async () => {
    const newContent = watch('lastPasted.newContent') as string[] | null;
    setValue(`chapter.content.sections.${currentSectionIndex}.content`, newContent);
    hideModal('ChapterSplitPasteTextModal');
  }, [chapter, watch('lastPasted')]);

  const splitPastedText = useCallback(async () => {
    const pastedText = watch('lastPasted.paragraphs') as string[] | null;
    const currentSections = getValues('chapter.content.sections');
    const oldContent = getValues('lastPasted.oldContent') as string;
    const voiceID = getValues(`chapter.content.sections.${currentSectionIndex}.voiceID`) as string;
    if (!oldContent) {
      currentSections.splice(currentSectionIndex, 1);
    } else {
      currentSections[currentSectionIndex].content = oldContent;
    }

    pastedText?.forEach((text, index) => {
      currentSections.splice(currentSectionIndex + 1 + index, 0, {
        title: `Sektion ${currentSectionIndex + index + 1}`,
        type: 'text',
        pause: 0,
        content: text,
        voiceID,
      });
    });

    const chapterToSave = {
      state: 'draft',
      content: { voiceID: watch('chapter.content.voiceID'), sections: currentSections },
    };

    await fdk.model('chapter').editEntry(chapterID as string, chapterToSave);
    await mutate();
  }, [chapter, watch('lastPasted')]);

  if (!chapter) return null;

  return (
    <Modal headline={t('chapter.chapterSplitPasteTextModal.title')} id={id as string}>
      <form
        onSubmit={async (e: any) => {
          e.preventDefault();
          await splitPastedText();
          hideModal('ChapterSplitPasteTextModal');
        }}
      >
        <div className="form-control">
          <label className="label">
            <span className="label-text text-sm leading-tight">
              {t('chapter.chapterSplitPasteTextModal.description')}
            </span>
          </label>
        </div>
        <div className="flex justify-end py-4 gap-x-2">
          <Button
            variant="outline"
            className="btn grow"
            onClick={(e) => {
              e.preventDefault();
              keepText();
            }}
          >
            {t('chapter.chapterSplitPasteTextModal.cancel')}
          </Button>
          <Button className="btn btn-primary grow" type="submit">
            {t('chapter.chapterSplitPasteTextModal.agree')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
