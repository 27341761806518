import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFdk } from '../../fdk';
import { TrackContext } from '../../routes/ChapterDetail';
import { Modal, hideModal } from '../Modal';
import { Button } from '../ui/button';
import { Label } from '../ui/label';

export default function GeneratingFailedModal({ id }: { id: string }) {
  const { t } = useTranslation('translation');
  const { chapterID } = useParams();
  const { generatingError } = useContext(TrackContext);
  const { data: chapter } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    entryID: chapterID,
  });

  let [assetID] = chapter?.currentAudio;
  const { data: currentAudio } = useFdk(
    assetID
      ? {
          assetGroup: 'audio_files',
          action: 'getAsset',
          assetID,
        }
      : null,
  );

  if (!chapter) return null;

  return (
    <Modal headline={t('chapter.generatingFailedModal.headline')} id={id}>
      <div className="flex flex-col">
        <div>
          <Label>
            {t('chapter.generatingFailedModal.descriptionOne')} {chapter.name}{' '}
            {t('chapter.generatingFailedModal.descriptionTwo')}
          </Label>
        </div>
        <div className="flex items-end py-4 gap-x-4">
          {currentAudio && (
            <a
              className="btn btn-primary grow"
              href={`mailto:support@lizzen.de?subject=Lizzen Fehler bei Generierung&body=%0D%0A%0D%0A Fehlermeldung:${generatingError}`}
            >
              {t('chapter.generatingFailedModal.contactSupport')}
            </a>
          )}
          <Button
            className="btn grow"
            onClick={() => {
              hideModal('generatingFailedModal');
            }}
          >
            {t('chapter.generatingFailedModal.cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
