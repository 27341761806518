import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useFdk, usePlainFdk } from '../../fdk';
import { getFlagEmoji, languages } from '../../utils';
import { Modal, hideModal } from '../Modal';
import { Button } from '../ui/button';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel } from '../ui/form';

export default function ChapterTranslateModal({ id, chapter }: { id: string; chapter?: any }) {
  const { chapterID } = useParams<{ shortID: string; chapterID: string; projectID: string }>();
  const { t, i18n } = useTranslation('translation');
  const fdk = usePlainFdk();
  const [pending, setPending] = useState(false);
  const { mutate } = useFdk({
    model: 'chapter',
    action: 'getEntry',
    id: chapterID,
  });

  const form = useForm({
    defaultValues: {
      language: languages[i18n.language].find((e) => e.language === chapter.language || e.language === 'de_DE')
        .language as any,
    },
  });

  return (
    <Modal id={id as string}>
      <h3 className="font-bold text-lg">{t('chapter.changeLanguageModal.headline')}</h3>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(async (values: any) => {
            try {
              setPending(true);
              await fdk.model('chapter').editEntry(chapterID as string, {
                language: values.language,
              });
              toast.success(t('chapter.changeLanguageModal.success'));
            } catch (error) {
              console.log('error', error);
              toast.error(t('chapter.changeLanguageModal.error'));
            }
            setPending(false);
            hideModal('changeLanguageModal');
            await mutate();
          })}
        >
          <FormField
            control={form.control}
            name="language"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('chapter.chapterTranslateModal.description')}</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <SelectTrigger>
                      <SelectValue placeholder={t('chapter.changeLanguageModal.pleaseChoose')} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {languages[i18n.language].map((language) => (
                          <SelectItem value={language.language} key={language.language}>
                            <div className="flex gap-x-2">
                              <span className="block truncate">
                                {getFlagEmoji(language?.countryCode ?? language?.language)}
                              </span>
                              <span className="block truncate">{language?.name}</span>
                            </div>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
              </FormItem>
            )}
          />
          <div className="form-control">
            <div className="flex items-end justify-end py-4 gap-x-2">
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  hideModal('changeLanguageModal');
                }}
              >
                {t('chapter.changeLanguageModal.cancel')}
              </Button>
              <Button type="submit" disabled={pending}>
                {t('chapter.changeLanguageModal.save')}
                {pending && <span className="loading loading-infinity loading-xs" />}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </Modal>
  );
}
