import { useOidc } from '@axa-fr/react-oidc';
import { CircleUser, Menu } from 'lucide-react';
import { PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { SideNav } from './components/SideNav';
import { Button } from './components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './components/ui/dropdown-menu';
import { Sheet, SheetContent, SheetTrigger } from './components/ui/sheet';
import { classNames } from './utils';
import Logo from './components/Logo';

export function Layout({ children, aside = null }: PropsWithChildren & { aside?: ReactNode }) {
  return (
    <main className="  bg-base- relative  ">
      <Header />
      <div className="flex grid-template-rows">
        {aside && <div className="border-r bg-muted relative">{aside}</div>}
        <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] max-w-7xl mx-auto flex-1 flex-col gap-4  p-4 md:gap-8 md:p-10">
          <div className="h-full">{children}</div>
        </main>
      </div>
    </main>
  );
}

export function LayoutCenter({ children, hideNav }: PropsWithChildren & { hideNav?: boolean }) {
  return (
    <main className="drawer min-h-screen bg-base-100 lg:drawer-open">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <main className="drawer-content flex items-center justify-center min-h-screen w-full">
        <div>{children}</div>
      </main>
      {hideNav && <SideNav />}
    </main>
  );
}

export function LayoutEditor({ children, aside = null }: PropsWithChildren & { aside?: ReactNode }) {
  return (
    <main className="bg-base-100 relative">
      <Header />
      <div className="flex grid-template-rows">
        {aside && (
          <div className="border-r bg-muted relative overflow-y-auto h-[calc(100vh_-_theme(spacing.16))]">{aside}</div>
        )}

        <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 md:gap-8">
          <div className="h-[calc(100vh_-_theme(spacing.16))] overflow-y-auto">{children}</div>
        </main>
      </div>
    </main>
  );
}

const Header = () => {
  const { shortID } = useParams<{ shortID: string }>();
  const { t } = useTranslation('translation');
  const oidc = useOidc();
  const location = useLocation();

  const NAVIGATION = [
    // {
    //   to: '/',
    //   label: 'Studios',
    // },
    shortID && {
      to: `/${shortID}/projects`,
      label: t('header.projects'),
    },
    shortID && {
      to: `/${shortID}/voices`,
      label: t('header.voices'),
    },
    shortID && !['9de61e3e', '25469537'].includes(shortID) && {
      to: `/${shortID}/audio-guide`,
      label: t('header.audioguide'),
    },
  ].filter(Boolean);

  return (
    <header className="sticky bg-muted top-0 flex h-16 items-center gap-4 border-b z-20 px-4 md:px-6">
      <nav className="hidden w-full flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
        <Link to="/" className="flex items-center gap-2 text-lg mr-3 font-semibold md:text-base">
          <div className="mx-4 flex items-center shrink-0 gap-2 font-black">
            <Logo />
          </div>
        </Link>
        {NAVIGATION.map((item: any) => (
          <NavLink
            to={item.to}
            key={item.label}
            className={({ isActive }) =>
              classNames(
                'text-muted-foreground transition-colors hover:text-foreground',
                isActive ? 'text-primary' : '',
              )
            }
          >
            {item.label}
          </NavLink>
        ))}
      </nav>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav className="grid gap-6 text-lg font-medium">
            <Link to="#" className="flex items-center gap-2 text-lg font-semibold">
              <span className="sr-only">Lizzen</span>
            </Link>
            {NAVIGATION.map((item: any) => (
              <NavLink
                key={item.label}
                to={item.to}
                className={({ isActive }) =>
                  classNames('text-muted-foreground hover:text-foreground flex-', isActive ? 'text-primary' : '')
                }
              >
                {item.label}
              </NavLink>
            ))}
          </nav>
        </SheetContent>
      </Sheet>
      <div className="flex  items-center gap-4  md:gap-2 lg:gap-4">
        <div className="">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUser className="h-5 w-5" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {location.pathname !== '/' && (
                <>
                  <Link to={`/${shortID}/settings`} className="cursor-pointer">
                    <DropdownMenuItem>{t('translation:global.settings')} </DropdownMenuItem>
                  </Link>
                  <DropdownMenuSeparator />
                </>
              )}
              <DropdownMenuItem className="cursor-pointer" onClick={async () => await oidc.logout('/')}>
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};
