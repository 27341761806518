import { useSTTForm } from '@/components/Editor/SpeechToTextModal';
import { VoiceSelectLocal } from '@/components/VoiceSelect';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Progress } from '@/components/ui/progress';
import { FormAudio } from '@/routes/VoiceCreateModal';
import { PauseIcon, PlayIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon } from 'lucide-react';
import { Fragment, ReactElement } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export function ProjectCreateSTT() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { shortID } = useParams();
  const {
    pending,
    setPending,
    hasSpeakers,
    uploadForm,
    onSubmitFile,
    file,
    progress,
    speakers,
    getSections,
    speakerForm,
    fdk,
    t,
    message,
    toggleExample,
    activeExample,
  } = useSTTForm({
    shortID,
  });

  return (
    <main className="drawer-content flex items-center justify-center min-h-screen w-screen">
      <Fragment>
        <Fragment>
          <div className="flex items-center fixed top-0 left-0 p-4">
            <Button onClick={() => navigate('../create')} variant="link">
              <ArrowLeftIcon className="w-4 h-4" />
            </Button>
            <h1 className="text-xl">{t('translation:projects.create.breadcrumb', { type: 'stt' })}</h1>
          </div>
          <div className="w-full lg:w-[40vw] mx-auto px-8 text-center flex flex-col space-y-10">
            <div className="space-y-3">
              {!pending && !hasSpeakers && (
                <>
                  <form onSubmit={uploadForm.handleSubmit(onSubmitFile)}>
                    <h3 className="text-2xl mb-8">Sprachdatei hochladen oder aufnehmen</h3>
                    <FormAudio f={uploadForm} description={t('chapter.speechToTextModal.selectFile')} maxSize={25} />
                    <Stepper
                      onPrev={() => navigate('../create')}
                      canNext={!pending && file}
                      nextLabel={
                        <>
                          {t('chapter.speechToTextModal.save')}
                          {pending && <span className="loading loading-infinity loading-xs" />}
                        </>
                      }
                      nextType="submit"
                    ></Stepper>
                  </form>
                </>
              )}
              {pending && !hasSpeakers && (
                <>
                  <Label>{t('chapter.speechToTextModal.description2')}</Label>
                  <div className="w-full py-2 px-4 mt-4">
                    <Progress className="progress w-full" value={progress} />
                    <span className="text-center block text-xs mt-1">{message}</span>
                  </div>
                  <Stepper
                    nextLabel={
                      <>
                        {t('chapter.speechToTextModal.save')}
                        {pending && <span className="loading loading-infinity loading-xs" />}
                      </>
                    }
                  ></Stepper>
                </>
              )}
              {hasSpeakers && (
                <>
                  <form
                    onSubmit={uploadForm.handleSubmit(async () => {
                      setPending(true);
                      try {
                        const project = await fdk.model('project').createEntry({
                          name: searchParams.get('name') || 'Namenloses Projekt',
                          description: '',
                          content: {
                            type: 'stt',
                          },
                        });
                        const sections = getSections();
                        const chapter = await fdk
                          .dm(shortID!)
                          .model('chapter')
                          .createEntry({
                            name: 'Kapitel 1',
                            description: '',
                            state: 'draft',
                            project: project.id,
                            content: {
                              voiceID: sections[0].voiceID,
                              sections,
                            },
                          });
                        navigate(`../${project.id}/chapters/${chapter.id}`);
                        toast.success(t('chapter.speechToTextModal.success'));
                      } catch (err) {
                        console.error(err);
                        toast.error(t('chapter.speechToTextModal.error'));
                      }
                      setPending(false);
                    })}
                  >
                    <h3 className="text-2xl mb-8">Stimmen zuweisen</h3>
                    <Label>
                      Es wurde{Object.keys(speakers).length !== 1 ? 'n' : ''} {Object.keys(speakers).length} Stimme
                      {Object.keys(speakers).length !== 1 ? 'n' : ''} erkannt. Bitte weise die erkannten Stimmen je
                      einer lizzen Stimme zu:
                    </Label>
                    <div className="space-y-8 max-w-[360px] m-auto pt-8">
                      {Object.entries(speakers).map(([name, id], i) => (
                        <div key={name} className="flex space-x-4 justify-between min-w-[200px] items-center w-full">
                          <div className="flex space-x-4 items-center">
                            <Button
                              type="button"
                              onClick={() => toggleExample(name)}
                              className="p-0 px-3 rounded-full w-9 h-9"
                            >
                              {activeExample === name ? (
                                <PauseIcon className="w-4 h-4" />
                              ) : (
                                <PlayIcon className="w-4 h-4" />
                              )}
                            </Button>
                            <span>Stimme&nbsp;{i + 1}</span>
                            <ArrowRightIcon className="w-4 h-4" />
                          </div>
                          <VoiceSelectLocal
                            value={id}
                            onChange={(e) => {
                              speakerForm.setValue('speakers', { ...speakers, [name]: e?.id });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <Stepper
                      canNext={!pending}
                      nextType="submit"
                      nextLabel={
                        <>
                          {t('chapter.speechToTextModal.save')}
                          {pending && <span className="loading loading-infinity loading-xs" />}
                        </>
                      }
                    ></Stepper>
                  </form>
                </>
              )}
            </div>
          </div>
        </Fragment>
      </Fragment>
    </main>
  );
}

function Stepper({
  onPrev,
  onNext,
  canPrev = !!onPrev,
  canNext = !!onNext,
  nextLabel = <>Weiter</>,
  nextType = 'button',
  prevLabel = <>Zurück</>,
}: {
  onPrev?: () => void;
  onNext?: () => void;
  canPrev?: boolean;
  canNext?: boolean;
  nextLabel?: ReactElement;
  nextType?: 'submit' | 'button';
  prevLabel?: ReactElement;
}) {
  return (
    <div className="flex justify-end gap-x-2 mt-20">
      <Button variant="secondary" type="button" onClick={() => onPrev?.()} disabled={!canPrev}>
        {prevLabel}
      </Button>
      <div className="text-right pb-20">
        <Button disabled={!canNext} onClick={() => onNext?.()} type={nextType}>
          {nextLabel}
        </Button>
      </div>
    </div>
  );
}
